import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { ImedClaimDynamicConfig } from '../imed-claim.dynamic-config';
import { ImedClaimService } from '../imed-claim.service';
import { UntypedFormBuilder, AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ImedClaimRequestorTypeService } from '../imed-claim-requestor-type.service';
import { forkJoin, Subscription } from 'rxjs';
import { ImedClaimStatusService } from '../imed-claim-status.service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicField, IDynamicFieldType, IDynamicLabel } from '@mt-ng2/dynamic-form';
import { AuthService } from '@mt-ng2/auth-module';
import { finalize, switchMap } from 'rxjs/operators';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { Router } from '@angular/router';
import { IMedClaimStatusIdEnums } from '../../common/constants/imed-claim-status.enum';
import { cloneAbstractControl } from '../../common/abstract-control-cloner';
import { StateService } from '@app-shared/services/state.service';
import { AddressService } from '../address/addresses/address.service';
import { IAddress } from '@model/interfaces/address';
import { IIMedClaimContactsInfo } from '../../model/interfaces/custom/imed-claim-contacts-info';
import { AddressBooksSelectService } from '../../common/address-book-select-list/address-books-select.service';
import { IAddressBookSelectEntity } from '../../common/address-book-select-list/address-books-select-entity';
import { PopUpService, IPopUpParams } from '../../../app-shared/services/popup-service';
import { PopUpTypeEnums } from '../../../app-shared/Enums/pop-up-type.enums';
import { TaskService } from '../../tasks/task.service';
import { ICreateDocumentDTO } from '../../model/interfaces/custom/create-document.dto';
import { trimObjectStringProperties } from '../../common/custom/trimObjectStringProperties';
import { ImedClaimTypes } from '@model/ImedClaimTypes';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';

@Component({
    selector: 'imed-claim-basic-info',
    templateUrl: './imed-claim-basic-info.component.html',
})
export class ImedClaimBasicInfoComponent implements OnInit, OnDestroy {
    get dateCreated(): Date {
        return this.imedClaim.DateCreated;
    }
    @Input('imedClaim') imedClaim: IImedClaim;
    @Input() canEdit: boolean;
    @Input() imedClaimContacts: IIMedClaimContactsInfo;
    @ViewChild('saveBtn') saveBtn: ElementRef;
    isEditing = false;
    formIsRendered = false;
    caseForm: UntypedFormGroup;
    config: any = {};
    inBetweenConfig: any = {};
    claimId: number;
    formFactory: ImedClaimDynamicConfig<IImedClaim>;
    updateFormFactory: ImedClaimDynamicConfig<IImedClaim>;
    doubleClickIsDisabled = false;
    dynamicFields: IDynamicField[];
    dynamicLabels: IDynamicLabel[];
    isHovered = false;
    caseNumber: AbstractControl;
    fileNumber: AbstractControl;
    subscriptions: Subscription = new Subscription();
    claimantFullAddress = 'No address provided';
    showAddressComponent = false;
    duplicates: IImedClaim[];
    imedClaimAddress: IAddress;
    manuallyEnteredContacts = {
        Carrier: null,
        DefenseAttorney: null,
        Other: null,
        Paralegal: null,
        PlaintiffAttorney: null,
    };
    private documentDTOs: ICreateDocumentDTO[] = [];

    modalOptions: IModalOptions = {
        cancelButtonColor: '#2778c4',
        cancelButtonText: 'Enter new case',
        confirmButtonColor: '#8AC175',
        confirmButtonText: 'Continue',
        reverseButtons: true,
        showCancelButton: true,
        width: 600,
    };
    duplicateCaseModalApi: IModalWrapperApi;

    private _saveFunc: () => void;

    constructor(
        private fb: UntypedFormBuilder,
        private imedClaimService: ImedClaimService,
        private requestorTypeService: ImedClaimRequestorTypeService,
        private imedClaimStatusService: ImedClaimStatusService,
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private router: Router,
        private stateService: StateService,
        private addressService: AddressService,
        private addressBookSelectService: AddressBooksSelectService,
        private popUpService: PopUpService,
        private taskService: TaskService,
    ) {}

    ngOnInit(): void {
        forkJoin(this.requestorTypeService.getItems(), this.imedClaimStatusService.getItems()).subscribe(() => {
            this.setConfig();
        });
        this.caseForm = this.fb.group({
            Address: this.fb.group({}),
            ImedClaim: this.fb.group({}),
        });
        this.subscriptions.add(
            this.addressService.changeEmitted$.subscribe(() => {
                this.setClaimantAddress();
            }),
        );
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };
        if (this.imedClaim.Id === 0) {
            this.subscriptions.add(
                this.imedClaimService.submitFormEvent.subscribe(() => {
                    this.formSubmitted(this.caseForm);
                }),
            );
            // handle any changes to the documents in the attachments control so that documents can be
            // uploaded with a new case
            this.subscriptions.add(
                this.imedClaimService.documentsChanged$.subscribe((newDocs) => {
                    this.documentDTOs = newDocs;
                }),
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setConfig(): void {
        this.setClaimantAddress();
        let cachedClaimNumberControl: AbstractControl;
        let cachedFileNumberControl: AbstractControl;
        if (this.imedClaimService.cachedImedClaimForm) {
            cachedClaimNumberControl = this.imedClaimService.cachedImedClaimForm.get('ImedClaim.ClaimNumber');
            cachedFileNumberControl = this.imedClaimService.cachedImedClaimForm.get('ImedClaim.FileNumber');
        }
        const controls =
            this.imedClaim.Id > 0
                ? [
                      'ClaimNumber',
                      'FileNumber',
                      'JobNumber',
                      'FirstName',
                      'LastName',
                      'Birthdate',
                      'Ssn',
                      'Insured',
                      'DateofAllegedAccidentOrInjury',
                      'RequestorTypeId',
                      'ImedClaimStatusId',
                      'Notes',
                  ]
                : ['ClaimNumber', 'FileNumber', 'JobNumber', 'FirstName', 'LastName', 'RequestorTypeId'];
        this.formFactory = new ImedClaimDynamicConfig<IImedClaim>(
            this.imedClaim,
            cachedClaimNumberControl,
            cachedFileNumberControl,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this.imedClaimStatusService.items,
            null,
            null,
            null,
            this.requestorTypeService.items,
            controls,
        );

        if (this.imedClaim.Id > 0) {
            this.config = this.formFactory.getForUpdate();
        } else {
            this.config = this.formFactory.getForCreate();
        }
        this.dynamicFields = this.config.formObject;
        this.dynamicLabels = this.config.viewOnly;
        this.formIsRendered = true;
    }

    formCreated(evt: UntypedFormGroup): void {
        this.caseForm = evt;
        if (this.imedClaimService.cachedImedClaimForm === null) {
            this.imedClaimService.cachedImedClaimForm = cloneAbstractControl(evt);
            this.subscribeToFormControls(evt);
        } else {
            this.caseForm.patchValue(this.imedClaimService.cachedImedClaimForm.value as { [key: string]: any });
            this.imedClaimContacts = {
                ...this.imedClaimService.cachedImedClaimContacts,
            };
            this.subscribeToFormControls(this.caseForm);
            this.caseForm.updateValueAndValidity();
        }
    }

    subscribeToFormControls(fg: UntypedFormGroup): void {
        this.fileNumber = fg.get('ImedClaim.FileNumber');
        this.caseNumber = fg.get('ImedClaim.ClaimNumber');
        this.subscriptions.add(
            this.fileNumber.valueChanges.subscribe((val) => {
                if (val && val.length) {
                    this.caseNumber.mtSetRequired(false);
                } else {
                    this.caseNumber.mtSetRequired(true);
                }
            }),
        );
        this.subscriptions.add(
            this.caseNumber.valueChanges.subscribe((val) => {
                if (val && val.length) {
                    this.fileNumber.mtSetRequired(false);
                } else {
                    this.fileNumber.mtSetRequired(true);
                }
            }),
        );
    }

    edit(): void {
        this.isEditing = this.canEdit ? !this.isEditing : false;
    }

    cancelClick(): void {
        this.isEditing = false;
        this.subscriptions.unsubscribe();
        this.showAddressComponent = false;
        this.imedClaimService.cachedImedClaimForm = null;
    }

    createImedClaim = (): void => {
        // Currently all claims are of type General Liability. Replace once other claims are supported.
        this.imedClaim.ClaimTypeId = ImedClaimTypes.GeneralLiability;

        this.imedClaim.ImedClaimServices = this.imedClaimService.buildServicesArray(this.imedClaim, this.authService.currentUser.getValue().Id);
        // Set ImedClaim notes value to notes value from services form
        this.imedClaim.Notes = this.imedClaimService._selectedServicesForm.get('Notes.notes').value;
        if (this.imedClaim.ImedClaimServices && this.imedClaim.ImedClaimServices.length) {
            this.imedClaim.ImedClaimStatusId = IMedClaimStatusIdEnums.Pending;
            this.imedClaim.CreatedById = this.authService.currentUser.getValue().Id;
            let claimId = 0;
            this.imedClaimService
                .createWithFks(this.imedClaim)
                .pipe(
                    switchMap((id: number) => {
                        claimId = id;
                        return this.imedClaimService.createAdditionalServicesTask(claimId);
                    }),
                    finalize(() => this.imedClaimService.enableDoubleClick.emit()),
                )
                .subscribe(() => {
                    this.imedClaimService.saveDocsOnCreate.emit(claimId);
                    this.imedClaimService.setIsDuplicate(false);
                    this.success();
                    this.imedClaimService.cachedImedClaimForm = null;
                    this.imedClaimService._selectedServicesForm = null;
                    if (!this.imedClaimService.unsuccessfulUploads) {
                        void this.showConfirmationPopup(claimId);
                    } else {
                        this.imedClaimService.showConfirmation.subscribe(() => {
                            void this.showConfirmationPopup(claimId);
                        });
                    }
                });
        } else {
            this.notificationsService.error('Save failed. A service must be selected to save a case.');
            this.imedClaimService.enableDoubleClick.emit();
        }
    };

    showConfirmationPopup(caseNo: number): void {
        const documentUploadErrorMsg =
            this.imedClaimService.unsuccessfulUploads && this.imedClaimService.unsuccessfulUploads.length
                ? '<div class="text-danger">Some documents did not upload successfully. Please view case and try to upload the files again.</div><br />'
                : '';
        const popUpParams: IPopUpParams = {
            cancelButtonColor: '#8AC175',
            cancelButtonText: 'View case & add documents?',
            confirmButtonText: 'Add another?',
            html: `${documentUploadErrorMsg}<div>Your case was successfully created.</div>`,
            title: 'Case Created',
            type: PopUpTypeEnums.Soft_Pop_Up,
        };
        this.popUpService.showPopUp(popUpParams).subscribe((response) => {
            if (response.value) {
                this.onEnterNewCaseClick();
            } else {
                void this.router.navigate(['cases', caseNo]);
            }
        });
    }

    onEnterNewCaseClick(): void {
        this.imedClaimService.setIsDuplicate(false);
        this.imedClaimService.cachedImedClaimForm = null;
        this.imedClaimService.unsuccessfulUploads = null;

        this.caseForm.reset();

        this.imedClaimService.resetServiceForm.emit();
        this.imedClaimService.clearDocumentUploadQueue.emit();
    }

    setDatesToTimeZoneRelatedDate(dateToBeConverted: string): Date {
        const dateFromFormAsISOString = new Date(dateToBeConverted).toISOString();
        const dateWithoutUTCIdentifier = new Date(dateFromFormAsISOString.substring(0, dateFromFormAsISOString.length - 1));
        return new Date(dateWithoutUTCIdentifier.mtDate.toMoment().format('MM/DD/YYYY'));
    }

    setBirthDate(): void {
        if (this.imedClaim.Birthdate) {
            this.imedClaim.Birthdate = this.setDatesToTimeZoneRelatedDate(this.caseForm.get('ImedClaim.Birthdate').value as string);
        }
    }

    setDateOfInjuryAccident(): void {
        if (this.imedClaim.DateofAllegedAccidentOrInjury) {
            this.imedClaim.DateofAllegedAccidentOrInjury = this.setDatesToTimeZoneRelatedDate(
                this.caseForm.get('ImedClaim.DateofAllegedAccidentOrInjury').value as string,
            );
        }
    }

    updateCase = (): void => {
        this.imedClaim.ModifiedById = this.authService.currentUser.getValue().Id;
        this.imedClaim = this.imedClaimService.assignContactsToClaim(this.imedClaim, this.imedClaimContacts);
        this.setBirthDate();
        this.setDateOfInjuryAccident();
        this.imedClaimService.update(this.imedClaim).subscribe(() => {
            this.isEditing = false;
            this.success();
            this.imedClaimService.setIsDuplicate(false);
            this.imedClaimService.cachedImedClaimForm = null;
            this.createTaskForManualContacts();
            this.setConfig();
        });
    };

    createTaskForManualContacts(): void {
        let taskDescription = '';
        for (const key in this.manuallyEnteredContacts) {
            if (this.manuallyEnteredContacts[key] !== null) {
                taskDescription += `${key}: ${this.manuallyEnteredContacts[key]}`;
            }
        }
        if (taskDescription.length) {
            const task = this.taskService.getEmptyTask();
            task.ImedClaimId = this.imedClaim.Id;
            task.TaskDescriptions =
                'Contacts have been manually added via the Client Portal for this case. ' +
                'Please add the following contacts and assign them to this case. '.concat(taskDescription);
            this.taskService.createManuallyAssignedContactTask(task).subscribe();
        }
    }

    areDocumentsValid(): boolean {
        return this.documentDTOs.every((dto) => dto.AttachmentTypeId !== null && dto.AttachmentTypeId !== 0);
    }

    validateAndSubmitNewClaim(): void {
        if (this.areDocumentsValid()) {
            this.handleDuplicateCheckAndSave(this.createImedClaim);
        } else {
            this.notificationsService.error('Please make sure any documents being uploaded have valid attachment types.');
            this.imedClaimService.enableDoubleClick.emit();
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.imedClaim, form.value.ImedClaim as IImedClaim);
            this.imedClaim.JobNumber = form.value.ImedClaim.JobNumber;
            this.imedClaim.RequestorTypeId = form.value.ImedClaim.RequestorTypeId;
            trimObjectStringProperties(this.imedClaim);
            if (this.imedClaim.Id > 0) {
                this.imedClaim.ModifiedById = 0;
                this.imedClaim.DateModified = new Date();
                this.handleDuplicateCheckAndSave(this.updateCase);
            } else {
                this.validateAndSubmitNewClaim();
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Required fields are specified with "*".  Please make sure you have all required fields entered.');
            this.imedClaimService.enableDoubleClick.emit();
        }
    }
    handleDuplicateCheckAndSave(saveFunc: () => void): void {
        this.imedClaimService.checkForDuplicates(this.imedClaim).subscribe((duplicates) => {
            if (duplicates && duplicates.length) {
                this.duplicates = duplicates;
                this._saveFunc = saveFunc;
                this.duplicateCaseModalApi.show();
            } else {
                saveFunc();
            }
        });
    }

    userConfirmDuplicateCaseModal(): void {
        this._saveFunc();
    }

    navigateToDuplicate(id: number): void {
        this.imedClaimService.cachedImedClaimForm.patchValue(this.caseForm.value as { [key: string]: any });
        this.imedClaimService.cachedImedClaimContacts = {
            ...this.imedClaimContacts,
        };
        void this.router.navigate(['cases', id]).then(() => this.imedClaimService.setIsDuplicate(true));
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    editAddress(): void {
        this.toggleForm();
        this.showAddressComponent = !this.showAddressComponent;
    }

    toggleForm(): void {
        if (this.caseForm.enabled) {
            this.caseForm.disable();
            this.saveBtn.nativeElement.disabled = true;
        } else {
            this.caseForm.enable();
            this.saveBtn.nativeElement.disabled = false;
        }
    }

    handleAddressChange(): void {
        this.showAddressComponent = false;
        this.toggleForm();
        this.setClaimantAddress();
    }

    setClaimantAddress(): void {
        if (this.imedClaim.Id > 0 && this.imedClaim.Addresses[0]) {
            this.imedClaimAddress = this.imedClaim.Addresses[0];
            let state;
            this.stateService.getAll().subscribe((x) => {
                state = x.find((y) => y.Id === this.imedClaimAddress.StateId).StateCode;
                this.claimantFullAddress = `${this.imedClaimAddress.Address1}, ${
                    this.imedClaimAddress.Address2 ? this.imedClaimAddress.Address2 + ',' : ''
                } ${this.imedClaimAddress.City}, ${state} ${this.imedClaimAddress.Zip}`;
            });
        } else {
            this.imedClaimAddress = this.addressService.getEmptyAddress();
        }
    }

    clearAddressBooks(name: string): void {
        this.imedClaimService.unAssignContactFromClaims(this.imedClaimContacts, name);
    }

    searchAddressBooks(addressBookType: string): void {
        this.addressBookSelectService.openAddressBookSelect(addressBookType, this.select); // note the bind(this)
    }

    select = (addressBookSelect: IAddressBookSelectEntity): void => {
        this.imedClaimContacts[addressBookSelect.AddressBookType + 'Name'] = addressBookSelect.IndividualId
            ? addressBookSelect.IndividualName
            : addressBookSelect.AddressBookName;
        this.imedClaimContacts[addressBookSelect.AddressBookType + 'IndividualId'] = addressBookSelect.IndividualId;
        this.imedClaimContacts[addressBookSelect.AddressBookType + 'AddressBookId'] = addressBookSelect.AddressBookId;
    };

    getIsDefaultDynamicField(entityName: string, entityValue: boolean): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: `Default`,
            name: `${entityName}IsDefault`,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            } as IDynamicFieldType),
            value: entityValue,
        } as IDynamicField);
    }

    setIsDefault(addressBookType: string, checked: boolean): void {
        this.imedClaimContacts = this.imedClaimService.setIsDefault(addressBookType, checked, this.imedClaimContacts);
    }
}
