
import { environment } from '../../environments/environment';
import { ILoginConfig } from '@mt-ng2/login-module';

export const LoginConfigOverride: ILoginConfig = {
    googleAuthConfig: environment.googleApiKey ? { googleApiKey: environment.googleApiKey } : null,
    loginComponentLinks: [
        {
            linkHtml: '<a>I forgot my password</a>',
            routerLinkPath: '/forgotpassword',
        },
        {
            linkHtml: '<a>Don`t have a ViewPoint log-in?</a>',
            routerLinkPath: '/requestaccess',
        },
        {
            linkHtml: '<a>Submit a Service Request</a>',
            routerLinkPath: '/service-request',
        },
        {
            linkHtml: '<a href="https://imedview.com/terms-and-conditions/" target="_blank">Terms and Conditions</a>',
            routerLinkPath: '',
        },
        {
            linkHtml: '<a href="https://imedview.com/privacy-policy/" target="_blank">Privacy Policy</a>',
            routerLinkPath: '',
        },
    ],
    messageOverrides: {
        failedPattern: 'Valid passwords must be at least 8 characters, have  upper case, lower case, a number, and a special character.',
    },
    passwordPattern : '(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}',
};
