
import { environment } from '../../environments/environment';
import { INavModuleConfig } from '@mt-ng2/nav-module';

export const NavConfigOverride: INavModuleConfig = {
    hasKeyboardShortcutsModule: false,
    showKeyboardShortcutsButton: false,
    siteName: environment.siteName || null,
    submitHelpUrl: environment.submitHelpUrl || null,
};
