import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IImedClaimRequest } from '@model/interfaces/imed-claim-request';
import { Observable } from 'rxjs';
import { IAddress } from '@model/interfaces/address';

export const emptyImedClaimRequest: IImedClaimRequest = {
    ClaimantFirstName: null,
    ClaimantLastName: null,
    ClaimNumber: null,
    Id: 0,
    ImedClaimTypeId: 0,
    IsOtherClaimType: false,
    RequestorEmail: null,
    RequestorFirstName: null,
    RequestorLastName: null,
    RequestorPhoneNumber: null,
};

@Injectable({
    providedIn: 'root',
})
export class ImedClaimRequestService extends BaseService<IImedClaimRequest> {
    constructor(public http: HttpClient) {
        super('/imed-claim-requests', http);
    }

    getEmptyImedClaimRequest(): IImedClaimRequest {
        return { ...emptyImedClaimRequest };
    }

    createClaimRequestAddress(data: IAddress, addressType: number): Observable<number> {
        return this.http.post<number>(`/imed-claim-requests/address/${addressType}`, data);
    }
}
