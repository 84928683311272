
import { ClaimTypes } from '../model/ClaimTypes';
import { NavCasesMenuItemComponent } from './nav-cases-menu-item/nav-cases-menu-item.component';
import { NavSidebarRowItem, NavSidebarParentRowItem } from '@mt-ng2/nav-module';

// tslint:disable:object-literal-sort-keys
export const appNavMenu: (NavSidebarRowItem | NavSidebarParentRowItem)[] = [
    new NavSidebarRowItem({
        claimType: ClaimTypes.CaseManagement,
        addLink: '/cases/add-from-nav',
        content: 'Cases',
        icon: 'fa fa-fw fa-briefcase',
        link: '/home',
        rowComponent: NavCasesMenuItemComponent,
    }),
    new NavSidebarRowItem({
        content: 'Upload Documents',
        icon: 'fa fa-fw fa-upload',
        link: '/upload-documents',
    }),
];
export const appCollapsedNavMenu: (NavSidebarRowItem | NavSidebarParentRowItem)[] = [
    new NavSidebarRowItem({
        claimType: ClaimTypes.CaseManagement,
        content: 'Cases',
        icon: 'fa fa-fw fa-briefcase',
        link: '/home',
    }),
    new NavSidebarRowItem({
        content: 'Upload Documents',
        icon: 'fa fa-fw fa-upload',
        link: '/upload-documents',
    }),
];
// tslint:enable:object-literal-sort-keys
