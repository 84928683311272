import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { saveAs } from 'file-saver';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { ImedClaimService } from '../imed-claim.service';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { ImedClaimBasicInfoComponent } from '../imed-claim-basic-info/imed-claim-basic-info.component';
import { IIMedClaimContactsInfo } from '../../model/interfaces/custom/imed-claim-contacts-info';
import { emptyIMedClaimContactsInfo } from '../../common/address-book-select-list/address-books-select.service';
import { HttpResponse } from '@angular/common/http';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { BaseAttachmentsService } from '../../common/attachments/base-attachments.service';
import { tap } from 'rxjs/operators';
import { IServiceListDTO } from '../../model/interfaces/custom/service-list-dto';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ImedClaimServiceService } from '../services/imedclaimservice.service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { ImedClaimAttachmentsComponent } from '../attachment/imed-claim-attachments.component';
import { TaskService } from '../../tasks/task.service';
import { ITaskDTO } from '@model/interfaces/custom/task-dto';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'imed-claim-detail',
    templateUrl: './imed-claim-detail.component.html',
})
export class ImedClaimDetailComponent implements OnInit, OnDestroy {
    @ViewChild('basicInfo') basicInfoComponent: ImedClaimBasicInfoComponent;
    @ViewChild('claimDocuments') documentsComponent: ImedClaimAttachmentsComponent;
    imedClaim: IImedClaim;
    canEdit = true;
    searching = false;
    id: number;
    isDuplicate: boolean;
    isDuplicateSubscription: Subscription;
    showCaseInfo: boolean;
    routeSubscription: Subscription;
    imedClaimContacts: IIMedClaimContactsInfo;
    documentArray: IAttachmentDTO[] = [];
    addingServices = false;
    services: IImedClaimService[];
    noAccess = false;
    tasks: ITaskDTO[];
    addingTask = false;
    documentSubscription: Subscription;
    searchParameter: string;
    currentUserId: number;

    constructor(
        private claimsService: ClaimsService,
        private imedClaimService: ImedClaimService,
        private route: ActivatedRoute,
        private router: Router,
        private attachmentsService: BaseAttachmentsService,
        private imedClaimServiceService: ImedClaimServiceService,
        private notificationsService: NotificationsService,
        private taskService: TaskService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.FullAccess]);
        this.imedClaimContacts = { ...emptyIMedClaimContactsInfo };
        this.isDuplicateSubscription = this.imedClaimService.getIsDuplicate().subscribe((val) => {
            this.isDuplicate = val;
            this.canEdit = !val;
        });

        // Clear cached form when coming from add link in navbar
        this.route.data.subscribe((data) => {
            if (data.clearCache) {
                this.imedClaimService.cachedImedClaimForm = null;
            }
        });

        this.routeSubscription = this.route.paramMap.subscribe((params) => {
            this.imedClaimService.prevCase = this.id > 0 ? this.id.toString() : 'add';
            if (!this.id || params.get('imedClaim') !== this.id.toString() || params.get('imedClaim') !== this.imedClaimService.prevCase) {
                this.getImedClaim();
            }
        });
        this.currentUserId = this.authService.currentUser.getValue().Id;
    }

    getImedClaim(): void {
        this.showCaseInfo = false;
        this.id = +this.route.snapshot.paramMap.get('imedClaimId');
        if (this.id > 0) {
            forkJoin({
                imedClaim: this.imedClaimService.getById(this.id),
                docs: this.getDocuments(),
                ics: this.getImedClaimServices(),
                tasks: this.getImedClaimTasks(),
            }).subscribe(({ imedClaim }) => {

                this.imedClaim = imedClaim;
                this.cdr.detectChanges();

                if (imedClaim) {
                    Object.assign(this.imedClaimContacts, imedClaim);
                    this.imedClaimService.assignClaimToContacts(this.imedClaimContacts, imedClaim);
                    this.showCaseInfo = true;
                    this.addingServices = false;
                    if (this.documentsComponent) {
                        this.documentsComponent.isEditing = this.documentsComponent.Files.some((doc) => doc.FileItem.isError) ? true : false;
                    }
                    this.subscribeToDocumentSort();
                } else {
                    this.noAccess = true;
                }
            });
        } else {
            this.addingServices = true;
            this.imedClaim = this.imedClaimService.getEmptyImedClaim();
            this.showCaseInfo = true;
        }
    }

    subscribeToDocumentSort(): void {
        this.documentSubscription = this.attachmentsService.currentSort().subscribe(() => {
            this.getDocuments().subscribe();
        });
    }

    downloadSummarySheet(): void {
        this.imedClaimService.downloadSummarySheet('client-summary-sheet', this.imedClaim.Id).subscribe(
            (data) => {
                const fileName = data.headers.get('X-File-Name');
                const thefile = new Blob([data.body as BlobPart], { type: 'application/octet-stream' });
                saveAs(thefile, fileName);
            },
            () => {
                this.notificationsService.error('Document Download failed.');
            },
        );
    }

    ngOnDestroy(): void {
        if (this.imedClaimService.prevCase !== 'add') {
            this.imedClaimService.cachedImedClaimForm = null;
        }
        this.routeSubscription.unsubscribe();
        this.isDuplicateSubscription.unsubscribe();
        this.imedClaimService.prevCase = '';
    }

    goBackToAddView(): void {
        this.imedClaimService.setIsDuplicate(false);
        const prevCase = this.imedClaimService.prevCase;
        void this.router.navigate(['cases', prevCase]).then(() => {
            setTimeout(() => {
                this.basicInfoComponent.isEditing = true;
            }, 200);
        });
    }

    refreshDocuments(name: string): void {
        if (this.id) {
            this.searchParameter = name;
            this.getDocuments().subscribe();
        }
    }

    getDocuments(): Observable<HttpResponse<IAttachmentDTO[]>> {
        return this.attachmentsService.all('imed-claim', this.id, this.searchParameter).pipe(tap((response) => (this.documentArray = response.body)));
    }

    getImedClaimServices(): Observable<IServiceListDTO[]> {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'imedClaimId',
                value: this.id.toString(),
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'ClientSort',
            orderDirection: 'asc',
            query: '',
            skip: 0,
            take: 10,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.imedClaimServiceService
            .searchImedClaimServices(this.id, searchparams)
            .pipe(tap(({ body: services }) => (this.services = services)));
    }

    getImedClaimTasks(): Observable<ITaskDTO[]> {
        const searchEntity: IEntitySearchParams = {
            order: 'DateCreated',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: 100,
        };

        const csp = new SearchParams(searchEntity);
        return this.taskService.getTasksForClaim(this.id, csp).pipe(
            tap(({ body: tasks }) => {
                this.tasks = tasks;
            }),
        );
    }
}
