import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserService } from './user.service';
import { UserHeaderComponent } from './user-header/user-header.component';

const userEntityConfig = {
    addressesPath: 'addresses',
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    documentsPath: 'documents',
    entityIdParam: 'userId',
    notesPath: '',
    service: UserService,
    title: 'User Detail',
};

export const userPaths = {
    users: 'users',
    usersAdd: 'users/add',
};

const userAddRoleGuard = {
    claimValues: [ClaimValues.FullAccess],
    title: 'Users Add',
};

const userRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: UserDetailComponent, pathMatch: 'full' }],
        component: UserHeaderComponent,
        data: userAddRoleGuard,
        path: userPaths.usersAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [{ path: '', component: UserDetailComponent, pathMatch: 'full' }],
        component: UserHeaderComponent,
        data: userEntityConfig,
        path: `users/:${userEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(userRoutes)],
})
export class UserRoutingModule { }
