import {
    FormDateTimeInputComponent,
    InputTypes,
    DynamicField,
    FormDateInputComponent,
    DynamicFieldTypes,
    DynamicLabel, IDynamicFormModuleConfig
} from '@mt-ng2/dynamic-form';
import { CommonService } from '../services/common.service';

export function customFormComponentFunction(dynamicField: DynamicField): any {
    if (dynamicField.type.fieldType === DynamicFieldTypes.Input) {
        if (dynamicField.type.inputType === InputTypes.DateTimepicker) {
            return FormDateTimeInputComponent;
        }
        if (dynamicField.type.inputType === InputTypes.Datepicker) {
            return FormDateInputComponent;
        }
    }
}

export function customFormComponentDisplayValueFunction(dynamicLabel: DynamicLabel): any {
    if (dynamicLabel.type.inputType === InputTypes.Datepicker && dynamicLabel.value) {
        const utcDate = new Date(dynamicLabel.value as string).toISOString();
        return new Date(utcDate).mtDate.toMoment().utc().format('MM/DD/YYYY');
    }
}

export const dynamicFormModuleConfig: IDynamicFormModuleConfig = {
    commonService: CommonService,
    customFormComponentDisplayValueFunction: customFormComponentDisplayValueFunction,
    customFormComponentFunction: customFormComponentFunction,
};
