import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { IUser } from '@model/interfaces/user';
import { IExternalPortalUserPayload } from '@model/interfaces/custom/external-portal-user-payload';
import { ILoginResponse, AuthService } from '@mt-ng2/auth-module';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { tap } from 'rxjs/operators';

@Injectable()
export class UserService extends BaseService<IUser> {
    private emptyUser: IUser = {
        Archived: false,
        AuthUserId: 0,
        CanBeAssignedTasks: false,
        CanCompleteOthersTasks: false,
        ClientUploadedStudyTask: false,
        CreatedById: 0,
        DateCreated: new Date(),
        Email: null,
        FirstName: null,
        Id: 0,
        LastName: null,
        TwoFactorEnabled: true,
        UserTypeId: null,
        Version: null,
    };

    constructor(public http: HttpClient, private environmentService: EnvironmentService, private authService: AuthService) {
        super('/users', http, null, {
            entityName: 'User',
        });
    }

    formatTitleText(user: IUser): void {
        this.setTitle(`User: ${user.FirstName} ${user.LastName}`);
    }

    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }

    updateClientUserProfile(id: number, data: IExternalPortalUserPayload): Observable<IUser> {
        return this.http.put<IUser>(`/users/external-portal/${id}`, data);
    }

    getTaskAssignableUsers(): Observable<IUser[]> {
        return this.http.get<IUser[]>('/client/tasks/getTaskAssignableUsers');
    }

    savePhoto(userId: number, photo: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post<any>(`/users/${userId}/pic`, formData);
    }

    deletePhoto(userId: number): Observable<unknown> {
        return this.http.delete(`/users/${userId}/pic`);
    }

    clientAccess(resetKey: string, userId: number): Observable<ILoginResponse> {
        const data = {
            AuthClientID: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            ResetKey: resetKey,
            UserId: userId,
        };
        return this.http
            .post<ILoginResponse>('/authUsers/clientAccess', data, {
                headers: { BypassAuth: 'true' },
            })
            .pipe(tap((response) => this.authService.saveToken(response, false)));
    }

    getDistributionListRecipients(): Observable<IUser[]> {
        return this.http.get<IUser[]>('/client-portal/users/get-distribution-recipients');
    }
}
