import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IService } from '@model/interfaces/service';
import { Observable } from 'rxjs';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { map } from 'rxjs/operators';

@Injectable()
export class ServiceService extends StaticMetaItemService<IService> {
    constructor(public http: HttpClient) {
        super('ServiceService', 'Service', 'ServiceIds', '/options/services', http);
    }

    getServiceTypeSortedList(): Observable<MtSearchFilterItem[]> {
        return this.http.get<IService[]>(`/options/services`, {}).pipe(
            map((services) => {
                return services
                    .sort((a, b) => (a.Sort < b.Sort ? -1 : 1))
                    .map((item) => {
                        item.Name = item.Alias;
                        return new MtSearchFilterItem(item, false);
                    });
            }),
        );
    }
}
