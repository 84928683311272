import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable, Subscription } from 'rxjs';
import { ITask } from '@model/interfaces/task';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IUser } from '@model/interfaces/user';
import { TaskDynamicConfig } from '../task.dynamic-config';
import { UserService } from '../../users/user.service';
import { ImedClaimService } from '../../imed-claims/imed-claim.service';
import { MultiselectItem, ISelectionChangedEvent } from '@mt-ng2/multiselect-control';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ITaskAssignedUser } from '@model/interfaces/task-assigned-user';
import { emptyTask, TaskService } from '../task.service';
import { tap, finalize } from 'rxjs/operators';
import { MetaItem } from '@mt-ng2/base-service';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { ITaskPartial } from '../../model/partials/task-partial';
import { IImedClaimServiceDropdownListOptions } from '@model/interfaces/custom/imed-claim-service-dropdown-list-options';
import { ITaskAssignedUserRole } from '@model/interfaces/task-assigned-user-role';
import { AssignedToTypes } from '../../common/constants/assigned-to-type.enum';
import { TaskDynamicControlsPartial } from '../../model/partials/task-partial.form-controls';
import { AuthService } from '@mt-ng2/auth-module';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';

const assigneeFormInitialStateClass = 'label-checkbox-group';
const assigneeFormInvalidStateClass = 'label-checkbox-error';
const assigneeFormValidStateClass = 'label-checkbox-group';

@Component({
    selector: 'app-task-add',
    styles: [
        `
            .${assigneeFormInitialStateClass} {
                padding: 0.5em;
                border: thin solid gainsboro;
                border-radius: 1%;
            }
            .${assigneeFormInvalidStateClass} {
                padding: 0.5em;
                border: thin solid red;
                border-radius: 1%;
            }
        `,
    ],
    templateUrl: './task-basic-info.component.html',
})
export class TaskBasicInfoComponent implements OnInit, OnDestroy {
    task: ITaskPartial;
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    imedClaimId: number;
    users: IUser[];
    isEditing: boolean;
    isHovered: boolean;
    config: IDynamicFormConfig;
    taskForm = new UntypedFormGroup({ Task: new UntypedFormGroup({}) });
    formFactory: TaskDynamicConfig<ITask>;
    doubleClickIsDisabled = false;
    usersSelectItems: MultiselectItem[];
    userRolesSelectItems: MultiselectItem[];
    controls: any;
    userUnsureSelected: boolean;
    selectedUsers: MetaItem[] = [];
    assignedUsers: ITaskAssignedUser[];
    selectedService: IImedClaimServiceDropdownListOptions;
    allServices: IImedClaimServiceDropdownListOptions = { ImedClaimServiceId: 0, DisplayName: 'All Services', UserRoles: [] };
    noServices: IImedClaimServiceDropdownListOptions = { ImedClaimServiceId: -1, DisplayName: 'No Services', UserRoles: [] };
    services: IImedClaimServiceDropdownListOptions[] = [];
    formValid = true;
    userSubscription: Subscription;
    unsureSubscription: Subscription;
    assigneeFormClass = assigneeFormInitialStateClass;
    _assigneeOldValidationState: boolean | null = null;
    _assigneeNewValidationState: boolean | null = null;

    pageTitle: string;

    fromTaskList = false;

    get assigneeNewValidationState(): boolean {
        return this._assigneeNewValidationState;
    }
    set assigneeNewValidationState(newState: boolean | null) {
        this._assigneeOldValidationState = this._assigneeNewValidationState;
        this._assigneeNewValidationState = newState;
        this.setAssigneeFormStyling();
    }

    @Output() emitSave: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() emitCancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input('imedClaim') imedClaim: IImedClaim;
    @Input('fromImedClaim') fromImedClaim: boolean;

    claimantName: string;
    constructor(
        private taskService: TaskService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private notificationsService: NotificationsService,
        private imedClaimsService: ImedClaimService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.id = +this.route.snapshot.paramMap.get('taskId');
        if (this.id) {
            this.pageTitle = 'Edit Task';
            this.taskService.getById(this.id).subscribe((response: ITaskPartial) => {
                this.task = response;
                this.task.UnsureAssignee = false;
                this.imedClaimsService.getById(this.task.ImedClaimId).subscribe((imedClaim) => {
                    this.imedClaim = imedClaim;
                    this.runSetup();
                });
            });
        } else {
            this.pageTitle = 'Add Task';
            if (this.imedClaim) {
                this.fromTaskList = false;
                this.runSetup();
            } else {
                // User clicked "Add" came from task list
                this.fromTaskList = true;
                this.imedClaimId = +this.route.parent?.parent?.snapshot.paramMap.get('imedClaimId');

                this.imedClaimsService.getById(this.imedClaimId).subscribe((imedClaim) => {
                    this.imedClaim = imedClaim;
                    this.runSetup();
                });
            }
        }
    }

    runSetup(): void {
        this.userSubscription = this.userService.getTaskAssignableUsers().subscribe((users) => {
            this.users = users;
            this.usersSelectItems = users.map(
                (item) =>
                    new MultiselectItem(
                        new MetaItem(item.Id, `${item.FirstName} ${item.LastName} (${item.AuthUser.UserRole.Name})`),
                        this.task?.TaskAssignedUsers.find((x) => x.UserId === item.Id) !== undefined,
                    ),
            );
            if (this.id > 0) {
                // check whether not sure checkbox should be checked
                this.userService.getDistributionListRecipients().subscribe((response) => {
                    const adminUserIds = response.map((x) => x.Id);
                    const taskAssignedUserIds = this.task.TaskAssignedUsers.map((x) => x.UserId);
                    if (adminUserIds.every((x) => taskAssignedUserIds.includes(x))) {
                        this.task.UnsureAssignee = true;
                        this.userUnsureSelected = true;
                    }
                    this.setConfig();
                });
            } else {
                this.setConfig();
            }
        });

        this.imedClaimsService.getImedClaimServiceListForDropdown(this.imedClaim.Id).subscribe((data) => {
            if (this.id === 0) {
                // don't include all services option for edit mode
                data.unshift(this.allServices);
            }
            data.push(this.noServices);
            this.services = data;
            if (this.task?.ImedClaimServiceId) {
                this.selectedService = this.services.find((x) => x.ImedClaimServiceId === this.task.ImedClaimServiceId);
            } else {
                this.selectedService = this.noServices;
            }
        });
    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
        if (this.unsureSubscription) {
            this.unsureSubscription.unsubscribe();
        }
    }

    getUsers(): Observable<IUser[]> {
        return this.userService.getAll().pipe(tap((answer) => (this.users = answer)));
    }

    setConfig(): void {
        this.claimantName = this.imedClaim.FirstName + ' ' + this.imedClaim.LastName;
        if (!this.task) {
            this.task = Object.assign({}, emptyTask);
        }
        this.task.IsAutomated = false;
        this.controls = new TaskDynamicControlsPartial(this.task, null).Form;
        this.formFactory = new TaskDynamicConfig<ITask>(this.task, [], ['DueDate', 'TaskDescriptions', 'Notes']);
    }

    userUnsureControlCreated(control: AbstractControl): void {
        this.unsureSubscription = control.valueChanges.subscribe((newValue: boolean) => {
            this.usersSelectItems.forEach((x) => {
                if (x.Selected) {
                    this.selectedUsers.push(x.Item as MetaItem);
                }
            });
            this.assigneeNewValidationState = this.checkAssigneeFormValidity(newValue);
            this.setAssigneeFormStyling();
        });
    }

    userSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedUsers = event.selectedItems;
        this.assigneeNewValidationState = this.selectedUsers.length > 0;
    }

    cancelClick(): void {
        if (this.fromTaskList || this.id > 0) {
            void this.router.navigate(['../'], { relativeTo: this.route });
        } else {
            this.emitCancel.emit(true);
        }
    }

    setAssigneeFormStyling(): void {
        if (this.assigneeNewValidationState === null) {
            this.assigneeFormClass = assigneeFormInitialStateClass;
        } else if (this.assigneeNewValidationState === true && this._assigneeOldValidationState !== true) {
            this.assigneeFormClass = assigneeFormValidStateClass;
        } else if (this.assigneeNewValidationState === false && this._assigneeOldValidationState !== false) {
            this.assigneeFormClass = assigneeFormInvalidStateClass;
        }
    }

    checkAssigneeFormValidity(currentNotSureValue: boolean): boolean {
        return currentNotSureValue || this.selectedUsers.length > 0;
    }

    resetTaskForm(): void {
        this.taskForm.reset();
        const now = new Date();
        this.taskForm.patchValue({
            Task: {
                DueDate: now,
            },
        });
        this.usersSelectItems = this.usersSelectItems.map(
            (item) =>
                ({
                    Item: item.Item,
                    Selected: false,
                } as MultiselectItem),
        );
        this.assigneeNewValidationState = null;
    }

    formSubmitted(): void {
        const form = this.taskForm;
        let finalTasks: ITaskPartial[] = [];

        const task: ITaskPartial = form.value.Task;

        this.assigneeNewValidationState = this.checkAssigneeFormValidity(task.UnsureAssignee);
        if (
            form.valid &&
            (this.assigneeNewValidationState ||
                (this.selectedService !== null && this.selectedService !== this.noServices) ||
                this.usersSelectItems.some((x) => x.Selected))
        ) {
            if (this.task.Id > 0) {
                this.task.ModifiedById = this.authService.currentUser.getValue().Id;
                this.task = this.clearExistingAssociations(this.task);
                if (this.selectedService.ImedClaimServiceId > 0) {
                    this.task.UnsureAssignee = false;
                }
            }
            this.formFactory.assignFormValues(this.task, task);
            this.task.ImedClaimId = this.imedClaim.Id;
            this.task.ImedClaim = this.imedClaim;

            this.selectedService = this.selectedService !== null ? this.selectedService : this.noServices;
            if (this.selectedService.ImedClaimServiceId > 0) {
                this.task.TaskAssignedUserRoles = this.selectedService.UserRoles.map(
                    (UserRole) =>
                        <ITaskAssignedUserRole>{
                            Id: 0,
                            Task: null,
                            TaskId: this.id > 0 ? this.id : 0,
                            UserRole: null,
                            UserRoleId: UserRole.Id,
                        },
                );
                this.task.AssignedToTypeId = AssignedToTypes.UserRoles;
                this.task.ImedClaimServiceId = this.selectedService.ImedClaimServiceId;
                if (this.id === 0) {
                    finalTasks.push(this.task);
                } else {
                    finalTasks = [this.task];
                }
            } else if (this.selectedService.ImedClaimServiceId === this.allServices.ImedClaimServiceId) {
                this.generateAllServicesTasks(finalTasks);
            } else {
                if (!this.task.UnsureAssignee) {
                    this.task.TaskAssignedUsers = this.selectedUsers.map(
                        (User) =>
                            <ITaskAssignedUser>{
                                Id: 0,
                                Task: null,
                                TaskId: this.id > 0 ? this.id : 0,
                                User: null,
                                UserId: User.Id,
                            },
                    );
                    this.task.ImedClaimServiceId = null;
                    this.task.AssignedToTypeId = AssignedToTypes.UserRoles;
                    if (this.id === 0) {
                        finalTasks.push(this.task);
                    } else {
                        finalTasks = [this.task];
                    }
                } else {
                    this.task.ImedClaimService = null;
                    this.task.ImedClaimServiceId = null;
                    this.task.AssignedToTypeId = AssignedToTypes.Users;
                    if (this.id === 0) {
                        finalTasks.push(this.task);
                    } else {
                        finalTasks = [this.task];
                    }
                }
            }
            if (this.id > 0) {
                this.taskService.updateTask(finalTasks.find((x) => x.Id === this.id)).subscribe(() => {
                    this.resetTaskForm();
                    void this.router.navigate(['../'], { relativeTo: this.route });
                    this.success();
                });
            } else {
                this.taskService.createTask(finalTasks).subscribe(() => {
                    this.resetTaskForm();
                    if (this.fromTaskList) {
                        void this.router.navigate(['../'], { relativeTo: this.route });
                    } else {
                        this.emitSave.emit(true);
                    }
                    this.success();
                });
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error("Please make sure all required form fields (marked with '*') are filled out correctly.");
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    generateAllServicesTasks(tasks: ITaskPartial[]): void {
        const form = this.taskForm;

        // Iterate through all service options and create a new task for each service
        this.services
            .filter((x) => x.ImedClaimServiceId > 0)
            .forEach((option) => {
                const newTask = { ...emptyTask };
                newTask.IsAutomated = false;

                this.formFactory.assignFormValues(newTask, form.value.Task as ITaskPartial);
                newTask.AssignedToTypeId = AssignedToTypes.UserRoles;
                newTask.ImedClaimId = this.imedClaim.Id;
                newTask.ImedClaimServiceId = option.ImedClaimServiceId;
                newTask.TaskAssignedUserRoles = option.UserRoles.map(
                    (UserRole) =>
                        <ITaskAssignedUserRole>{
                            Id: 0,
                            Task: null,
                            TaskId: 0,
                            UserRole: null,
                            UserRoleId: UserRole.Id,
                        },
                );
                tasks.push(newTask);
            });
    }

    clearExistingAssociations(task: ITaskPartial): ITaskPartial {
        task.TaskAssignedUserRoles = [];
        task.TaskAssignedUsers = [];
        return task;
    }
}
