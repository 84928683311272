import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { ImedClaimRequestFormComponent } from './imed-claim-request-form/imed-claim-request-form.component';
import { ImedClaimRequestComponent } from './imed-claim-request/imed-claim-request.component';

@NgModule({
    declarations: [ImedClaimRequestComponent, ImedClaimRequestFormComponent],
    imports: [SharedModule],

})
export class ImedClaimRequestModule { }
