import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ISpecialty } from '@model/interfaces/specialty';
import { SpecialityService } from '../specialties.service';
import { Subscription, forkJoin } from 'rxjs';
import { ImedClaimService } from '../imed-claim.service';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ImedClaimServiceService } from '../services/imedclaimservice.service';
import { AuthService } from '@mt-ng2/auth-module';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { Router } from '@angular/router';

@Component({
    selector: 'imed-claim-add-services',
    styles: ['.side-margin { margin: 0 10px }'],
    templateUrl: './imed-claim-add-services.component.html',
})
export class ImedClaimAddServicesComponent implements OnInit {
    @Input('canEdit') canEdit;
    @Input('hideSubmitButton') hideSubmitButton;
    @Input('imedClaim') imedClaim: IImedClaim;
    @Output() emitCancel: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitSave: EventEmitter<any> = new EventEmitter<any>();

    form: UntypedFormGroup;
    specialties: ISpecialty[];
    subscriptions: Subscription = new Subscription();
    doubleClickIsDisabled = false;
    hideImeSpecialties = true;
    hideRecordReviewSpecialties = true;
    hideImeExpertConsultationSpecialties = true;

    constructor(
        private fb: UntypedFormBuilder,
        private specialtyService: SpecialityService,
        private imedClaimService: ImedClaimService,
        private notificationsService: NotificationsService,
        private imedClaimServiceService: ImedClaimServiceService,
        private authService: AuthService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.specialtyService.getItems().subscribe((items) => (this.specialties = items));
        this.form = this.fb.group({
            AdditionalServices: this.fb.group({
                backgroundInvestigationRequested: new UntypedFormControl(false),
                surveillanceRequested: new UntypedFormControl(false),
                translationRequested: new UntypedFormControl(false),
            }),
            Notes: this.fb.group({
                notes: new UntypedFormControl(''),
            }),
            Services: this.fb.group({
                billDeskReview: new UntypedFormControl(false),
                ime: new UntypedFormControl(false),
                imeExpertConsultation: new UntypedFormControl(false),
                // Must be a FormControl because it is being passed into a ControlValueAccessor which will not accept the value if it is a FormArray.
                // https://stackoverflow.com/a/48345955/4635216
                imeExpertConsultationWithSpecialties: new UntypedFormControl([
                    { Id: 0, Name: '' },
                    { Id: 0, Name: '' },
                    { Id: 0, Name: '' },
                ]),
                imesWithSpecialties: new UntypedFormControl([
                    { Id: 0, Name: '' },
                    { Id: 0, Name: '' },
                    { Id: 0, Name: '' },
                ]),
                radiologyReview: new UntypedFormControl(false),
                recordRetrieval: new UntypedFormControl(false),
                recordReview: new UntypedFormControl(false),
                recordReviewWithSpecialties: new UntypedFormControl([
                    { Id: 0, Name: '' },
                    { Id: 0, Name: '' },
                ]),
                subpoenaProcessing: new UntypedFormControl(false),
            }),
        });
        this.imedClaimService._selectedServicesForm = this.form;
        this.subscriptions.add(this.imedClaimService.enableDoubleClick.subscribe(() => this.enableDoubleClick()));
        this.subscriptions.add(this.imedClaimService.resetServiceForm.subscribe(() => this.resetServiceForm()));
    }

    resetServiceForm(): void {
        this.form.reset({
            AdditionalServices: {
                backgroundInvestigationRequested: false,
                surveillanceRequested: false,
                translationRequested: false,
            },
            Notes: {
                notes: '',
            },
            Services: {
                billDeskReview: false,
                ime: false,
                imeExpertConsultation: false,
                imeExpertConsultationWithSpecialties: [
                    { Id: 0, Name: '' },
                    { Id: 0, Name: '' },
                    { Id: 0, Name: '' },
                ],
                imesWithSpecialties: [
                    { Id: 0, Name: '' },
                    { Id: 0, Name: '' },
                    { Id: 0, Name: '' },
                ],
                radiologyReview: false,
                recordRetrieval: false,
                recordReview: false,
                recordReviewWithSpecialties: [
                    { Id: 0, Name: '' },
                    { Id: 0, Name: '' },
                ],
                subpoenaProcessing: false,
            },
        });

        this.enableDoubleClick();
    }

    ngAfterViewInit(): void {
        this.subscribeToFormControls();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.imedClaimService._selectedServicesForm = null;
    }

    enableDoubleClick(): void {
        setTimeout(() => (this.doubleClickIsDisabled = false));
    }

    subscribeToFormControls(): void {
        this.subscriptions.add(
            this.form.get('Services.ime').valueChanges.subscribe((newValue) => {
                this.hideImeSpecialties = !newValue;
            }),
        );
        this.subscriptions.add(
            this.form.get('Services.imeExpertConsultation').valueChanges.subscribe((newValue) => {
                this.hideImeExpertConsultationSpecialties = !newValue;
            }),
        );
        this.subscriptions.add(
            this.form.get('Services.recordReview').valueChanges.subscribe((newValue) => {
                this.hideRecordReviewSpecialties = !newValue;
            }),
        );
    }

    cancelClick(): void {
        this.emitCancel.emit();
    }

    onSubmit(form: UntypedFormGroup): void {
        if (form.valid) {
            const servicesToSave = this.imedClaimService
                .buildServicesArray(this.imedClaim, this.authService.currentUser.getValue().Id)
                .map((x) => this.imedClaimServiceService.createWithFks(x));
            forkJoin(...servicesToSave, this.imedClaimService.createAdditionalServicesTask(this.imedClaim.Id)).subscribe(
                () => {
                    this.success();
                    this.emitSave.emit();
                },
                () => this.error(),
            );
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed. Please check the form and try again.');
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    saveCase(): void {
        this.imedClaimService.submitFormEvent.emit();
    }

    cancelAddCase(): void {
        void this.router.navigate(['/cases']);
    }
}
