// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .miles-form {
                font-size: 12px;
            }
            .section-header {
                font-size: 15px;
                font-weight: bold;
                text-decoration: underline;
                margin-bottom: 10px;
                display: block;
            }
            .space-for-validation {
                margin-bottom: 5px !important;
            }
            .service-type-container {
                display: inline-block;
                margin-left: 20px;
            }
            .issue-type-container {
                display: inline-block;
                margin-left: 10px;
            }
            .issue-form {
                font-size: 11px;
            }
        `, "",{"version":3,"sources":["webpack://./src/client-portal/imed-claim-requests/imed-claim-request-form/imed-claim-request-form.component.ts"],"names":[],"mappings":";YACY;gBACI,eAAe;YACnB;YACA;gBACI,eAAe;gBACf,iBAAiB;gBACjB,0BAA0B;gBAC1B,mBAAmB;gBACnB,cAAc;YAClB;YACA;gBACI,6BAA6B;YACjC;YACA;gBACI,qBAAqB;gBACrB,iBAAiB;YACrB;YACA;gBACI,qBAAqB;gBACrB,iBAAiB;YACrB;YACA;gBACI,eAAe;YACnB","sourcesContent":["\n            .miles-form {\n                font-size: 12px;\n            }\n            .section-header {\n                font-size: 15px;\n                font-weight: bold;\n                text-decoration: underline;\n                margin-bottom: 10px;\n                display: block;\n            }\n            .space-for-validation {\n                margin-bottom: 5px !important;\n            }\n            .service-type-container {\n                display: inline-block;\n                margin-left: 20px;\n            }\n            .issue-type-container {\n                display: inline-block;\n                margin-left: 10px;\n            }\n            .issue-form {\n                font-size: 11px;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
