import { Component, OnInit } from '@angular/core';
import { ClaimTypes } from './model/ClaimTypes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { appPaths } from './default-routes/app-paths.library';
import { Router } from '@angular/router';

@Component({
    template: '',
})
export class AppRoutingComponent implements OnInit {
    constructor(private claimsService: ClaimsService, private router: Router) { }

    ngOnInit(): void {
        if (this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.FullAccess, ClaimValues.ReadOnly])) {
            void this.router.navigate(['/', appPaths.home]);
        } else {
            void this.router.navigate(['/', appPaths.clientDocumentUpload]);
        }
    }
}
