import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IImeService } from '@model/interfaces/ime-service';

export const emptyImeService: IImeService = {
    Archived: false,
    ConflictOfInterestApproved: false,
    CreatedById: 0,
    DateCreated: new Date(),
    FilmReviewId: null,
    HasPreLitigation: false,
    Id: 0,
    ImedClaimServiceId: 0,
    IsFederalCase: false,
    PatientPreviouslyTreatedByPhysician: false,
    Surveillance: false,
};

@Injectable({
    providedIn: 'root',
})
export class ImeServiceService extends BaseService<IImeService> {
    constructor(public http: HttpClient) {
        super('/imeservices', http);
    }

    getEmptyImeService(): IImeService {
        return { ...emptyImeService };
    }
}
