import { IEntityListConfig, EntityListConfig, EntityListColumn, IEntityListColumn } from '@mt-ng2/entity-list-module';
import { DatePipe } from '@angular/common';
import { IImedClaimServiceFollowup } from '@model/interfaces/imed-claim-service-followup';

export class ImedClaimServiceFollowupsEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (followUp: IImedClaimServiceFollowup): string {
                        return new DatePipe('en-US').transform(followUp.Date, 'MMM d, y', 'UTC');
                    },
                    name: 'Date',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['FollowupType', 'Name'],
                    name: 'Type',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Notes'],
                    bindToInnerHtml: true,
                    name: 'Notes',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CreatedBy', 'AuthUser', 'UserRole', 'Name'],
                    name: 'User Role',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (followUp: IImedClaimServiceFollowup): string {
                        return followUp.CreatedBy.FirstName + ' ' + followUp.CreatedBy.LastName;
                    },
                    name: 'User',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
