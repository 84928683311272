import { Component, OnInit } from '@angular/core';
import { IImedClaimType } from '@model/interfaces/imed-claim-type';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ImedClaimTypes } from '@model/ImedClaimTypes';
import { IPopUpParams, PopUpService } from '../../../app-shared/services/popup-service';
import { AuthService } from '@mt-ng2/auth-module';
import { ImedClaimTypeService } from '../../imed-claims/services/imed-claim-type.service';
import { NavContentDynamicRowComponent } from '@mt-ng2/nav-module';

@Component({
    selector: 'nav-cases-menu-item',
    templateUrl: 'nav-cases-menu-item.component.html',
})
export class NavCasesMenuItemComponent extends NavContentDynamicRowComponent implements OnInit {
    imedClaimTypes: IImedClaimType[];
    currentUser: any;

    dropdownIsOpen = false;

    timeout: NodeJS.Timeout = null;

    get rowLinkIsActive(): boolean {
        return this.row.link ? this.router.isActive(this.row.link.link, true) : false;
    }

    constructor(
        private imedClaimTypeService: ImedClaimTypeService,
        private router: Router,
        private notificationsService: NotificationsService,
        private popUpService: PopUpService,
        private authService: AuthService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.currentUser = this.authService.currentUser.getValue();
        this.imedClaimTypeService.getAll().subscribe((imedClaimTypes) => {

            this.imedClaimTypes = imedClaimTypes.map((imedClaimType) => {
                if (imedClaimType.Name === 'General Liability') {
                    imedClaimType.Name = 'General Liability (Record Retrievals / Subpoena Processing)';
                }
                return imedClaimType;
            });

        });
    }

    showPopupWarning(popUp: IPopUpParams): void {
        this.popUpService.showPopUp(popUp).subscribe((response) => {
            if (response.value) {
                return;
            }
        });
    }

    toggle(): void {
        this.dropdownIsOpen = !this.dropdownIsOpen;
    }

    setTimeout(): void {
        if (this.dropdownIsOpen) {
            this.timeout = setTimeout(() => (this.dropdownIsOpen = false), 300);
        }
    }

    clearTimeout(): void {
        if (this.dropdownIsOpen) {
            clearTimeout(this.timeout);
        }
    }

    addImedClaim(type: IImedClaimType): void {
        switch (type.Id as ImedClaimTypes) {
            case ImedClaimTypes.GeneralLiability:
                void this.router.navigate([`/cases/${ImedClaimTypes.GeneralLiability}/add/`]);
                break;
            case ImedClaimTypes.WorkersCompensation:
                void this.router.navigate(['/service-request']);
                break;
            case ImedClaimTypes.Auto:
                void this.router.navigate(['/service-request']);
                break;
            default:
                void this.notificationsService.error(`Unknown claim type: ${type.Name}`);
        }
    }

    navigateToLink(): void {
        void this.router.navigate([this.row.link.link]);
    }
}
