import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { AuthService } from '@mt-ng2/auth-module';
import { ModalService } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FileItem } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { DocumentTransactionTypeEnums } from '../../app/common/constants/Enums';
import { AttachmentControlComponent } from '../common/attachment-control/attachment-control.component';
import { BaseAttachmentsService } from '../common/attachments/base-attachments.service';
import { DocumentTransactionLogService } from '../imed-claims/attachment/document-transaction-log.service';
import { ICreateDocumentDTO } from '../model/interfaces/custom/create-document.dto';

@Component({
    selector: 'client-upload-documents',
    templateUrl: './upload-documents.component.html',
})
export class UploadDocumentsComponent implements OnInit, AfterViewChecked {
    currentUserId = 0;
    documentArray: IAttachmentDTO[] = [];
    canEdit: boolean;
    doubleClickIsDisabled = false;
    formCreated = false;
    doneInitializing = false;
    fileNamesArray: string[] = [];

    Files: ICreateDocumentDTO[] = [];
    isPasswordProtected = false;

    attachmentComponentSubscription: Subscription;
    @ViewChildren(AttachmentControlComponent) private _attachmentComponents: QueryList<AttachmentControlComponent>;
    @ViewChild('docComponent') docComponent: AttachmentControlComponent;

    constructor(
        private attachmentsService: BaseAttachmentsService,
        private cdRef: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private docTransactionLogService: DocumentTransactionLogService,
        private authService: AuthService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        this.currentUserId = this.authService.currentUser.getValue().Id;
    }

    ngAfterViewChecked(): void {
        this.formCreated = true;
        this.attachmentComponentSubscription = this._attachmentComponents.changes.subscribe((components: QueryList<AttachmentControlComponent>) => {
            this.docComponent = components.first;

            if (this.docComponent) {
                this.docComponent.uploader.onAfterAddingFile = () => {
                    const latestFile = this.docComponent.uploader.queue[this.docComponent.uploader.queue.length - 1];
                    if (latestFile) {
                        const documentDTO = this.attachmentsService.getEmptyCreateDocumentDTO();
                        documentDTO.FileItem = latestFile;
                        this.docComponent.uploader.queue = [];
                        this.Files.push(documentDTO);
                        this.fileNamesArray = [];
                        this.Files.map((x) => this.fileNamesArray.push(x.FileItem.file.name));
                    }
                };
            }
        });
        if (!this.doneInitializing) {
            this.cdRef.detectChanges();
            this.doneInitializing = true;
        }
    }

    removeItem(indexToRemove: number): void {
        this.Files = this.Files.filter((_, index) => indexToRemove !== index);
        this.fileNamesArray = [];
        this.Files.map((x) => this.fileNamesArray.push(x.FileItem.file.name));
    }

    ngOnDestroy(): void {
        if (this.attachmentComponentSubscription) {
            this.attachmentComponentSubscription.unsubscribe();
        }
    }

    // Async upload function that sets file upload status upon successful or unsuccessful upload
    async upload(file: ICreateDocumentDTO): Promise<void> {
        file.FileItem.isUploading = true;
        file.FileItem.isError = false;
        await this.attachmentsService.uploadDocumentsToQueue(file.FileItem._file, this.currentUserId).toPromise();
    }

    // Iterate over file array and upload each file sequentially, log error in db
    async tryUploadDocuments(): Promise<void> {
        if (this.Files.length > 0) {
            for (const file of this.Files) {
                if (!file.FileItem.isUploaded) {
                    try {
                        await this.upload(file).then(() => this.setDocumentUploadStatus(file.FileItem, true));
                    } catch (err) {

                        if (err.error === 'Password Protected.') {
                             this.isPasswordProtected = true;
                        }
                        this.setDocumentUploadStatus(file.FileItem, false);
                        this.docTransactionLogService.logError(err as HttpErrorResponse, null, DocumentTransactionTypeEnums.MANUAL_UPLOAD_FE).subscribe();
                    }
                }
            }
            this.completeDocumentsSave();
        } else {
            this.notificationsService.error(`You need to add at least one file to upload document(s)`);
            setTimeout(() => (this.doubleClickIsDisabled = false), 200);
        }
    }

    // Set queued document's upload status
    setDocumentUploadStatus(doc: FileItem, uploaded: boolean): void {
        doc.isUploading = false;
        doc.isUploaded = uploaded;
        doc.isError = !uploaded;
    }

    // Check if any of the queued documents are in error status, if so show error and allow for reupload
    completeDocumentsSave(): void {
        setTimeout(() => (this.doubleClickIsDisabled = false), 200);
        if (this.queueHasErrors) {

             if (this.isPasswordProtected) {
                 this.notificationsService.error(
                     `One or more documents have been uploaded with protection. Please re-upload the document without protection.`,
                 );
                 this.isPasswordProtected = false;
             } else {
                 this.notificationsService.error(
                     `The following files marked with a red 'X' failed to upload. Remove the file and add it again to retry upload`,
                 );
             }

        } else {
            this.uploadSuccessMessage();
        }
    }

    get queueHasErrors(): boolean {
        return this.Files.some((file) => file.FileItem.isError);
    }

    clearDocumentUploadQueue(): void {
        this.docComponent.uploader.queue = [];
        this.Files = [];
    }

    uploadSuccessMessage(): void {
        this.modalService
            .showModal({
                html: `<p>Following file(s) have been successfully uploaded:<br/> ${this.fileNamesArray.join('<br/>')}</p>`,
                title: 'Success!',
            	icon: 'success',
})
            .subscribe(() => this.clearDocumentUploadQueue());
    }
}
