import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IIssueRequestType } from '@model/interfaces/issue-request-type';

@Injectable({
    providedIn: 'root',
})
export class IssueRequestTypeService extends StaticMetaItemService<IIssueRequestType> {
    constructor(public http: HttpClient) {
        super('IssueRequestTypeService', 'Type', 'TypeIds', '/options/issue-request-types', http);
    }
}
