import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImedClaimDetailComponent } from './imed-claim-detail/imed-claim-detail.component';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ImedClaimService } from './imed-claim.service';
import { ImedClaimHeaderComponent } from './imed-claim-header/imed-claim-header.component';
import { ImedClaimListComponent } from './imed-claim-list/imed-claim-list.component';
import { ImedClaimServiceFollowupsComponent } from './services/follow-ups/imed-claim-service-followups.component';
import { ImedClaimServiceService } from './services/imedclaimservice.service';
import { ImedClaimServicesComponent } from './services/service-list/imed-claim-services.component';
import { TaskListComponent } from '../tasks/task-list/task-list.component';
import { ClaimTypes } from '../model/ClaimTypes';
import { TaskBasicInfoComponent } from '../tasks/task-basic-info/task-basic-info.component';
import { TaskService } from '../tasks/task.service';
import { UsserTaskGuardService } from './services/tasks/user-task-guard.service';

const imedClaimEntityConfig = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'imedClaimId',
    entityTypeIdParam: 'imedClaimTypeId',
    service: ImedClaimService,
    title: 'Case Details',
};

const imedClaimListRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Cases',
};

const imedClaimAddRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.FullAccess],
};

const imedClaimAddFromNavRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.FullAccess],
    clearCache: true,
};

const imedClaimServicesListRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Services',
};

const imedClaimTaskListRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Tasks',
};

const imedClaimServiceFollowupListRoleGuard = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Follow-Ups',
};

const imedServiceEntityConfig = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'serviceId',
    service: ImedClaimServiceService,
    title: 'Service Detail',
};

const taskEntityConfig = {
    claimType: ClaimTypes.CaseManagement,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'taskId',
    service: TaskService,
    title: 'Task Detail',
};
// https://gitlab.4miles.com/imedview/imedview/-/commit/aa2fa6c4e83c6e9c8d823ac162580695e1468080
const imedClaimRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: ImedClaimListComponent,
        data: imedClaimListRoleGuard,
        path: 'cases', // workaround to fix user going to login link, we will need to refactor the routing to fix the problem https://mt.striven.com/tasks/TaskInfo.aspx?TaskID=1928548
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                component: ImedClaimDetailComponent,
                path: '',
                pathMatch: 'full',
            },
        ],
        component: ImedClaimHeaderComponent,
        data: imedClaimAddRoleGuard,
        path: `cases/:${imedClaimEntityConfig.entityTypeIdParam}/add`,
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                component: ImedClaimDetailComponent,
                path: '',
                pathMatch: 'full',
            },
        ],
        component: ImedClaimHeaderComponent,
        data: imedClaimAddFromNavRoleGuard,
        path: `cases/:${imedClaimEntityConfig.entityTypeIdParam}/add`,
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                component: ImedClaimDetailComponent,
                path: '',
                pathMatch: 'full',
            },
            {
                component: ImedClaimServicesComponent,
                data: imedClaimServicesListRoleGuard,
                path: `services`,
            },
            {
                children: [
                    {
                        component: ImedClaimServiceFollowupsComponent,
                        data: imedClaimServiceFollowupListRoleGuard,
                        path: `followups`,
                    },
                ],
                path: `services/:${imedServiceEntityConfig.entityIdParam}`,
            },
            {
                children: [
                    {
                        component: TaskListComponent,
                        path: '',
                    },
                    {
                        component: TaskBasicInfoComponent,
                        path: 'add',
                    },
                    {
                        canActivate: [UsserTaskGuardService],
                        component: TaskBasicInfoComponent,
                        data: taskEntityConfig,
                        path: `:${taskEntityConfig.entityIdParam}`,
                    },
                ],
                data: imedClaimTaskListRoleGuard,
                path: 'tasks',
            },
        ],
        component: ImedClaimHeaderComponent,
        data: imedClaimEntityConfig,
        path: `cases/:${imedClaimEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(imedClaimRoutes)],
})
export class ImedClaimRoutingModule {}
