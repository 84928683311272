import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import * as moment from 'moment-timezone';
import { ImedClaimService } from '../../imed-claim.service';

export class ImedClaimServicesEntityListConfig extends EntityListConfig {
    constructor(private imedClaimService: ImedClaimService) {
            super({
                columns: [
                new EntityListColumn({
                    accessors: ['ServiceType'],
                    fireOnColumnSelected: true,
                    name: 'Service',
                    sort: {
                        sortProperty: 'ServiceType',
                    },
                }),
                new EntityListColumn({
                    accessors: ['ServiceStatus'],
                    fireOnColumnSelected: true,
                    name: 'Status',
                    sort: {
                        sortProperty: 'ServiceStatus',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (s: IViewGetServicesForList): string {
                        return s.PhysicianName ? s.PhysicianName : '';
                    },
                    fireOnColumnSelected: true,
                    name: 'Physician',
                    sort: {
                        sortProperty: 'PhysicianName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (s: IViewGetServicesForList): string {
                        let name = ' ';
                        if (s.IndividualName.trim() && s.FacilityName) {
                            name = `${s.IndividualName} / ${s.FacilityName}`;
                        } else if (s.IndividualName.trim() && !s.FacilityName) {
                            name = s.IndividualName;
                        } else if (s.FacilityName && !s.IndividualName.trim()) {
                            name = s.FacilityName;
                        }
                        return name;
                    },
                    fireOnColumnSelected: true,
                    name: 'Facility',
                    sort: {
                        sortProperty: 'FacilityName',
                    },
                }),
                new EntityListColumn({
                    accessors: ['RequestedSpecialtyName'],
                    fireOnColumnSelected: true,
                    name: 'Requested Specialty',
                    sort: {
                        sortProperty: 'RequestedSpecialtyName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (s: IViewGetServicesForList): string {
                        if (s.AppointmentDateTime) {
                            return moment.tz(s.AppointmentDateTime, s.Timezone).format('MM/DD/YYYY hh:mm A');
                        }
                        return '';
                    },
                    fireOnColumnSelected: true,
                    name: 'Appointment Date / Time',
                    sort: {
                        sortProperty: 'AppointmentDateTime',
                    },
                }),
                new EntityListColumn({
                    accessors: ['ServiceAddress'],
                    fireOnColumnSelected: true,
                    name: 'Exam Location',
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IViewGetServicesForList) => {
                        if (!(entity && entity.FollowUpDate && entity.FollowUpName && entity.FollowUpUser)) {
                            return '';
                        }
                        const followupDate = new Date(entity.FollowUpDate);
                        return `<b>${moment.utc(followupDate).format('MM/DD/YYYY')}</b> ${entity.FollowUpName} - <em>${entity.FollowUpUser}</em>`;
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Last Follow-up',
                    sort: {
                        sortProperty: 'LatestFollowUp.FollowUpDate',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (): string {
                        return 'View All';
                    },
                    linkFunction: function (ab: IViewGetServicesForList): void {
                        // workaround to ignore invalid navigationUrl
                        imedClaimService.setServiceId(ab.Id, ab.ImedClaimId);
                    },
                    name: 'Follow-ups',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        } as IEntityListConfig);
    }
}
