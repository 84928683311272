import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IAddress } from '@model/interfaces/address';

export const emptyAddress: IAddress = {
    Address1: null,
    Address2: null,
    Archived: false,
    City: null,
    CreatedById: 0,
    DateCreated: new Date(),
    Id: 0,
    ModifiedById: null,
    StateId: 0,
    Zip: null,
};

export interface IAddressTypeAheadDTO {
    AddressBookId: number;
    Address: string;
}

@Injectable({
    providedIn: 'root',
})
export class AddressService extends BaseService<IAddress> {
    constructor(public http: HttpClient) {
        super('/addresses', http);
    }

    getEmptyAddress(): IAddress {
        return { ...emptyAddress };
    }
}
