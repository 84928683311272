import { Component, Input, forwardRef, ViewChildren, QueryList, AfterViewInit, ViewChild, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IMetaItem, MetaItem } from '@mt-ng2/base-service';
import { ISelectionChangedEvent, TypeAheadComponent } from '@mt-ng2/type-ahead-control';

@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TypeAheadListControlComponent),
        },
    ],
    selector: 'type-ahead-list-control',
    template: `
        <div style="text-align:center;">
            <div *ngFor="let row of typeAheadList; let i = index" style="padding: 5px 0; text-align: left;">
                <label>{{ label }}</label>
                <mt-type-ahead [items]="items" (selectionChanged)="selectionChanged($event, i)"></mt-type-ahead>
            </div>
            <button #addBtn type="button" (click)="addRow()" class="btn btn-primary btn-xs btn-nohover btn-flat">
                <i class="fa fa-plus fa-lg" aria-hidden="true"></i>
            </button>
        </div>
    `,
})
export class TypeAheadListControlComponent implements ControlValueAccessor, AfterViewInit {
    @Input() _typeAheadList: IMetaItem[];
    @Input('items') items: IMetaItem[];
    @Input('label') label: IMetaItem[];
    @Input('adding') adding;
    @ViewChildren(TypeAheadComponent) private _typeAheadComponents: QueryList<TypeAheadComponent>;
    @ViewChild('addBtn', { static: true }) addBtn;
    get typeAheadList(): IMetaItem[] {
        return this._typeAheadList;
    }
    set typeAheadList(val) {
        this._typeAheadList = val;
        this.propagateChange(this._typeAheadList);
    }

    constructor(private renderer: Renderer2) {}

    setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.addBtn.nativeElement, 'disabled', isDisabled);
        if (this._typeAheadComponents && isDisabled) {
            this._typeAheadComponents.forEach((x: TypeAheadComponent) => x.inputControl.disable());
        } else if (this._typeAheadComponents) {
            this._typeAheadComponents.forEach((x: TypeAheadComponent) => x.inputControl.enable());
        }
    }

    ngAfterViewInit(): void {
        this._typeAheadComponents.changes.subscribe(() => {
            this._typeAheadComponents.forEach((x: TypeAheadComponent, i: number) => {
                x.selectedItem = this.typeAheadList[i];
            });
        });
    }

    addRow(): void {
        this.typeAheadList.push(new MetaItem(0, ''));
    }

    selectionChanged(event: ISelectionChangedEvent, i: number): void {
        this._typeAheadComponents.toArray()[i].selectedItem = event.selection;
        this.typeAheadList[i] = event.selection;
    }
    // ControlValueAccessor implementation. Required to use custom form control in reactive form.
    writeValue(value: any): void {
        this.typeAheadList = value;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propagateChange = (_: IMetaItem[]) => null;

    registerOnChange(func): void {
        this.propagateChange = func;
    }

    // Not needed but required in ControlValueAccessor interface
    registerOnTouched = (): void => null;
}
