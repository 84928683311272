import { Component, OnInit } from '@angular/core';
import { ImedClaimService } from '../imed-claim.service';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { ImedClaimsEntityListConfig } from './imed-claims.entity-list-config';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { SearchParams, IEntitySearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { ClaimValues, ClaimsService } from '@mt-ng2/auth-module';
import { IItemSelectedEvent } from '@mt-ng2/entity-list-module';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IImedClaimType } from '@model/interfaces/imed-claim-type';
import { ImedClaimTypeService } from '../services/imed-claim-type.service';
import { IFabSpeedDialMini, OpenDirection } from '@mt-ng2/fab-speed-dial-control';
import { ImedClaimTypes } from '../../model/ImedClaimTypes';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'app-imed-claim-list-info',
    templateUrl: './imed-claim-list.component.html',
})
export class ImedClaimListComponent implements OnInit {
    total: number;
    imedClaims: IImedClaim[] = [];
    entityListConfig = new ImedClaimsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    currentPage = 1;
    searchClaimsForm: UntypedFormGroup;
    canAddImedClaim = false;

    claimantNameSearchValue = '';
    searchParams: SearchParams;

    fabMinis: IFabSpeedDialMini[] = [];
    openDir = OpenDirection.up;
    iconActive = 'fa fa-minus fa-lg';
    iconStart = 'fa fa-plus fa-lg';
    enablePillIcons = true;

    constructor(
        private clientPortalImedClaimService: ImedClaimService,
        private imedClaimTypeService: ImedClaimTypeService,
        private claimsService: ClaimsService,
        private router: Router,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.searchClaimsForm = new UntypedFormGroup({
            searchClaimantName: new UntypedFormControl(''),
            searchClaimNo: new UntypedFormControl(''),
            searchDefenseAttorney: new UntypedFormControl(''),
            searchFileNo: new UntypedFormControl(''),
            searchIncludeAllCases: new UntypedFormControl(false),
            searchParalegal: new UntypedFormControl(''),
        });

        this.entityListConfig.export = {
            exportName: 'Claims List',
            getDataForExport: () => this.getImedClaimsSubscription(true).pipe(map((x) => x.body)),
        };

        this.canAddImedClaim = this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.FullAccess]);

        this.imedClaimTypeService.getAll().subscribe((claimTypes) => {
            this.fabMinis = claimTypes.map((ct) => {

                if (ct.Name === 'General Liability') {
                    ct.Name = 'General Liability (Record Retrievals / Subpoena Processing)';
                }

                return {
                    htmlOverride: `<span>${ct.Name}</span>`,
                    icon: ' ',
                    onClick: () => {
                        this.addImedClaim(ct);
                    },
                } as IFabSpeedDialMini;
            });
        });

        this.searchClientCases();
    }

    updateClaimantNameSearch(searchEvent: string): void {
        this.claimantNameSearchValue = searchEvent;
    }

    buildSearchModel(skipPaging?: boolean): void {
        const searchValue = this.searchClaimsForm.value;
        const extraParams: ExtraSearchParams[] = [];
        for (const key in searchValue) {
            if (Object.prototype.hasOwnProperty.call(searchValue, key)) {
                let value = searchValue[key];
                if (key === 'searchIncludeAllCases') {
                    value = value ? '1' : '0';
                }
                extraParams.push(
                    new ExtraSearchParams({
                        name: key,
                        value: value,
                    }),
                );
            }
        }

        const entitySearchParams: IEntitySearchParams = {
            extraParams: extraParams,
            query: this.claimantNameSearchValue,
            skip: skipPaging ? 0 : (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: skipPaging ? 0 : entityListModuleConfig.itemsPerPage,
        };
        this.searchParams = new SearchParams(entitySearchParams);
    }

    getImedClaimsSubscription(skipPaging?: boolean): Observable<any> {
        this.buildSearchModel(skipPaging);
        return this.clientPortalImedClaimService.getClaimsByClientId(this.searchParams);
    }

    searchClientCases(): void {
        this.buildSearchModel();
        this.getClaims();
    }

    getClaims(): void {
        this.clientPortalImedClaimService.getClaimsByClientId(this.searchParams).subscribe((answer) => {
            this.imedClaims = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    claimSelected(event: IItemSelectedEvent): void {
        this.clientPortalImedClaimService.cachedImedClaimForm = null;
        this.clientPortalImedClaimService.setIsDuplicate(false);
        void this.router.navigate(['/cases', event.entity.Id]);
    }

    enterPressed(): void {
        this.searchClientCases();
    }

    addImedClaim(type: IImedClaimType): void {
        switch (type.Id as ImedClaimTypes) {
            case ImedClaimTypes.GeneralLiability:
                void this.router.navigate([`/cases/${ImedClaimTypes.GeneralLiability}/add/`]);
                break;
            case ImedClaimTypes.WorkersCompensation:
                void this.router.navigate(['/service-request']);
                break;
            case ImedClaimTypes.Auto:
                void this.router.navigate(['/service-request']);
                break;
            default:
                this.notificationsService.error(`Unknown claim type: ${type.Name}`);
        }
    }
}
