import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { appNavMenu, appCollapsedNavMenu } from './app-nav-menu.config';
import { NavService, NavSidebarContentContainer, INavSidebarService, NavSidebarHeaderItem } from '@mt-ng2/nav-module';

@Injectable()
export class NavSidebarService implements INavSidebarService {
    content: BehaviorSubject<NavSidebarContentContainer[]>;

    navigationMenu: NavSidebarContentContainer;
    collapsedNavMenu: NavSidebarContentContainer;

    constructor(private navService: NavService) {
        const header = new NavSidebarHeaderItem({ content: `NAVIGATION` });
        this.navigationMenu = new NavSidebarContentContainer({
            header: header,
            rows: appNavMenu,
        });
        this.collapsedNavMenu = new NavSidebarContentContainer({
            header: header,
            rows: appCollapsedNavMenu,
        });

        this.content = new BehaviorSubject([this.navigationMenu]);

        this.navService.sidebarCollapsed.subscribe((sidebarCollapsed) => {
            if (sidebarCollapsed) {
                this.content.next([this.collapsedNavMenu]);
            } else {
                this.content.next([this.navigationMenu]);
            }
        });
    }
}
