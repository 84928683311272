import { IFilmReviewService } from '@model/interfaces/film-review-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';

export const emptyFilmReviewService: IFilmReviewService = {
    Archived: false,
    CreatedById: 0,
    DateCreated: new Date(),
    DiscCopiedDate: null,
    DiscMailedDate: null,
    HasPreLitigation: false,
    Id: 0,
    ImedClaimServiceId: 0,
    IsFederalCase: false,
    RadiologyFacility: '',
    RequestDate: null,
    RequestDateChangedById: null,
    RequestDateChangedDate: null,
};

@Injectable({
    providedIn: 'root',
})
export class RadiologyReviewServiceService extends BaseService<IFilmReviewService> {
    constructor(public http: HttpClient) {
        super('/filmreviewservices', http);
    }

    getEmptyFilmReviewServiceService(): IFilmReviewService {
        return { ...emptyFilmReviewService };
    }
}
