// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .details-list {
                list-style-type: none;
            }
            .list-group-item {
                border-top: none;
                border-bottom: none;
                cursor: default;
            }
            .list-group-item:hover {
                background-color: #f5f5f5;
            }
            .list-group-item:nth-child(even) {
                background-color: #ececec;
            }
            .list-group-item:first-child {
                border-top: 1px solid #ddd;
            }
            .list-group-item:last-child {
                border-bottom: 1px solid #ddd;
            }
        `, "",{"version":3,"sources":["webpack://./src/client-portal/imed-claims/imed-claim-view-services/imed-claim-view-services.component.ts"],"names":[],"mappings":";YACY;gBACI,qBAAqB;YACzB;YACA;gBACI,gBAAgB;gBAChB,mBAAmB;gBACnB,eAAe;YACnB;YACA;gBACI,yBAAyB;YAC7B;YACA;gBACI,yBAAyB;YAC7B;YACA;gBACI,0BAA0B;YAC9B;YACA;gBACI,6BAA6B;YACjC","sourcesContent":["\n            .details-list {\n                list-style-type: none;\n            }\n            .list-group-item {\n                border-top: none;\n                border-bottom: none;\n                cursor: default;\n            }\n            .list-group-item:hover {\n                background-color: #f5f5f5;\n            }\n            .list-group-item:nth-child(even) {\n                background-color: #ececec;\n            }\n            .list-group-item:first-child {\n                border-top: 1px solid #ddd;\n            }\n            .list-group-item:last-child {\n                border-bottom: 1px solid #ddd;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
