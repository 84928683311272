import { DynamicField, SelectInputTypes, LabelPositions } from '@mt-ng2/dynamic-form';
import { IDocument } from '@model/interfaces/document';
import { DocumentDynamicControls, IDocumentDynamicControlsParameters } from '@model/form-controls/document.form-controls';

export class DocumentDynamicControlsPartial extends DocumentDynamicControls {
    constructor(document?: IDocument, additionalParameters?: IDocumentDynamicControlsParameters) {
        super(document, additionalParameters);

        (<DynamicField>this.Form.AttachmentTypeId).type.inputType = SelectInputTypes.Dropdown;
        (<DynamicField>this.Form.AttachmentTypeId).labelPosition.position = LabelPositions.Hidden;
        (<DynamicField>this.Form.AttachmentTypeId).setRequired(true);
    }
}
