import { Observable, Subscription } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import 'rxjs/operators';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { ImedClaimServiceService } from '../imedclaimservice.service';
import { entityListModuleConfig } from '../../../common/shared.module';
import { ImedClaimServicesEntityListConfig } from './imed-claim-services.entity-list-config';
import { IService } from '@model/interfaces/service';
import { forkJoin } from 'rxjs';

import { debounceTime, map } from 'rxjs/operators';
import { MtSearchBarComponent } from '@mt-ng2/searchbar-control';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { ImedClaimService } from '../../imed-claim.service';
import { ServiceService } from '@app-shared/services/service.service';

@Component({
    selector: 'app-imed-claim-services',
    styles: [
        '.service-select { font-weight: normal; width: 100%; padding: 3px 20px 3px 20px; margin: 0; border: none; background: transparent;} ',
        '.service-select:hover { background: #f5f5f5;} ',
        '.service-select:focus { outline: 0} ',
        '.service-select:active { background: #8AC175; color: #ffffff; outline: none} ',
    ],
    templateUrl: './imed-claim-services.component.html',
})
export class ImedClaimServicesComponent implements OnInit, OnDestroy {
    searchControl = new UntypedFormControl();
    imedClaimServices: IViewGetServicesForList[];
    currentPage = 1;
    query = '';
    total: number;
    imedClaims: MtSearchFilterItem[] = [];
    entityListConfig = new ImedClaimServicesEntityListConfig(this.imedClaimService);
    order = 'DateCreated';
    orderDirection = 'desc';
    serviceTypes: MtSearchFilterItem[] = [];
    services: IService[];
    formCreated = false;
    searchClaimServicesForm: UntypedFormGroup;
    subscriptions: Subscription = new Subscription();
    selectedServices: IService[];

    // Track current search for input to saved search component
    currentSearch: SearchParams;
    searchChanged: boolean; // value to input to search component to reset the search filter dropdown ui
    // Property store common search parameters
    commonSearchParams: SearchParams;
    componentLoaded: boolean;

    imedClaim: IImedClaim;
    @ViewChild('searchBar') searchBar: MtSearchBarComponent;
    imedClaimId: number;

    constructor(
        private imedClaimServiceService: ImedClaimServiceService,
        private imedClaimService: ImedClaimService,
        private router: Router,
        private serviceService: ServiceService,
        private activatedRoute: ActivatedRoute,
    ) { }

    resetFilters(): void {
        this.searchControl.reset();
        this.searchBar.clearSearch();
        this.serviceTypes.forEach((x) => (x.Selected = false));
    }

    ngOnInit(): void {
        this.imedClaimId = this.activatedRoute.parent.snapshot.params.imedClaimId;

        this.entityListConfig.export = {
            exportName: 'Services List',
            getDataForExport: () => this.getImedClaimServicesSubscription(true).pipe(map((x) => x.body)),
        };

        forkJoin(this.serviceService.getItems(), this.serviceService.getServiceTypeSortedList()).subscribe(([services, serviceTypes]) => {
            this.serviceTypes = serviceTypes;
            this.services = services;

            this.getImedClaimServices();
        });

        this.componentLoaded = true;
        this.formCreated = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    overrideDebounceTime(searchControl: UntypedFormControl): void {
        this.searchControl = searchControl;
        this.subscriptions.add(
            searchControl.valueChanges.pipe(debounceTime(600)).subscribe((value: string) => {
                this.search(value);
            }),
        );
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedServiceTypeIds: number[] = this.getSelectedFilters(this.serviceTypes);

        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ServiceTypeIds',
                valueArray: selectedServiceTypeIds,
            }),
        );

        return _extraSearchParams;
    }

    getImedClaimServicesSubscription(skipPaging?: boolean): Observable<any> {
        const search = this.commonSearchParams && this.commonSearchParams.query ? this.commonSearchParams.query : this.query;
        const _extraSearchParams: ExtraSearchParams[] =
            this.commonSearchParams && this.commonSearchParams.extraParams ? this.commonSearchParams.extraParams : this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.commonSearchParams && this.commonSearchParams.order ? this.commonSearchParams.order : this.order,
            orderDirection:
                this.commonSearchParams && this.commonSearchParams.orderDirection ? this.commonSearchParams.orderDirection : this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: skipPaging ? 0 : (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: skipPaging ? 0 : entityListModuleConfig.itemsPerPage,
        };
        const searchparams = new SearchParams(searchEntity);
        // store current search params in case user wants to saves this search
        this.currentSearch = searchparams;

        return this.imedClaimServiceService.searchImedClaimServices(this.imedClaimId, searchparams);
    }

    // Clear out common search params before getting next results, also resets page to 1  to avoid 0 results
    clearCommonSearchParamsAndSearch(): void {
        this.commonSearchParams = null;
        this.searchChanged = !this.searchChanged;
        this.currentPage = 1;
        this.getImedClaimServices();
    }

    getImedClaimServices(): void {
        const search = this.commonSearchParams && this.commonSearchParams.query ? this.commonSearchParams.query : this.query;
        const _extraSearchParams: ExtraSearchParams[] =
            this.commonSearchParams && this.commonSearchParams.extraParams ? this.commonSearchParams.extraParams : this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.commonSearchParams && this.commonSearchParams.order ? this.commonSearchParams.order : this.order,
            orderDirection:
                this.commonSearchParams && this.commonSearchParams.orderDirection ? this.commonSearchParams.orderDirection : this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        // store current search params in case user wants to saves this search
        this.currentSearch = searchparams;

        this.imedClaimServiceService
            .searchImedClaimServices(this.imedClaimId, searchparams)
            .pipe(debounceTime(400))
            .subscribe((answer) => {
                this.imedClaimServices = answer.body;
                this.total = +answer.headers.get('X-List-Count');
            });
    }

    search(query: string): void {
        this.query = query;
        this.clearCommonSearchParamsAndSearch();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getImedClaimServices();
    }

    close(): void {
        void this.router.navigateByUrl('/cases/' + this.imedClaimId);
    }
}
