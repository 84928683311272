import { UserTypeValues } from './../../common/constants/user-type.enum';
import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserService } from '../user.service';

import { IUser } from '@model/interfaces/user';
import { UserDynamicConfig } from '../user.dynamic-config';
import { AuthUserDynamicConfig } from '../../auth-entity/auth-user/auth-user.dynamic-config';
import { IAuthUser } from '@model/interfaces/auth-user';
import { IExternalPortalUserPayload } from '@model/interfaces/custom/external-portal-user-payload';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { trimObjectStringProperties } from '../../common/custom/trimObjectStringProperties';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-user-basic-info',
    templateUrl: './user-basic-info.component.html',
})
export class UserBasicInfoComponent implements OnInit {
    @Input() user: IUser;
    authUser: IAuthUser;
    additionalConfigs: IDynamicFormConfig[] = [];
    isEditing: boolean;
    isHovered: boolean;
    config: IDynamicFormConfig;
    userForm: FormGroup;
    formFactory: UserDynamicConfig<IUser>;
    doubleClickIsDisabled = false;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(private userService: UserService, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
    }

    getAdditionalConfigs(): AuthUserDynamicConfig<IAuthUser>[] {
        const usernameConfigControls: string[] = ['Username'];
        const authUser = this.user.AuthUser;
        const usernameConfig = new AuthUserDynamicConfig<IAuthUser>(authUser, null, usernameConfigControls);
        return [usernameConfig];
    }

    setConfig(): void {
        this.formFactory = new UserDynamicConfig<IUser>(this.user);
        this.config = this.formFactory.getForUpdate(this.getAdditionalConfigs());

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            const data: IExternalPortalUserPayload = {
                Email: form.value.User.Email,
                FirstName: form.value.User.FirstName,
                LastName: form.value.User.LastName,
                RoleId: this.user.AuthUser.RoleId,
                TwoFactorEnabled: true,
                UserId: this.user.Id,
                Username: form.value.AuthUser.Username,
                UserTypeId: UserTypeValues.Client,
            };

            trimObjectStringProperties(data);
            this.userService.updateClientUserProfile(data.UserId, data).subscribe((answer: IUser) => {
                this.user = answer;
                this.isEditing = false;
                this.success();
                this.setConfig();
            });
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(`Save failed.  Please check the form and try again.`);
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    updateVersion(version): void {
        this.user.Version = version;
    }
}
