import { Validators } from '@angular/forms';
import { IImedClaimRequestDynamicControlsParameters, ImedClaimRequestDynamicControls } from '@model/form-controls/imed-claim-request.form-controls';
import { IImedClaimRequest } from '@model/interfaces/imed-claim-request';
import { IImedClaimRequestService } from '@model/interfaces/imed-claim-request-service';
import { IServiceRequestType } from '@model/interfaces/service-request-type';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, InputTypes, noZeroRequiredValidator, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { ImedClaimDynamicControls, IImedClaimDynamicControlsParameters } from '../form-controls/imed-claim.form-controls';
import { IImedClaim } from '../interfaces/imed-claim';

export interface IImedClaimRequestDynamicControlsParametersPartial extends IImedClaimRequestDynamicControlsParameters {
    serviceRequestTypes?: IServiceRequestType[];
}

export class ImedClaimRequestDynamicControlsPartial extends ImedClaimRequestDynamicControls {
    constructor(imedClaimRequestPartial?: IImedClaimRequest, additionalParameters?: IImedClaimRequestDynamicControlsParametersPartial) {
        super(imedClaimRequestPartial, additionalParameters);

        this.Form.ImedClaimTypeId = new DynamicField({
            formGroup: this.formGroup,
            label: 'Case type:',
            name: 'ImedClaimTypeId',
            options: this.imedClaimTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.OldStyleRadioButtonList,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: imedClaimRequestPartial && imedClaimRequestPartial.ImedClaimTypeId || null,
        });

        (<DynamicField>this.Form.RequestorEmail).validation = [Validators.required, Validators.maxLength(100), Validators.email];
        (<DynamicField>this.Form.RequestorEmail).validators = { 'required': true, 'maxlength': 100, 'email': true };
        (<DynamicField>this.Form.RequestorPhoneNumber).type.inputType = InputTypes.Phone;
        (<DynamicField>this.Form.CarrierPhoneNumber).type.inputType = InputTypes.Phone;
        (<DynamicField>this.Form.ClaimantPhoneNo).type.inputType = InputTypes.Phone;
        (<DynamicField>this.Form.Ssn).labelHtml = '<label>Last 4 digits of SSN (###-###-1111)</label>';
        (<DynamicField>this.Form.Specialties).labelHtml = '<label>Specialty / Specialities requested:</label>';
        (<DynamicField>this.Form.Injuries).labelHtml = '<label>Injuries / Body parts to be examined:</label>';
        (<DynamicField>this.Form.RequestorEmail).labelHtml = '<label>Requestor Email</label><span class="errortext ng-star-inserted"> *</span>';
    }
}
