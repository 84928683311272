import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { IUser } from '@model/interfaces/user';
import { UserService } from '../user.service';
import { IAddress } from '@model/interfaces/base';
import { AuthService } from '@mt-ng2/auth-module';

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
})
export class UserDetailComponent implements OnInit {
    user: IUser;
    editingComponent: Subject<string> = new Subject();
    userAddress: IAddress;
    myProfile: boolean;

    constructor(private userService: UserService, private authService: AuthService) { }

    ngOnInit(): void {
        const id = this.authService.currentUser.getValue().Id;
        this.user = this.userService.getEmptyUser();
        this.getUserById(id);
        this.editingComponent.next('');
    }

    getUserById(id: number): void {
        this.userService.getById(id).subscribe((user: IUser) => {
            this.user = user;
        });
    }

    updateVersion(version): void {
        this.user.Version = version;
    }
}
