import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { EnvironmentService } from '@mt-ng2/environment-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NavService } from '@mt-ng2/nav-module';
import { AuthConfig, AuthService, ILoginResponse, MtAuthGuard } from '@mt-ng2/auth-module';
import { PageTitlesService } from '@mt-ng2/page-titles';
import { LoginConfig, LoginService, LoginModuleConfigToken, ILoginConfig, RememberOptions } from '@mt-ng2/login-module';
import { ClaimTypes } from './model/ClaimTypes';
import { appPaths } from './default-routes/app-paths.library';

interface LoginFormValues {
    password: string;
    username: string;
    rememberMe: boolean;
}

@Component({
    selector: 'app-client-portal-login',
    styles: [
        `
            .fa-google {
                padding-right: 10px;
            }
        `,
    ],
    template: `
        <div class="login-box">
            <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
            <div class="login-box-body">
                <p class="login-box-msg">{{ config?.messageOverrides.loginLabel }}</p>
                <div *ngIf="!config?.hideRegularSignIn">
                    <form *ngIf="loginForm" [formGroup]="loginForm" (submit)="onLogin()">
                        <div class="form-group has-feedback" [class.has-error]="showUsernameRequiredError()">
                            <input type="text" autofocus class="form-control" placeholder="Username" formControlName="username" />
                            <span class="fa fa-user form-control-feedback"></span>
                            <div *ngIf="showUsernameRequiredError()" class="small errortext" [style.position]="'absolute'">Username is required</div>
                        </div>
                        <div class="form-group has-feedback" [class.has-error]="showPasswordRequiredError()">
                            <input
                                #Password
                                type="password"
                                autocomplete="off"
                                class="form-control"
                                placeholder="Password"
                                formControlName="password"
                            />
                            <span class="fa fa-lock form-control-feedback"></span>
                            <div *ngIf="showPasswordRequiredError()" class="small errortext" [style.position]="'absolute'">Password is required</div>
                        </div>
                        <div class="padded block-parent">
                            <strong>*Reminder, your Viewpoint username is not your E-mail address.</strong>
                        </div>
                        <div class="padded block-parent">
                            <i>Password is case sensitive and must include a combination of upper case, lower case, numbers, and special characters. Password must be a minimum of 8 characters in length.</i>
                        </div>
                        <div class="padded block-parent">
                            <div *ngIf="config?.rememberFeature.rememberOptions" class="form-check">
                                <input type="checkbox" id="rememberMe" class="form-check-input" formControlName="rememberMe" />
                                <label class="form-check-label" for="rememberMe"> Remember Me</label>
                            </div>
                            <button [disabled]="!loginForm.valid" type="submit" class="btn btn-primary btn-flat inline-block block-right">
                                {{ config?.messageOverrides.signInButtonText }}
                            </button>
                        </div>
                    </form>

                    <ng-container *ngFor="let link of config?.loginComponentLinks">
                        <span style="cursor: pointer;" [innerHtml]="link.linkHtml" [routerLink]="link.routerLinkPath"></span><br />
                    </ng-container>
                </div>
            </div>
        </div>
    `,
})
export class ClientPortalLoginComponent implements OnInit, OnDestroy {
    loginForm: UntypedFormGroup;
    logoFull = `${this.environmentService.config.imgPath}logo-full.png`;
    returnUrl = '';
    public config: LoginConfig;

    get hasGoogleAuthConfig(): boolean {
        return this.config.googleAuthConfig ? true : false;
    }

    get rememberOption(): RememberOptions {
        return this.config.rememberFeature.rememberOptions;
    }

    constructor(
        private fb: UntypedFormBuilder,
        private navService: NavService,
        private authService: AuthService,
        private router: Router,
        private notificationsService: NotificationsService,
        private environmentService: EnvironmentService,
        @Inject(LoginModuleConfigToken) private loginConfig: ILoginConfig,
        private pageTitlesService: PageTitlesService,
        private activatedRoute: ActivatedRoute,
        private loginService: LoginService,
        private authConfig: AuthConfig,
    ) {}

    ngOnInit(): void {
        // appReady determines if an authenticated connection has been made.
        // While it's waiting it shows a loading icon.  When appReady has a
        // value the loading icon is hidden.  We always want this to be true
        // when you are on the login page.  Because you aren't authed!
        if (this.authService.appReady && !this.authService.appReady.getValue()) {
            this.authService.appReady.next(true);
        }
        this.config = new LoginConfig(this.loginConfig);
        this.navService.setShowNav(false);
        this.createForm();
        this.setPageTitle();
        this.returnUrl = this.activatedRoute.snapshot.queryParams[MtAuthGuard.Return_Url_QueryParam];
    }

    setPageTitle(): void {
        this.pageTitlesService.setPageTitle('Login');
    }

    createForm(): void {
        if (!this.loginForm) {
            this.loginForm = this.fb.group({});
        }
        this.loginForm.addControl('password', new UntypedFormControl(''));
        this.loginForm.addControl('username', new UntypedFormControl('', (c: AbstractControl) => Validators.required(c)));
        this.loginForm.addControl('rememberMe', new UntypedFormControl(false, (c: AbstractControl) => Validators.required(c)));
    }

    showUsernameRequiredError(): boolean {
        const control = this.loginForm.get('username');
        return control.touched && control.hasError('required');
    }

    showPasswordRequiredError(): boolean {
        const control = this.loginForm.get('password');
        return control.touched && control.hasError('required');
    }

    onLogin(): void {
        if (this.loginForm.valid) {
            const values = this.loginForm.value as LoginFormValues;
            this.authService.login(values.username, values.password, values.rememberMe).subscribe(
                (successResponse: ILoginResponse) => {
                    if (successResponse.AuthUserId) {
                        this.notificationsService.info(
                            'A one-time password has been emailed to you.  Click the link in the email to finish logging in.',
                        );
                        void this.router.navigate([this.authConfig.paths.twoFactorVerifyPath]);
                    } else if (this.returnUrl) {
                        void this.router.navigateByUrl(this.returnUrl);
                    } else {
                        if (successResponse.LoginResult.ClaimFlags[ClaimTypes.CaseManagement]) {
                            void this.router.navigate(['/', appPaths.home]);
                        } else {
                            void this.router.navigate(['/', appPaths.clientDocumentUpload]);
                        }
                    }
                },
                (errorResponse) => {
                    if (errorResponse.status === 418) {
                        if (errorResponse.error === 'DomainLoginEmailSent') {
                            this.notificationsService.success('A login link has been emailed to you');
                        } else {
                            this.notificationsService.error(this.config.messageOverrides.userNamePasswordFailure);
                        }
                    } else if (errorResponse.status === 400) {
                        if (errorResponse.error) {
                            this.notificationsService.error(errorResponse.error as string);
                        } else {
                            this.notificationsService.error('Something went wrong');
                        }
                    } else if (errorResponse.status === 500) {
                        if (errorResponse.error) {
                            this.notificationsService.error(errorResponse.error as string);
                        } else {
                            this.notificationsService.error('Something went wrong');
                        }
                    } else {
                        this.notificationsService.error(errorResponse.error as string);
                    }

                },
            );
        } else {
            markAllFormFieldsAsTouched(this.loginForm);
        }
    }

    ngOnDestroy(): void {
        this.navService.setShowNav(true);
    }
}
