import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { IServiceListDTO } from '../../model/interfaces/custom/service-list-dto';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';

@Component({
    selector: 'imed-claim-view-services',
    styles: [
        `
            .details-list {
                list-style-type: none;
            }
            .list-group-item {
                border-top: none;
                border-bottom: none;
                cursor: default;
            }
            .list-group-item:hover {
                background-color: #f5f5f5;
            }
            .list-group-item:nth-child(even) {
                background-color: #ececec;
            }
            .list-group-item:first-child {
                border-top: 1px solid #ddd;
            }
            .list-group-item:last-child {
                border-bottom: 1px solid #ddd;
            }
        `,
    ],
    templateUrl: './imed-claim-view-services.component.html',
})
export class ImedClaimViewServicesComponent {
    @Input('canEdit') canEdit;
    @Input('imedClaim') imedClaim: IImedClaim;
    @Input('services') services: IServiceListDTO[];
    @Output() emitAdd: EventEmitter<any> = new EventEmitter();

    constructor(private router: Router) { }

    parsePhysicianFacilityIndividualName(service: IServiceListDTO): string {
        if (service.PhysicianName.trim()) {
            return service.PhysicianName;
        } else if (service.IndividualName.trim() && service.FacilityName.trim()) {
            return service.IndividualName + ' / ' + service.FacilityName;
        } else if (service.IndividualName.trim() && !service.FacilityName) {
            return service.IndividualName;
        } else if (!service.IndividualName && service.FacilityName.trim()) {
            return service.FacilityName;
        }
        return 'Not assigned';
    }

    formatAppointmentDateTime(service: IServiceListDTO): string {
        return moment.tz(service.AppointmentDateTime, service.Timezone).format('LLL');
    }

    addServices(): void {
        this.emitAdd.emit();
    }

    seeAll(): void {
        void this.router.navigate([`cases/${this.imedClaim.Id}/services/`]);
    }
}
