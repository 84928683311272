import { Component, OnDestroy, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavService } from '@mt-ng2/nav-module';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { environment } from './environments/environment';
import { PrintModeService } from '@mt-ng2/entity-list-module';
import { AppStates, AppStateService } from './app-state.service';
import { UserInactivityMonitorService } from '@app-shared/services/user-inactivity-monitor.service';
import { NavigationEnd, Router } from '@angular/router';
import { PortalTypeEnums } from '@app-shared/Enums/PortalTypeEnum';

@Component({
    host: { 'window:beforeunload': 'beforeUnloadHandler', 'window:unload': 'unloadHandler' },
    selector: 'app-root',
    template: `
        <div
            [hidden]="appReady === null"
            class="skin-blue sidebar-mini"
            [class.sidebar-open]="!sidebarCollapsed && showNav"
            [class.sidebar-collapse]="sidebarCollapsed || !showNav"
            [class.sidebar-mini]="showNav"
        >
            <div class="wrapper">
            <mt-nav *ngIf="!verifyPath"></mt-nav>
                <div class="container-fluid content-wrapper" [style.min-height]="showFooter ? null : '100vh'">
                    <ng-progress></ng-progress>
                    <span [hidden]="!isMainAppState">
                        <router-outlet></router-outlet>
                    </span>
                    <address-books-select
                        *ngIf="isSearchAddressBookAppState"
                        (closeAddressBookAction)="closeAddressBookAction()"
                    ></address-books-select>
                    <br />
                </div>
                <mt-footer></mt-footer>
            </div>
        </div>
        <div [hidden]="appReady !== null" [style.position]="'relative'">
            <div class="pulse">
                <span></span>

                <img class="logo-img " src="{{ logoFull }}" alt="Logo" />
            </div>
        </div>
    `,
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'app';

    sidebarCollapsed: boolean;
    showNav: boolean;
    verifyPath = false;
    showFooter: boolean;
    appReady: boolean = null;
    appState: AppStates;
    logoFull = `${environment.imgPath}logo-full.png`;
    subscriptions: Subscription = new Subscription();

    get isMainAppState(): boolean {
        return this.appState === AppStates.Main;
    }

    get isSearchAddressBookAppState(): boolean {
        return this.appState === AppStates.SearchAddressBook;
    }

    constructor(
        private navService: NavService,
        private authService: AuthService,
        private printModeService: PrintModeService,
        private appStateService: AppStateService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private userInactivitymonitorService: UserInactivityMonitorService,
    ) {
        // sets the root view to be used with shortcuts
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(): void {
        this.authService.currentUser.subscribe((user: ILoggedIn) => {
            if (user) {
                this.authService.logout();
            }
        });

    }

    @HostListener('window:unload', ['$event'])
    unloadHandler(): void {
        this.authService.currentUser.subscribe((user: ILoggedIn) => {
            if (user) {
                this.authService.logout();
            }
        });
    }

    ngOnInit(): void {
        this.navService.showSubmitHelpButton = false;
        this.subscriptions.add(
            this.printModeService.printMode.subscribe((inPrintMode) => {
                this.navService.showNav.next(!inPrintMode);
                this.navService.showFooter.next(!inPrintMode);
                this.cdr.detectChanges();
            }),
        );
        this.subscriptions.add(
            this.authService.appReady.subscribe((answer) => {
                this.appReady = answer;
                this.cdr.detectChanges();
            }),
        );
        this.sidebarCollapsed = this.navService.sidebarCollapsed.getValue();
        this.subscriptions.add(
            this.navService.sidebarCollapsed.subscribe((sidebarCollapsed: boolean) => {
                this.sidebarCollapsed = sidebarCollapsed;
                this.cdr.detectChanges();
            }),
        );

        this.showNav = this.navService.showNav.getValue();
        this.subscriptions.add(
            this.navService.showNav.subscribe((showNav: boolean) => {
                this.showNav = showNav;
                this.cdr.detectChanges();
            }),
        );

        this.appState = this.appStateService.appState.getValue();
        this.subscriptions.add(
            this.appStateService.appState.subscribe((appState: AppStates) => {
                this.appState = appState;
                this.cdr.detectChanges();
            }),
        );

        this.showFooter = this.navService.showFooter.getValue();
        this.subscriptions.add(
            this.navService.showFooter.subscribe((showFooter: boolean) => {
                this.showFooter = showFooter;
                this.cdr.detectChanges();
            }),
        );

        this.subscriptions.add(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    if (event.url === '/verify') {
                        this.verifyPath = true;
                        this.navService.showNav.next(false);
                    } else if (event.url.includes('resetpassword')) {
                        this.verifyPath = false;
                        this.navService.showNav.next(false);
                    } else  {
                        this.verifyPath = false;
                        this.navService.showNav.next(true);
                    }
                }
            }),
        );

        this.userInactivitymonitorService.init(15,PortalTypeEnums.Client);
    }

    closeAddressBookAction(): void {
        this.appStateService.appState.next(AppStates.Main);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
