import { Component, OnInit, inject} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { IUser } from '@model/interfaces/user';
import { UserService } from '../user.service';

@Component({
    selector: 'app-user-header',
    templateUrl: './user-header.component.html',
})
export class UserHeaderComponent implements OnInit {
    user: IUser;
    header$: Observable<string>;
    subscriptions: Subscription = new Subscription();

    readonly userService = inject(UserService);
    readonly route = inject(ActivatedRoute);

    ngOnInit(): void {
        const id = +this.route.snapshot.paramMap.get('userId');
        this.userService.setInitialTitle(id);
        this.header$ = this.userService.title$;
    }
}
