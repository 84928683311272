export enum AddressBookSelectionEnums {
    ParaLegal = 'ParaLegal',
    DefenseAttorney = 'DefenseAttorney',
    Other = 'Other',
    PlantiffAttorney = 'PlantiffAttorney',
    CarrierParty = 'CarrierParty',
    Physician = 'Physician',
    AddressBook = 'AddressBook',
    Individual = 'Individual',
    TransportationServicesVendor = 'TransportationServicesVendor',
    TranslationServicesVendor = 'TranslationServicesVendor',
    BackgroundInvestigationVendor = 'BackgroundInvestigationVendor',
}
