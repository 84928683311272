import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { AuthService } from '@mt-ng2/auth-module';
import { IAddressBook } from '@model/interfaces/address-book';
import { MetaAddressBookTypeIdEnums } from '../constants/meta-address-book-type.enums';
import { IAddressBooksForList } from '@model/interfaces/address-books-for-list';

export const emptyAddressBook: IAddressBook = {
    AddressBookTypeId: null,
    Archived: false,
    Attention: null,
    CreatedById: null,
    DateCreated: new Date(),
    Ein: null,
    FacilityName: null,
    Id: 0,
    Individuals: [],
    MetaAddressBookTypeId: MetaAddressBookTypeIdEnums.Facility_Organization,
    ModifiedById: null,
    Notes: null,
    PrintOnCheckAs: null,
};

export interface IHasCreatedBy {
    CreatedById: number;
    DateCreated: Date;
    IsPrimary?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class AddressBookService extends BaseService<IAddressBook> {
    constructor(public http: HttpClient, public authService: AuthService) {
        super('/address-books', http);
    }

    getEmptyAddressBook(): IAddressBook {
        return { ...emptyAddressBook };
    }

    searchAddressBooks(data: SearchParams): any {
        const params = this.getHttpParams(data);
        return this.http.get<IAddressBooksForList>(`/client/address-books/_searchAddressBookPayload`, { observe: 'response', params: params });
    }
}
