import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/operators';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ImedClaimServiceFollowupsEntityListConfig } from './imed-claim-service-followups.entity-list-config';
import { MtSearchBarComponent } from '@mt-ng2/searchbar-control';
import { IImedClaimServiceFollowup } from '@model/interfaces/imed-claim-service-followup';
import { ImedClaimServiceFollowupService } from './imedclaimservicefollowup.service';
import { entityListModuleConfig } from '../../../common/shared.module';

@Component({
    selector: 'app-imed-claim-service-followups',
    templateUrl: './imed-claim-service-followups.component.html',
})
export class ImedClaimServiceFollowupsComponent implements OnInit {
    imedClaimServiceFollowups: IImedClaimServiceFollowup[] = [];
    currentPage = 1;
    serviceId: number;
    total: number;
    entityListConfig = new ImedClaimServiceFollowupsEntityListConfig();
    imedClaimServiceId: number;
    imedClaimId: number;
    @ViewChild('searchBar') searchBar: MtSearchBarComponent;

    constructor(private imedClaimServiceFollowupService: ImedClaimServiceFollowupService, private router: Router, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.imedClaimServiceId = +this.route.snapshot.paramMap.get('serviceId');
        this.imedClaimId = +this.route.parent.parent.snapshot.paramMap.get('imedClaimId');
        this.getImedClaimServiceFollowups();
    }

    getImedClaimServiceFollowups(): void {
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            query: '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.imedClaimServiceFollowupService.search(searchparams, this.imedClaimServiceId).subscribe((answer) => {
            this.imedClaimServiceFollowups = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );

        return _extraSearchParams;
    }

    close(): void {
        void this.router.navigate(['cases', this.imedClaimId, 'services']);
    }
}
