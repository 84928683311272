import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { ISubpoenaService } from '@model/interfaces/subpoena-service';

export const emptySubpoenaService: ISubpoenaService = {
    AffidavitOfService: true,
    CertificationOfRecords: true,
    DepositionSubpoena: false,
    DomesticationRequired: false,
    Id: 0,
    ImedClaimServiceId: 0,
    LetterOfIntent: true,
    LetterOfRepresentation: true,
    OtherSubpoena: false,
    PhysicalCheckToFacilityRequired: false,
    PhysicalCheckToFacilityRequiredProcessServerFee: false,
    PhysicalCheckToFacilityRequiredWitnessFee: false,
    PhysicalCheckToForeignCourtRequiredWitnessFee: false,
    ProcessServerRequired: false,
    ProofOfservice: true,
    ProofOfServiceOfObjection: true,
    Reference: '',
    SubpoenaCoverPage: true,
    SubpoenaDucesTecum: false,
    TrialSubpoena: false,
    OptionalField1: '',
    OptionalField2: '',
    PhysicalCheckToFacilityRequiredFacilityFee: false,
};

@Injectable({
    providedIn: 'root',
})
export class SubpoenaServiceService extends BaseService<ISubpoenaService> {
    constructor(public http: HttpClient) {
        super('/subpoenaservices', http);
    }

    getEmptySubpoenaService(): ISubpoenaService {
        return { ...emptySubpoenaService };
    }
}
