import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { ITask } from '@model/interfaces/task';
import { Observable } from 'rxjs';
import { ITaskPartial } from '../model/partials/task-partial';
import { SearchParams } from '@mt-ng2/common-classes';

export const emptyTask: ITaskPartial = {
    Archived: false,
    AssignedToTypeId: 1,
    CreatedById: 0,
    DateCreated: new Date(),
    DueDate: null,
    Id: 0,
    ImedClaimId: 0,
    IsAutomated: true,
    IsPhysicalCheckRequiredToFacility: false,
    Notes: null,
    TaskDescriptions: null,
    TaskStatusId: 1,
    TriggeredFromServicePhysicalCheckRequiredToFacility: false,
    TriggeredFromServiceProcessServerFee: false,
    TriggeredFromServiceWitnessFee: false,
    UnsureAssignee: false,
    TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired: false,
    TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired: false,
};

@Injectable({
    providedIn: 'root',
})
export class TaskService extends BaseService<ITask> {
    constructor(public http: HttpClient) {
        super('/client/tasks', http);
    }

    getEmptyTask(): ITask {
        return { ...emptyTask };
    }

    createManuallyAssignedContactTask(task: ITask): Observable<number> {
        return this.http.post<number>(`/client/tasks/manually-assigned-contacts`, task);
    }

    createTask(task: ITaskPartial[]): Observable<number> {
        return this.http.post<number>(`/client/tasks/assign`, task);
    }

    getTasksForClaim(claimId: number, searchParams: SearchParams): Observable<any> {
        return this.http.get<any>(`/client/tasks/${claimId}/search-tasks`, { observe: 'response', params: this.getHttpParams(searchParams) });
    }

    updateTask(data: ITask): Observable<number> {
        return this.http.post<number>(`/client/tasks/update-task/${data.Id}`, data);
    }
}
