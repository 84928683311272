import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '../../../client-portal/model/ClaimTypes';

@Injectable({
    providedIn: 'root',
})
export class NoAccessGuard  {
    get hasCasesAccess(): boolean {
        return this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
    }

    constructor(private router: Router, private claimsService: ClaimsService) { }

    canActivate(): boolean {
        if (!this.hasCasesAccess) {
            void this.router.navigate(['/no-access']);
            return false;
        }

        return true;
    }
}
