
import { Validators } from '@angular/forms';
import { AddressDynamicControls, IAddressDynamicControlsParameters } from '@model/form-controls/address.form-controls';
import { IAddress } from '@model/interfaces/address';
import { IAddressType } from '@model/interfaces/address-type';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    SelectInputTypes,
    IDynamicFieldType,
    IDynamicField,
} from '@mt-ng2/dynamic-form';

export interface IAddressDynamicControlsParametersPartial extends IAddressDynamicControlsParameters {
    addressTypes?: IAddressType[];
    selectedAddressTypeIdsArray?: number[];
}

export class AddressDynamicControlsPartial extends AddressDynamicControls {
    constructor(addressPartial?: IAddress, additionalParameters?: IAddressDynamicControlsParametersPartial) {
        super(addressPartial, additionalParameters);

        this.Form.AssociatedAddressTypes = new DynamicField({
            formGroup: this.formGroup,
            label: 'Select Address Types',
            name: 'AssociatedAddressTypes',
            options: additionalParameters?.addressTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                maxToShowInSelectedText: 1,
                showSelectAllButtons: true,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value: additionalParameters?.selectedAddressTypeIdsArray,
        } as IDynamicField);

        (<DynamicField>this.Form.Address1).setRequired(false);
        (<DynamicField>this.Form.City).setRequired(false);
        (<DynamicField>this.Form.StateId).setRequired(false);
        (<DynamicField>this.Form.Zip).setRequired(false);
    }
}
