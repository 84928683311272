export enum AddressBookTypeIdEnums {
    Claimant_physicians_and_medical_facilities = 1,
    Claimant_employers = 2,
    Claimant_schools = 3,
    Government_offices = 4,
    Physician_panel = 5,
    Expert_panel = 6,
    Carrier = 7,
    Attorney = 8,
    Translation_Service = 9,
    Background_Investigators = 10,
    Transportation_Providers = 11,
    Rental_Facility = 15,
    Transcription_Service = 16,
}
