import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ImedClaimTypeService } from '../services/imed-claim-type.service';
import { ImedClaimRequestDynamicControlsPartial } from '@model/partials/imed-claim-request.form-controls';
import { AddressDynamicControlsPartial } from '@model/partials/address-partial.form-controls';
import { ServiceRequestTypeService } from '../services/service-request-type.service';
import { CommonService } from '../../common/services/common.service';
import { IState } from '@model/interfaces/state';
import { ImedClaimRequestService } from '../services/imed-claim-request.service';
import { AddressTypes } from '../../model/enums/AddressTypes';
import { IImedClaimRequest } from '@model/interfaces/imed-claim-request';
import { IAddress } from '@model/interfaces/base';
import { IServiceRequestType } from '@model/interfaces/service-request-type';
import { IImedClaimRequestService } from '@model/interfaces/imed-claim-request-service';
import { IssueRequestTypeService } from '../services/issue-request-type.service';
import { IIssueRequestType } from '@model/interfaces/issue-request-type';
import { IImedClaimRequestIssue } from '@model/interfaces/imed-claim-request-issue';
import { ModalService } from '@mt-ng2/modal-module';
import { IImedClaimType } from '@model/interfaces/imed-claim-type';
import { finalize } from 'rxjs/operators';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'imed-claim-request-form',
    styles: [
        `
            .miles-form {
                font-size: 12px;
            }
            .section-header {
                font-size: 15px;
                font-weight: bold;
                text-decoration: underline;
                margin-bottom: 10px;
                display: block;
            }
            .space-for-validation {
                margin-bottom: 5px !important;
            }
            .service-type-container {
                display: inline-block;
                margin-left: 20px;
            }
            .issue-type-container {
                display: inline-block;
                margin-left: 10px;
            }
            .issue-form {
                font-size: 11px;
            }
        `,
    ],
    templateUrl: './imed-claim-request-form.component.html',
})
export class ImedClaimRequestFormComponent implements OnInit {
    // abstract controls
    abstractImedClaimRequestControls: any;
    abstractClaimaintAddressControls: any;
    abstractClaimaintAttorneyAddressControls: any;
    abstractCarrierAddressControls: any;

    imedClaimRequestForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    states: IState[];
    imedClaimRequest: IImedClaimRequest;
    serviceRequestTypes: IServiceRequestType[];
    issueRequestTypes: IIssueRequestType[];
    issueRequestTypeIds: number[] = [];
    mmiStateSpecificVerbiage: string;
    claimTypes: IImedClaimType[];
    serviceTypeIds: number[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private imedClaimTypeService: ImedClaimTypeService,
        private serviceRequestTypeService: ServiceRequestTypeService,
        private commonService: CommonService,
        private imedClaimRequestService: ImedClaimRequestService,
        private issueRequestTypeService: IssueRequestTypeService,
        private modalService: ModalService,
    ) {}

    ngOnInit(): void {
        forkJoin(
            this.imedClaimTypeService.getItems(),
            this.serviceRequestTypeService.getItems(),
            this.commonService.getStates(),
            this.issueRequestTypeService.getItems(),
        ).subscribe((answer) => {
            this.claimTypes = answer[0].slice();
            this.claimTypes.push({
                Id: 0,
                Name: 'Other',
            });
            this.serviceRequestTypes = answer[1];
            this.states = answer[2];
            this.issueRequestTypes = answer[3];
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
        this.imedClaimRequestForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractImedClaimRequestControls = new ImedClaimRequestDynamicControlsPartial(null, {
            formGroup: 'ImedClaimRequest',
            imedClaimTypes: this.claimTypes,
            serviceRequestTypes: this.serviceRequestTypeService.items,
        }).Form;
        this.abstractCarrierAddressControls = new AddressDynamicControlsPartial(null, {
            formGroup: 'CarrierAddress',
            states: this.states,
        }).Form;
        this.abstractClaimaintAddressControls = new AddressDynamicControlsPartial(null, {
            formGroup: 'ClaimantAddress',
            states: this.states,
        }).Form;
        this.abstractClaimaintAttorneyAddressControls = new AddressDynamicControlsPartial(null, {
            formGroup: 'ClaimantAttorneyAddress',
            states: this.states,
        }).Form;
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            CarrierAddress: this.fb.group({}),
            ClaimantAddress: this.fb.group({}),
            ClaimantAttorneyAddress: this.fb.group({}),
            ImedClaimRequest: this.fb.group({}),
        });
    }

    onIssueRequestTypeChange(id: number): void {
        const indexOfId = this.issueRequestTypeIds.indexOf(id);
        if (indexOfId === -1) {
            this.issueRequestTypeIds.push(id);
        } else {
            this.issueRequestTypeIds.splice(indexOfId, 1);
        }
    }

    onServiceTypeChange(id: number): void {
        const indexOfId = this.serviceTypeIds.indexOf(id);
        if (indexOfId === -1) {
            this.serviceTypeIds.push(id);
        } else {
            this.serviceTypeIds.splice(indexOfId, 1);
        }
    }

    formSubmitted(): void {
        if (this.imedClaimRequestForm.valid) {
            this.imedClaimRequest = this.imedClaimRequestForm.value.ImedClaimRequest;
            this.imedClaimRequest.Id = 0;
            if (this.imedClaimRequest.ImedClaimTypeId === 0) {
                this.imedClaimRequest.ImedClaimTypeId = null;
                this.imedClaimRequest.IsOtherClaimType = true;
            }
            this.assignAddressValues();
            this.imedClaimRequest.ImedClaimRequestServices = this.assignServiceValues(this.serviceTypeIds);
            this.imedClaimRequest.ImedClaimRequestIssues = this.assignIssueValues(this.issueRequestTypeIds);
            this.imedClaimRequest.MmiNotes = this.mmiStateSpecificVerbiage;
            this.imedClaimRequestService.createWithFks(this.imedClaimRequest).subscribe(() => {
                this.modalService
                    .showModal({
                        text: 'Thank you for your submission.',
                        title: 'Thank you!',
                        icon: 'success',
                    })
                    .subscribe(() => {
                        this.cancelClick();
                    });
            });
        } else {
            markAllFormFieldsAsTouched(this.imedClaimRequestForm);
            this.error();
        }
    }

    private assignAddressValues(): void {
        if (this.isValidAddress(this.imedClaimRequestForm.value.CarrierAddress as IAddress)) {
            const carrierAddress = this.setAddressDefaultValues(this.imedClaimRequestForm.value.CarrierAddress as IAddress);
            this.imedClaimRequest.CarrierAddress = carrierAddress;
        }
        if (this.isValidAddress(this.imedClaimRequestForm.value.ClaimantAddress as IAddress)) {
            const claimantAddress = this.setAddressDefaultValues(this.imedClaimRequestForm.value.ClaimantAddress as IAddress);
            this.imedClaimRequest.ClaimantAddress = claimantAddress;
        }
        if (this.isValidAddress(this.imedClaimRequestForm.value.ClaimantAttorneyAddress as IAddress)) {
            const claimantAttorneyAddress = this.setAddressDefaultValues(this.imedClaimRequestForm.value.ClaimantAttorneyAddress as IAddress);
            this.imedClaimRequest.ClaimantAttorneyAddress = claimantAttorneyAddress;
        }
    }

    private setAddressDefaultValues(address: IAddress): IAddress {
        address.Id = 0;
        address.AddressTypeId = AddressTypes.CorrespondenceAddress;
        address.IsPrimary = true;
        // Set the created by to the root admin
        address.CreatedById = 1;
        return address;
    }

    private assignServiceValues(serviceIds: number[]): IImedClaimRequestService[] {
        const services: IImedClaimRequestService[] = [];
        serviceIds.forEach((id) => {
            services.push({
                Id: 0,
                ImedClaimRequestId: 0,
                ServiceRequestTypeId: id,
            });
        });
        return services;
    }

    private assignIssueValues(issueIds: number[]): IImedClaimRequestIssue[] {
        const issues: IImedClaimRequestIssue[] = [];
        issueIds.forEach((id) => {
            issues.push({
                Id: 0,
                ImedClaimRequestId: 0,
                IssueRequestTypeId: +id,
            });
        });
        return issues;
    }

    cancelClick(): void {
        void this.router.navigate(['../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('ImedClaimRequest saved successfully.');
    }

    isValidAddress(address: IAddress): boolean {
        return address.Address1 !== '' && address.City !== '' && address.StateId != null && address.Zip !== '';
    }
}
