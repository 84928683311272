import { DynamicField } from '@mt-ng2/dynamic-form';
import { ImedClaimDynamicControls, IImedClaimDynamicControlsParameters } from '@model/form-controls/imed-claim.form-controls';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { AbstractControl } from '@angular/forms';

export class ImedClaimDynamicControlsPartial extends ImedClaimDynamicControls {
    constructor(
        imedClaim?: IImedClaim,
        claimNumberControl?: AbstractControl,
        fileNumberControl?: AbstractControl,
        additionalParameters?: IImedClaimDynamicControlsParameters,
    ) {
        super(imedClaim, additionalParameters);
        if (imedClaim.FileNumber || (fileNumberControl && fileNumberControl.value !== '')) {
            (<DynamicField>this.Form.ClaimNumber).setRequired(false);
        } else {
            (<DynamicField>this.Form.ClaimNumber).setRequired(true);
        }
        if (imedClaim.ClaimNumber || (claimNumberControl && claimNumberControl.value !== '')) {
            (<DynamicField>this.Form.FileNumber).setRequired(false);
        } else {
            (<DynamicField>this.Form.FileNumber).setRequired(true);
        }

        (<DynamicField>this.Form.RequestorTypeId).setRequired(true);

        if (imedClaim.Id === 0) {
            (<DynamicField>this.Form.Notes).labelHtml =
                '<label>Notes <span class="text text-danger">(Use this field to let us know about preferred vendors, deadlines, CARRIER/BILL-TO PARTIES, venues, special considerations regarding injury, etc.)</span>:</label>';
        }
        (<DynamicField>this.Form.Notes).validators = {};
        (<DynamicField>this.Form.Notes).validation = [];
        (<DynamicField>this.Form.DateofAllegedAccidentOrInjury).labelHtml = '<label>Date of Alleged Accident or Injury</label>';
        (<DynamicField>this.View.DateofAllegedAccidentOrInjury).label = 'Date of Alleged Accident or Injury';
        (<DynamicField>this.Form.Ssn).setMaxLength(4);
        (<DynamicField>this.Form.Ssn).labelHtml = '<label>Last 4 of SSN</label>';
        (<DynamicField>this.View.Ssn).label = 'Last 4 of SSN';

        (<DynamicField>this.Form.ImedClaimStatusId).disabled = true;
        (<DynamicField>this.Form.ImedClaimStatusId).labelHtml = '<label>Status</label>';
        (<DynamicField>this.View.ImedClaimStatusId).label = 'Status';
    }
}
