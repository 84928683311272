import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, MtTwoFactorGuard } from '@mt-ng2/auth-module';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { ResetPasswordComponent, AdminAccessComponent, TwoFactorComponent } from '@mt-ng2/login-module';
import { appPaths } from './default-routes/app-paths.library';
import { ImedClaimListComponent } from './imed-claims/imed-claim-list/imed-claim-list.component';
import { ClientAccessComponent } from './ClientAccessComponent';
import { RequestAccessComponent } from '../client-portal/common/request-access/request-access.component';
import { ClientPortalLoginComponent } from './client-portal-login.component';
import { ClientPortalForgotPasswordComponent } from './client-portal-forgot-password.component';
import { ImedClaimRequestComponent } from './imed-claim-requests/imed-claim-request/imed-claim-request.component';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { AppRoutingComponent } from './app-routing.component';
import { NoAccessGuard } from './common/services/no-access-guard';

const appRoutes: Routes = [
    { path: appPaths.login, component: ClientPortalLoginComponent },
    { path: appPaths.forgotPassword, component: ClientPortalForgotPasswordComponent },
    { path: appPaths.requestAccess, component: RequestAccessComponent },

    { path: appPaths.resetPassword, component: ResetPasswordComponent , title: 'Reset Password' },

    { path: appPaths.adminAccess, component: AdminAccessComponent , title: 'Admin Access' },
    { path: appPaths.clientAccess, component: ClientAccessComponent },
    { path: appPaths.serviceRequest, component: ImedClaimRequestComponent },
    {
        canActivate: [AuthGuard, NoAccessGuard],
        component: ImedClaimListComponent,
        path: appPaths.home,
    },
    {
        canActivate: [AuthGuard],
        component: UploadDocumentsComponent,
        path: appPaths.clientDocumentUpload,
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        component: UserDetailComponent,
        path: appPaths.myProfile,
        pathMatch: 'full',
    },
    {
        canActivate: [MtTwoFactorGuard],
        component: TwoFactorComponent,
        path: appPaths.verify2FA,
    },
    {
        canActivate: [AuthGuard],
        component: AppRoutingComponent,
        path: '**',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload'})],
})
export class AppRoutingModule {}
