import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { SearchParams } from '@mt-ng2/common-classes';
import { IImedClaimServiceFollowup } from '@model/interfaces/imed-claim-service-followup';

@Injectable({
    providedIn: 'root',
})
export class ImedClaimServiceFollowupService extends BaseService<IImedClaimServiceFollowup> {
    constructor(public http: HttpClient) {
        super('/imedclaimservicefollowups', http);
    }

    search(searchparameters: SearchParams, imedClaimServiceId: number): Observable<HttpResponse<IImedClaimServiceFollowup[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http.get<IImedClaimServiceFollowup[]>('/client/imedclaimservicefollowups/_search/' + imedClaimServiceId, {
            observe: 'response',
            params: params,
        });
    }
}
