import { AuthUserDynamicControls } from '@model/form-controls/auth-user.form-controls';
import { IAuthUser } from '@model/interfaces/auth-user';
import { IUserRole } from '@model/interfaces/user-role';
import { Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';

export class AuthUserDynamicControlsExtended extends AuthUserDynamicControls {
    constructor(private extAuthUser: IAuthUser, userRoles?: IUserRole[]) {
        super(extAuthUser, { roles: userRoles });
        const passwordRegexPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        const failedPatternMessage = 'Password too weak. Must be at least 8 characters. Must have  upper case, lower case, a number, and a special character.';
        // TODO: JJG This is a little hacky to get around the contructor object intellisense.
        // Since I am adding dynamic properties not on the model I need to do this to get around
        // the TS compiler complaining.
        if (!userRoles) {
            if (!this.extAuthUser || this.extAuthUser.Id === 0) {
                const sendEmail = new DynamicField({
                    formGroup: 'AuthUser',
                    label: 'Send Password Reset Email',
                    name: 'SendResetEmail',
                    type: new DynamicFieldType({
                        fieldType: DynamicFieldTypes.Checkbox,
                    }),
                    value: null,
                });
                this.Form.SendResetEmail = sendEmail;
            }

            this.Form.ConfirmPassword = new DynamicField({
                formGroup: 'AuthUser',
                label: 'Confirm Password',
                name: 'ConfirmPassword',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                }),
                validation: [Validators.required, Validators.pattern(passwordRegexPattern)],
                validators: { pattern: passwordRegexPattern, required: true },
                value: '',
            });

            this.Form.CurrentPassword = new DynamicField({
                formGroup: 'AuthUser',
                label: 'Current Password',
                name: 'CurrentPassWord',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: '',
            });
        }

        if (this.Form.ConfirmPassword) {
            this.Form.ConfirmPassword.failedPatternMessage = failedPatternMessage;
        }
        this.Form.Password.setPattern(passwordRegexPattern);
        this.Form.Password.failedPatternMessage = failedPatternMessage;
    }
}
