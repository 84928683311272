import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IAddressBookType } from '@model/interfaces/address-book-type';

@Injectable({
    providedIn: 'root',
})
export class AddressTypeService extends StaticMetaItemService<IAddressBookType> {
    constructor(public http: HttpClient) {
        super('AddressTypeService', 'Type', 'TypeIds', '/options/addressTypes', http);
    }
}
