import { IAddressBooksForList } from '@model/interfaces/address-books-for-list.d';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import 'rxjs/operators';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { AddressBooksSelectEntityListConfig } from './address-books-select.entity-list-config';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { AddressBooksSelectService } from './address-books-select.service';
import { entityListModuleConfig } from '../shared.module';
import { AppStateService, AppStates } from '../../app-state.service';
import { Subscription } from 'rxjs';
import { AddressBookService } from '../address-books/addressbook.service';
import { AddressBookTypeService } from '../address-books/addressbooktype.service';
import { AddressBookTypeIdEnums } from '../constants/address-book-type.enums';

@Component({
    selector: 'address-books-select',
    templateUrl: './address-books-select.component.html',
})
export class AddressBooksSelectComponent implements OnInit, OnDestroy {
    @Input() typeFilter: string;
    @Output('closeAddressBookAction') closeAddressBookAction: EventEmitter<string> = new EventEmitter<string>();

    searchControl = new UntypedFormControl();
    addressBooks: IAddressBooksForList[];
    currentPage = 1;
    query = '';
    total: number;
    metaAddressBookTypes: MtSearchFilterItem[] = [];
    addressBookTypes: MtSearchFilterItem[] = [];
    canAddAddressBook = false;
    entityListConfig = new AddressBooksSelectEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    subscriptions = new Subscription();
    constructor(
        private addressBookService: AddressBookService,
        private addressBookTypesService: AddressBookTypeService,
        private addressBookSelectService: AddressBooksSelectService,
        private router: Router,
        private appStateService: AppStateService,
    ) { }

    ngOnInit(): void {
        this.getAddressBooks();
        this.subscriptions.add(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    // the route has changed, so lets change the AppState to Main
                    // since we shouldn't be showing this search if a route change
                    // has occurred
                    this.appStateService.appState.next(AppStates.Main);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private buildSearch(): ExtraSearchParams[] {
        const clientPortalAddressBookTypeIds: number[] = [AddressBookTypeIdEnums.Attorney, AddressBookTypeIdEnums.Carrier];
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressBookTypeIds',
                valueArray: clientPortalAddressBookTypeIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: '0',
            }),
        );

        return _extraSearchParams;
    }

    getAddressBooks(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.addressBookService.searchAddressBooks(searchparams).subscribe((answer) => {
            this.addressBooks = answer.body;
            this.addressBooks.map((addressBook) => (addressBook.SearchType = this.typeFilter));
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getAddressBooks();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getAddressBooks();
    }

    close(): void {
        this.closeAddressBookAction.emit('');
    }
}
