// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
        .logo-img {
            max-height: 50px;
        }
        .header-text {
            color: lightblue;
            font-size: 13px;
        }
    `, "",{"version":3,"sources":["webpack://./src/client-portal/imed-claim-requests/imed-claim-request/imed-claim-request.component.ts"],"names":[],"mappings":";QACQ;YACI,gBAAgB;QACpB;QACA;YACI,gBAAgB;YAChB,eAAe;QACnB","sourcesContent":["\n        .logo-img {\n            max-height: 50px;\n        }\n        .header-text {\n            color: lightblue;\n            font-size: 13px;\n        }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
