import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';

import { EnvironmentService } from '@mt-ng2/environment-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { NavService } from '@mt-ng2/nav-module';
import { AuthService } from '@mt-ng2/auth-module';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { PageTitlesService } from '@mt-ng2/page-titles';
import { LoginConfig, LoginModuleConfigToken, ILoginConfig } from '@mt-ng2/login-module';

@Component({
    selector: 'app-client-portal-forgot-password',
    template: `
        <div class="login-box">
            <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
            <div class="login-box-body">
                <p class="login-box-msg">{{ config?.messageOverrides.forgotPasswordLabel }}</p>
                <form *ngIf="forgotPasswordForm" [formGroup]="forgotPasswordForm" (submit)="onLogin()">
                    <div class="form-group has-feedback" [class.has-error]="emailHasError()">
                        <input type="text" autofocus class="form-control" placeholder="example@domain.com" formControlName="email" />
                        <span class="fa fa-user form-control-feedback"></span>
                        <div *ngIf="showEmailRequiredError()" class="small errortext" [style.position]="'block'">Email is required</div>
                        <div *ngIf="showEmailInvalidError()" class="small errortext" [style.position]="'block'">
                            Invalid email address. Valid e-mail can contain only letters, numbers and '@'
                        </div>
                    </div>
                    <div class="padded block-parent">
                        <button type="button" routerLink="/login" class="btn btn-default btn-flat inline-block block-left">Back</button>
                        <button [disabled]="!forgotPasswordForm.valid" type="submit" class="btn btn-primary btn-flat inline-block block-right">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    `,
})
export class ClientPortalForgotPasswordComponent implements OnInit {
    forgotPasswordForm: UntypedFormGroup;
    logoFull = `${this.environmentService.config.imgPath}logo-full.png`;
    public config: LoginConfig;

    constructor(
        private fb: UntypedFormBuilder,
        private navService: NavService,
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private environmentService: EnvironmentService,
        @Inject(LoginModuleConfigToken) private loginConfig: ILoginConfig,
        private pageTitlesService: PageTitlesService,
    ) {}

    ngOnInit(): void {
        // appReady determines if an authenticated connection has been made.
        // While it's waiting it shows a loading icon.  When appReady has a
        // value the loading icon is hidden.  We always want this to be true
        // when you are on the login page.  Because you aren't authed!
        if (this.authService.appReady && !this.authService.appReady.getValue()) {
            this.authService.appReady.next(true);
        }
        this.config = new LoginConfig(this.loginConfig);
        this.navService.setShowNav(false);
        this.createForm();
        this.setPageTitle();
    }

    setPageTitle(): void {
        this.pageTitlesService.setPageTitle('Forgot Password');
    }

    createForm(): void {
        if (!this.forgotPasswordForm) {
            this.forgotPasswordForm = this.fb.group({});
        }
        this.forgotPasswordForm.addControl('email', new UntypedFormControl('', [(c: AbstractControl) => Validators.required(c), (c: AbstractControl) => Validators.email(c)]));
    }

    emailHasError(): boolean {
        const control = this.forgotPasswordForm.get('email');
        return control.errors && (control.touched || control.errors.maxlength);
    }

    showEmailRequiredError(): boolean {
        const control = this.forgotPasswordForm.get('email');
        return control.touched && control.hasError('required');
    }

    showEmailInvalidError(): boolean {
        const control = this.forgotPasswordForm.get('email');
        return control.touched && control.hasError('email');
    }

    onLogin(): void {
        if (this.forgotPasswordForm.valid) {
            const values = this.forgotPasswordForm.value;
            this.authService.forgot(values.email as string).subscribe(
                () => {
                    this.notificationsService.success(this.config.messageOverrides.forgotPasswordSuccess);
                },
                (errorResponse) => {
                    if (errorResponse.status === 400) {
                        if (errorResponse.error) {
                            this.notificationsService.error(errorResponse.error as string);
                        } else {
                            this.notificationsService.error(this.config.messageOverrides.forgotPasswordFailure);
                        }
                    }
                },
            );
        } else {
            markAllFormFieldsAsTouched(this.forgotPasswordForm);
        }
    }
}
