import { EntityListConfig, IEntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { IImedClaim } from '@model/interfaces/imed-claim';

export class ImedClaimsEntityListConfig extends EntityListConfig {
    constructor() {
        const attorneyAccessorGenerator = function (attorneyType: string): (claim: IImedClaim) => string {
            return function (claim: IImedClaim): string {
                const attorneyAccessorIndiv = `${attorneyType}Individual`;
                const attorneyAccessorFacility = `${attorneyType}AddressBook`;
                return (
                    (claim[attorneyAccessorIndiv] && `${claim[attorneyAccessorIndiv].FirstName} ${claim[attorneyAccessorIndiv].LastName}`) ||
                    (claim[attorneyAccessorFacility] && claim[attorneyAccessorFacility].FacilityName) ||
                    'N/A'
                );
            };
        };

        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Id'],
                    name: 'Id',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IImedClaim): string {
                        if (ab.FirstName) {
                            return `${ab.FirstName} ${ab.LastName}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Claimant Name',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['ClaimNumber'],
                    name: 'Claim Number',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['FileNumber'],
                    name: 'File Number',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    // TODO: had to update model interface from Paralegal -> ParaLegal
                    accessorFunction: attorneyAccessorGenerator('ParaLegal'),
                    name: 'Paralegal',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: attorneyAccessorGenerator('DefenseAttorney'),
                    name: 'Defense Attorney',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    // note: db table has mispelling
                    accessorFunction: attorneyAccessorGenerator('PlantiffAttorney'),
                    name: 'Plaintiff Attorney',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: attorneyAccessorGenerator('CarrierParty'),
                    name: 'Carrier/Bill-To Party',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['ImedClaimStatus', 'Name'],
                    name: 'Status',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
