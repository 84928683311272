import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImedClaimService } from '../imed-claim.service';
import { Subscription, forkJoin } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ModalService as CustomModalService, ModalTypes } from '../../../app/common/services/modal.service';
import { IFilmInformationDTO } from '@model/interfaces/custom/film-information-dto';
import { IFilmInformation } from '@model/interfaces/film-information';
import { FilmInformationService } from '@app-shared/services/film-information.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ModalService } from '@mt-ng2/modal-module';
import { AuthService } from '@mt-ng2/auth-module';
import { ImedClaimServiceService } from '../services/imedclaimservice.service';
import { environment } from '../../environments/environment';
import { IImedClaimService } from '@model/partials/imed-claim-service.partial';

@Component({
    selector: 'imed-claim-header',
    templateUrl: './imed-claim-header.component.html',
})
export class ImedClaimHeaderComponent implements OnInit {
    claimId: number;
    claimantName = '';
    claimantBirthdate = '';
    defenseAttorneyName: string = null;
    header: string;
    showRadiologyReviewIcon = false;
    showAmbraStudyIcon = false;
    isDuplicateSubscription: Subscription;
    isDuplicate = false;
    subscriptions = new Subscription();
    filmInfo: IFilmInformationDTO[] = [];
    serviceId: string[];
    recordRetrievalService: IImedClaimService;

    constructor(
        private route: ActivatedRoute,
        private imedClaimService: ImedClaimService,
        private customModalService: CustomModalService,
        private titleService: Title,
        private datePipe: DatePipe,
        private router: Router,
        private filmInformationService: FilmInformationService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private authService: AuthService,
        private imedClaimServiceService: ImedClaimServiceService,
    ) {}

    ngOnInit(): void {
        this.imedClaimService.getServiceId().subscribe((serviceId) => {
            this.serviceId = [serviceId.toString()];
        });
        this.claimId = +this.route.snapshot.paramMap.get('imedClaimId');
        this.isDuplicateSubscription = this.imedClaimService.getIsDuplicate().subscribe((val) => (this.isDuplicate = val));
        this.getHeader();
        this.subscriptions.add(
            this.route.paramMap.pipe(filter((params) => this.claimId !== +params.get('imedClaimId'))).subscribe((params) => {
                this.claimId = +params.get('imedClaimId');
                this.getHeader();
            }),
        );
    }

    ngOnDestroy(): void {
        this.isDuplicateSubscription.unsubscribe();
    }

    getHeader(): void {
        if (this.route.snapshot.url[1].path === 'add' || this.route.snapshot.url[1].path === 'add-from-nav') {
            this.resetTitle();
            this.header = 'Enter a new case for processing';
        } else if (this.claimId > 0) {
            forkJoin([
                this.imedClaimService.getHeaderInfo(this.claimId),
                this.imedClaimService.getFilmInformationByImedClaimId(this.claimId),
            ]).subscribe(([info, filmInfo]) => {
                this.filmInfo = filmInfo;
                this.claimantName = info.ClaimantName;
                this.claimantBirthdate = info.Birthdate ? this.datePipe.transform(info.Birthdate, 'mediumDate', 'UTC') : 'Not provided';
                this.showRadiologyReviewIcon = info.HasRadiologyRetrieval;
                this.showAmbraStudyIcon = info.IsGeneralLiability;
                this.defenseAttorneyName = info.DefenseAttorneyName;
                this.setHeader();
                this.addClaimantNameToTitle();
            });
        } else {
            this.resetTitle();
            this.showRadiologyReviewIcon = false;
            this.header = 'Cases';
        }
    }

    resetTitle(): void {
        this.titleService.setTitle('Viewpoint Client Portal');
    }

    setHeader(): void {
        this.header = `Case: ${this.claimantName} | Birthdate: ${this.claimantBirthdate}`;
    }

    addClaimantNameToTitle(): void {
        this.titleService.setTitle(this.claimantName + ' - Viewpoint');
    }

    showFilmModal(modal): void {
        this.imedClaimService.getFilmInformationByImedClaimId(this.claimId).subscribe((films) => {
            this.filmInfo = films;
            this.customModalService.showDocumentModal(modal, ModalTypes.FILM);
        });
    }

    addAmbraStudy(): void {
        this.imedClaimServiceService.getRecordRetrievalImedClaimServiceId(this.claimId).subscribe((service) => {
            this.recordRetrievalService = service;
            if (this.recordRetrievalService && this.recordRetrievalService.Id) {
                this.showAmbraModel(this.recordRetrievalService.Id);
            } else {
                const recordRetrievalService = this.imedClaimService.createRecordRetrievalServiceForAmbraUpload(
                    this.claimId,
                    this.authService.currentUser.getValue().Id,
                    this.defenseAttorneyName,
                );
                this.imedClaimServiceService.saveServiceWithoutTask(recordRetrievalService).subscribe((imedClaimServiceId: number) => {
                    this.showAmbraModel(imedClaimServiceId);
                });
            }
        });
    }

    showAmbraModel(imedClaimServiceId: number): void {
        const userEmail = this.authService.currentUser.value.Email;
        const ambraUploadItemUrl =
            environment.ambraUploadUrl +
            `email_address=${encodeURIComponent(userEmail)}&suppress_notification=1&integration_key=${imedClaimServiceId}`;
        this.modalService
            .showModal({
                allowEscapeKey: false,
                allowOutsideClick: false,

                confirmButtonText: 'Close',
                html: `
                            <iframe
                                src="${ambraUploadItemUrl}"
                                height="900px"
                                width="100%">
                            </iframe>
                            <i class="small">*Note: Ambra studies require time to process after upload. You will receive an email once the processing completes and the study is ready for viewing.</i>
                        `,
                title: 'Upload Study',
                width: '80%',
                customClass: {
                    confirmButton: 'swal-ambra',
                },
            })
            .subscribe();
    }

    delete(filmInformation: IFilmInformation): void {
        filmInformation.Archived = true;
        this.filmInformationService.requestDelete(filmInformation.Id).subscribe(
            () => {
                this.notificationsService.success('Successfully requested the film to be deleted!');
            },
            () => {
                this.notificationsService.error('An error occurred while requesting the film to be deleted!');
            },
            () => {
                this.imedClaimService.getFilmInformationByImedClaimId(this.claimId).subscribe((filmInfo) => {
                    setTimeout(() => (this.filmInfo = filmInfo));
                });
            },
        );
    }
}
