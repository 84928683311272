import { Component, Input } from '@angular/core';

@Component({
    selector: 'upload-animation',
    styles: [`
            .dot-container {
                width: 100%;
                height: 100%;
                margin: 0 auto;
            }
            .generating {
                width: 100%;
                height: 100%;
                display: flex;
                opacity: 100%;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
            .dot {
                height: 1%;
                width: 1%;
                border-radius: 50%;
                margin: 1%;
                background: #858A9F;
                display: inline-block;
                animation: dot-strobe 1.5s infinite ease-in-out;
            }
            .dot1 {
                animation-delay: .2s;
            }
            .dot2 {
            animation-delay: .4s;
            }

            .dot3 {
            animation-delay: .6s;
            }
            @keyframes dot-strobe{
                0% {
                    height: 1%;
                    width: 1%;
                    background: rgba(68, 68, 68, .3);
                }
                50% {
                    height: 40%;
                    width: 40%;
                    background: rgba(68, 68, 68, .8);
                }
                100% {
                    height: 1%;
                    width: 1%;
                    background: rgba(68, 68, 68, .8);
                }
            }
            @-webkit-keyframes dot-strobe {
                0% {
                    height: 1%;
                    width: 1%;
                    background: rgba(68, 68, 68, .3);
                }
                50% {
                    height: 40%;
                    width: 40%;
                    background: rgba(68, 68, 68, .8);
                }
                100% {
                    height: 1%;
                    width: 1;
                    background: rgba(68, 68, 68, .3);
                }
            }
        `],
    template: `
        <div class="dot-container" [style.height]="height" [style.width]="width">
            <div class="generating">
                <span class="dot dot1"></span>
                <span class="dot dot2"></span>
                <span class="dot dot3"></span>
            </div>
        </div>
    `,
})
export class UploadAnimationComponent {
    @Input() height = '40px';
    @Input() width = '40px';
    constructor() {}
}
