import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'utcDate' })
export class UtcDate implements PipeTransform {
    transform(value: string): string {
        const utcDate = value ? new Date(value).toISOString() : '';
        if (utcDate) {
            return new Date(utcDate).mtDate.toMoment().utc().format('MM/DD/YYYY');
        } else {
            return '';
        }
    }
}
