import { AddressBooksSelectService } from './address-books-select.service';
import { EntityListConfig, EntityListColumn, IEntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { IAddressBooksForList } from '@model/interfaces/address-books-for-list';
import { AddressBookSelectOptionComponent } from './address-book-select-option.component';

export class AddressBooksSelectEntityListConfig extends EntityListConfig {
    columns: IEntityListColumn[];
    addressBookSelectService: AddressBooksSelectService;
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['FacilityName'],
                    name: 'Facility Name',
                }),
                new EntityListColumn({
                    accessors: ['Type'],
                    name: 'Facility Type',
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.FirstName) {
                            return `${ab.FirstName} ${ab.LastName} ${ab.Suffix}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Full Name',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.Address1) {
                            return `${ab.Address1} ${ab.Address2}, ${ab.City}
                            ${ab.StateCode} ${ab.Zip !== null ? ab.Zip : ''}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Address',
                    sort: { disableSort: true },
                    style: { width: 230 },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.CountyName) {
                            return `${ab.CountyName}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'County',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.IndividualPhoneNumbers) {
                            return `${ab.IndividualPhoneNumbers}`;
                        } else if (ab.AddressBookPhoneNumbers) {
                            return `${ab.AddressBookPhoneNumbers}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Phone',
                    pipes: ['phone'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.IndividualEmail) {
                            return `${ab.IndividualEmail}`;
                        } else if (ab.AddressBookEmail) {
                            return `${ab.AddressBookEmail}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Email',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.Specilaities) {
                            return `${ab.Specilaities}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Specialty',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.SpecilaitiyDetails) {
                            return `${ab.SpecilaitiyDetails}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Specialty Details',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessors: ['SubspecialtyNotes'],
                    name: 'Specialty Note',
                }),
                new EntityListColumn({
                    accessorFunction: function (ab: IAddressBooksForList): string {
                        if (ab.PhysicianStatus) {
                            return `${ab.PhysicianStatus}`;
                        } else {
                            return '';
                        }
                    },
                    name: 'Status',
                    sort: { disableSort: true },
                    style: { width: 200 },
                }),
                new EntityListColumn({
                    component: AddressBookSelectOptionComponent,
                    fireOnColumnSelected: false,
                    name: 'Select',
                    sort: { disableSort: true },
                    style: { width: 200 },
                }),
            ],
        };
        super(listConfig);
    }
}
