import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@mt-ng2/auth-module';
import { NavService } from '@mt-ng2/nav-module';
import { PageTitlesService } from '@mt-ng2/page-titles';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-imed-claim-request',
    styles: [`
        .logo-img {
            max-height: 50px;
        }
        .header-text {
            color: lightblue;
            font-size: 13px;
        }
    `],
    templateUrl: './imed-claim-request.component.html',
})
export class ImedClaimRequestComponent implements OnInit {
    imedClaimRequestForm: UntypedFormGroup;
    logoFull = `${environment.imgPath}logo-full.png`;

    constructor(
        private fb: UntypedFormBuilder,
        private navService: NavService,
        private authService: AuthService,
        private pageTitlesService: PageTitlesService,
    ) { }

    ngOnInit(): void {
        this.authService.isAuthenticated().subscribe((answer) => {
            this.navService.setShowNav(answer);
        });
        this.setPageTitle();
    }

    setPageTitle(): void {
        this.pageTitlesService.setPageTitle('Request Access');
    }

}
