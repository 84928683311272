import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IImedClaimDynamicControlsParameters } from '@model/form-controls/imed-claim.form-controls';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { IAddressBook } from '@model/interfaces/address-book';
import { IIndividual } from '@model/interfaces/individual';
import { IUser } from '@model/interfaces/user';
import { IImedClaimStatus } from '@model/interfaces/imed-claim-status';
import { IImage } from '@model/interfaces/image';
import { IRequestorType } from '@model/interfaces/requestor-type';
import { ImedClaimDynamicControlsPartial } from '../model/partials/imed-claim-partial.form-controls';
import { AbstractControl } from '@angular/forms';

export class ImedClaimDynamicConfig<T extends IImedClaim> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private imedClaim: T,
        private claimNumberControl: AbstractControl,
        private fileNumberControl: AbstractControl,
        private paraLegalAddressBooks?: IAddressBook[],
        private defenseAttorneyAddressBooks?: IAddressBook[],
        private plantiffAttorneyAddressBooks?: IAddressBook[],
        private carrierPartyAddressBooks?: IAddressBook[],
        private paraLegalIndividuals?: IIndividual[],
        private defenseAttorneyIndividuals?: IIndividual[],
        private plantiffAttorneyIndividuals?: IIndividual[],
        private carrierPartyIndividuals?: IIndividual[],
        private imedClaimStatuses?: IImedClaimStatus[],
        private letterHeadImages?: IImage[],
        private createdBies?: IUser[],
        private modifiedBies?: IUser[],
        private requestorTypes?: IRequestorType[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IImedClaimDynamicControlsParameters = {
            paraLegalAddressBooks: this.paraLegalAddressBooks,
            // tslint:disable-next-line: object-literal-sort-keys
            defenseAttorneyAddressBooks: this.defenseAttorneyAddressBooks,
            plantiffAttorneyAddressBooks: this.plantiffAttorneyAddressBooks,
            carrierPartyAddressBooks: this.carrierPartyAddressBooks,
            paraLegalIndividuals: this.paraLegalIndividuals,
            defenseAttorneyIndividuals: this.defenseAttorneyIndividuals,
            plantiffAttorneyIndividuals: this.plantiffAttorneyIndividuals,
            carrierPartyIndividuals: this.carrierPartyIndividuals,
            imedClaimStatuses: this.imedClaimStatuses,
            letterHeadImages: this.letterHeadImages,
            createdBies: this.createdBies,
            modifiedBies: this.modifiedBies,
            requestorTypes: this.requestorTypes,
        };
        const dynamicControls = new ImedClaimDynamicControlsPartial(
            this.imedClaim,
            this.claimNumberControl,
            this.fileNumberControl,
            additionalParams,
        );
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'FirstName',
                'LastName',
                'ImedClaimStatusId',
                'PastMedicalHistory',
                'PastSubsequentHistory',
                'HistoryOfAllegedInjury',
                'OccupationalStatus',
                'CurrentSymptomsAndCompliants',
                'CurrentCourseOfTreatment',
                'Impression',
                'DegreeOfDisability',
                'NeedForFurtherTreatment',
                'WorkStatus',
                'CausalRelationship',
                'CreatedById',
                'DateCreated',
                'Archived',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
