import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { ITaskDTO } from '@model/interfaces/custom/task-dto';
import { DatePipe } from '@angular/common';

export class TasksEntityListConfig extends EntityListConfig {
    constructor(currentUserId: number) {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['TaskDescriptions'],
                    name: 'Task Descriptions',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessors: ['Notes'],
                    name: 'Notes',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (task: ITaskDTO) => {
                        return task.TaskUsersList && task.TaskUsersList.trim().length ? task.TaskUsersList : task.TaskUserRolesList;
                    },
                    name: 'Assigned To',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessors: ['TaskStatus'],
                    name: 'Status',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: ITaskDTO) => new DatePipe('en-US').transform(entity.DueDate, 'MM/dd/yyyy', 'UTC') || ' ',
                    name: 'Due Date',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: ITaskDTO) => new DatePipe('en-US').transform(entity.DateCompleted, 'MM/dd/yyyy', 'UTC') || ' ',
                    name: 'Date Completed',
                    sort: { disableSort: true },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: ITaskDTO) =>
                        entity.CreatedById === currentUserId && !entity.IsAutomated ? '<i class="fa fa-lg fa-edit"></i>' : '',
                    bindToInnerHtml: true,
                    name: 'Edit',
                    sort: { disableSort: true },
                }),
            ],
        };
        super(listConfig);
    }
}
