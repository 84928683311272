import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';
import { IAttachmentType } from '@model/interfaces/attachment-type';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class AttachmentTypeService extends MetaItemService<IAttachmentType> {
    private _allAttachments: BehaviorSubject<IAttachmentType[]> = new BehaviorSubject<IAttachmentType[]>([]);

    setAttachmentTypes(value: IAttachmentType[]): void {
        this._allAttachments.next(value);
    }

    getCachedAttachmentTypes(): Observable<IAttachmentType[]> {
        return this._allAttachments.asObservable();
    }

    constructor(public http: HttpClient) {
        super('AttachmentTypeService', 'Type', 'TypeIds', '/attachmenttypes', http);
        this.getAll().subscribe((types) => {
            this.setAttachmentTypes(types);
        });
    }

    public getAttachmentTypes(attachmentTypeServiceId: number): Observable<IAttachmentType[]> {
        return this.http
            .get<IAttachmentType[]>(`/attachmenttypes/${attachmentTypeServiceId}/_getAttachmentTypes`, { responseType: 'json' })
            .pipe(map((types) => types.sort((a, b) => (a.Name > b.Name ? 1 : a.Name < b.Name ? -1 : 0))));
    }
}
