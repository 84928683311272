// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=testing` then `environment.testing.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    ambraiv: '30303030303030303030303030303030',
    ambraKey: '494d4544564945575541545445535431',
    ambraUploadUrl: 'https://imedview-uat.ambrahealth.com/api/v3/link/redirect?uuid=0606b71a-b712-46ad-8710-c75772df4ac5&',
    ambraViewUrl: 'https://imedview-uat.ambrahealth.com/api/v3/link/external?u=2b376d33-d59c-4911-895d-61cb94c05dcd&v=',
    apiVersion: '1',
    appName: '',
    appVersion: '1.0.0',
    assetsPath: 'assets/',
    authClientId: 2,
    authSecretVariable: 'asdj390usdfklsj',
    baseApiUrl: '',
    docPath: 'docs/',
    googleApiKey: '', // Empty this string to disable google login
    imgPath: 'docs/images/',
    logger: false,
    production: false,
    siteName: 'clientportal',
    submitHelpUrl: 'http://submit-help.testing.milesapp.com',
};
