import { DynamicField, DynamicFieldTypes, SelectInputTypes, DynamicFieldType, InputTypes } from '@mt-ng2/dynamic-form';
import { TaskDynamicControls, ITaskDynamicControlsParameters } from '@model/form-controls/task.form-controls';
import { ITaskPartial } from './task-partial';

export class TaskDynamicControlsPartial extends TaskDynamicControls {
    constructor(taskPartial?: ITaskPartial, additionalParameters?: ITaskDynamicControlsParameters) {
        super(taskPartial, additionalParameters);

        (<DynamicField>this.Form.AssignedToTypeId).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.AssignedToTypeId).options = [
            { Id: 1, Name: 'User Roles' },
            { Id: 2, Name: 'Users' },
        ];
        (<DynamicField>this.Form.AssignedToTypeId).type.inputType = SelectInputTypes.RadioButtonList;
        (<DynamicField>this.Form.AssignedToTypeId).labelHtml = `<label>Assigned To</label>`;

        (<DynamicField>this.Form.TaskDescriptions).setRequired(true);
        (<DynamicField>this.Form.ImedClaimId).setRequired(true);

        const now = new Date();
        const dyType = new DynamicFieldType({
            datepickerOptions: {
                minDate: {
                    day: now.getDate(),
                    month: now.getMonth() + 1,
                    year: now.getFullYear(),
                },
            },
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Datepicker,
        });
        this.Form.DueDate = new DynamicField({
            formGroup: this.formGroup,
            label: 'Due Date',
            name: 'DueDate',
            type: dyType,
            value: taskPartial?.DueDate ? taskPartial?.DueDate : now,
        });
        (<DynamicField>this.Form.DueDate).setRequired(true);
        (<DynamicField>this.Form.AssignedToTypeId).setRequired(true);

        this.Form.UnsureAssignee = new DynamicField({
            formGroup: this.formGroup,
            label: 'Not Sure',
            name: 'UnsureAssignee',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            }),
            value: taskPartial.UnsureAssignee,
        });
    }
}
