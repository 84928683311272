import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IAddressBookSelectEntity } from './address-books-select-entity';
import { IIMedClaimContactsInfo } from '../../model/interfaces/custom/imed-claim-contacts-info';
import { AppStateService, AppStates } from '../../app-state.service';

export const emptyAddressBookSelectEntity: IAddressBookSelectEntity = {
    AddressBookId: null,
    AddressBookName: null,
    Id: null,
    IndividualId: null,
    IndividualName: null,
    PhysicianId: null,
};

export const emptyIMedClaimContactsInfo: IIMedClaimContactsInfo = {
    CarrierPartyAddressBookId: null,
    CarrierPartyIndividualId: null,
    CarrierPartyIsDefault: false,
    CarrierPartyName: '',
    DefenseAttorneyAddressBookId: null,
    DefenseAttorneyIndividualId: null,
    DefenseAttorneyIsDefault: false,
    DefenseAttorneyName: '',
    OtherAddressBookId: null,
    OtherIndividualId: null,
    OtherIsDefault: false,
    OtherName: '',
    ParaLegalAddressBookId: null,
    ParaLegalIndividualId: null,
    ParaLegalIsDefault: false,
    ParaLegalName: '',
    PlantiffAttorneyAddressBookId: null,
    PlantiffAttorneyIndividualId: null,
    PlantiffAttorneyIsDefault: false,
    PlantiffAttorneyName: '',
};

export type AddressBookSelectedFunction = (addressBook: IAddressBookSelectEntity) => void;

@Injectable({
    providedIn: 'root',
})
export class AddressBooksSelectService {
    protected emitChangeSource = new Subject<IAddressBookSelectEntity>();
    public changeEmitted$: Observable<IAddressBookSelectEntity> = this.emitChangeSource.asObservable();

    public typeFilter: string;

    private selectFunction: AddressBookSelectedFunction;

    constructor(private appStateService: AppStateService) { }

    public emitChange(entity: IAddressBookSelectEntity): void {
        if (this.selectFunction) {
            const functionToCall = this.selectFunction;
            this.selectFunction = null;
            functionToCall(entity);
        }
        if (this.appStateService.appState.getValue() !== AppStates.Main) {
            this.appStateService.appState.next(AppStates.Main);
        }
        this.emitChangeSource.next(entity);
    }

    openAddressBookSelect(typeFilter: string, selectFunction: AddressBookSelectedFunction): void {
        this.typeFilter = typeFilter;
        this.selectFunction = selectFunction;
        this.appStateService.appState.next(AppStates.SearchAddressBook);
    }

    addressBookSelected(selectedEntity: IAddressBookSelectEntity): void {
        selectedEntity.AddressBookType = this.typeFilter;
        this.emitChange(selectedEntity);
    }
}
