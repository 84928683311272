import { Injectable } from '@angular/core';
import { IModalResult, ModalService } from '@mt-ng2/modal-module';
import { Observable } from 'rxjs';
import { PopUpTypeEnums } from '../Enums/pop-up-type.enums';

export interface IPopUpParams {
    cancelButtonColor?: string;
    cancelButtonText?: string;
    html: string;
    title?: string;
    type: PopUpTypeEnums;
    confirmButtonText?: string;
}

@Injectable({
    providedIn: 'root',
})
export class PopUpService {
    constructor(private modalService: ModalService) {}

    showPopUp({
        cancelButtonColor = '#d33',
        cancelButtonText = 'Cancel',
        html = '',
        title = '',
        type = PopUpTypeEnums.Soft_Pop_Up,
        confirmButtonText = 'Save',
    }: IPopUpParams): Observable<IModalResult> {
        return this.modalService.showModal({
            cancelButtonColor: cancelButtonColor,
            cancelButtonText: cancelButtonText,
            confirmButtonColor: '#3085d6',
            confirmButtonText: confirmButtonText,
            focusConfirm: true,
            html: html,
            showCancelButton: true,
            showCloseButton: false,
            showConfirmButton: type === PopUpTypeEnums.Soft_Pop_Up ? true : false,
            title: title,
            width: 800,
            customClass: {
                confirmButton: 'swal-override',
            },
        });
    }
}
