import { IRecordReviewService } from '@model/interfaces/record-review-service.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';

export const emptyRecordReviewService: IRecordReviewService = {
    Archived: false,
    ConflictOfInterestApproved: false,
    CreatedById: 0,
    DateCreated: new Date(),
    HasPreLitigation: false,
    Id: 0,
    ImedClaimServiceId: 0,
    IsFederalCase: false,
    PatientPreviouslyTreatedByPhysician: false,
    RequestDate: null,
    RequestDateChangedById: null,
    RequestDateChangedDate: null,
    Surveillance: false,
};

@Injectable({
    providedIn: 'root',
})
export class RecordReviewServiceService extends BaseService<IRecordReviewService> {
    constructor(public http: HttpClient) {
        super('/recordreviewservices', http);
    }

    getEmptyRecordReviewServiceService(): IRecordReviewService {
        return { ...emptyRecordReviewService };
    }
}
