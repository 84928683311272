import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { NavService } from '@mt-ng2/nav-module';
import { PageTitlesService } from '@mt-ng2/page-titles';
import { UserService } from './users/user.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from './model/ClaimTypes';
import { appPaths } from './default-routes/app-paths.library';

@Component({
    selector: 'app-client-access',
    template: ``,
})
export class ClientAccessComponent implements OnInit, OnDestroy {
    resetPasswordForm: UntypedFormGroup;
    resetKey: string;
    userId: number;
    badKeyError = `Oops something went wrong!
    - Probably the key has been used or expired.
    - Or you did something you weren't supposed to do.
    - Your best bet is to just generate a new email.`;

    constructor(
        private navService: NavService,
        private userService: UserService,
        private router: Router,
        public route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private pageTitlesService: PageTitlesService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.navService.setShowNav(false);
        this.route.queryParams.subscribe((params) => {
            this.resetKey = params.resetKey;
            this.userId = params.userId;
        });
        this.userService.clientAccess(this.resetKey, this.userId).subscribe(
            () => {
                this.success();
                if (this.claimsService.hasClaim(ClaimTypes.CaseManagement, [ClaimValues.FullAccess, ClaimValues.ReadOnly])) {
                    void this.router.navigate(['/', appPaths.home]);
                } else {
                    void this.router.navigate(['/', appPaths.clientDocumentUpload]);
                }
            },
            (error) => {
                if (error.status === 418) {
                    this.notificationsService.error('Access link is invalid');
                    // eslint-disable-next-line no-console
                    console.error(this.badKeyError);
                } else if (error.status === 400) {
                    // TODO: Can we just inject the error service?
                    if (error.error) {
                        this.error(error.error.ModelState.Service as string);
                    } else {
                        this.error('Something went wrong');
                        // eslint-disable-next-line no-console
                        console.error(this.badKeyError);
                    }
                }
            },
        );
        this.setPageTitle();
    }

    setPageTitle(): void {
        this.pageTitlesService.setPageTitle('Client Access');
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Something went wrong');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Welcome Miles User!');
    }

    ngOnDestroy(): void {
        this.navService.setShowNav(true);
    }
}
