import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IImedClaimRequest } from '../interfaces/imed-claim-request';
import { IAddress } from '../interfaces/address';
import { IImedClaimType } from '../interfaces/imed-claim-type';

export interface IImedClaimRequestDynamicControlsParameters {
    formGroup?: string;
    imedClaimTypes?: IImedClaimType[];
    carrierAddresses?: IAddress[];
    claimantAttorneyAddresses?: IAddress[];
    claimantAddresses?: IAddress[];
}

export class ImedClaimRequestDynamicControls {

    formGroup: string;
    imedClaimTypes: IImedClaimType[];
    carrierAddresses: IAddress[];
    claimantAttorneyAddresses: IAddress[];
    claimantAddresses: IAddress[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private imedclaimrequest?: IImedClaimRequest, additionalParameters?: IImedClaimRequestDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ImedClaimRequest';
        this.imedClaimTypes = additionalParameters && additionalParameters.imedClaimTypes || undefined;
        this.carrierAddresses = additionalParameters && additionalParameters.carrierAddresses || undefined;
        this.claimantAttorneyAddresses = additionalParameters && additionalParameters.claimantAttorneyAddresses || undefined;
        this.claimantAddresses = additionalParameters && additionalParameters.claimantAddresses || undefined;

        this.Form = {
            AdditionalComments: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Comments',
                name: 'AdditionalComments',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('AdditionalComments') && this.imedclaimrequest.AdditionalComments !== null ? this.imedclaimrequest.AdditionalComments.toString() : '',
            }),
            CarrierAddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Address',
                name: 'CarrierAddressId',
                options: this.carrierAddresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimrequest && this.imedclaimrequest.CarrierAddressId || null,
            }),
            CarrierContact: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Contact',
                name: 'CarrierContact',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('CarrierContact') && this.imedclaimrequest.CarrierContact !== null ? this.imedclaimrequest.CarrierContact.toString() : '',
            }),
            CarrierEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Email',
                name: 'CarrierEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('CarrierEmail') && this.imedclaimrequest.CarrierEmail !== null ? this.imedclaimrequest.CarrierEmail.toString() : '',
            }),
            CarrierFaxNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Fax Number',
                name: 'CarrierFaxNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('CarrierFaxNumber') && this.imedclaimrequest.CarrierFaxNumber !== null ? this.imedclaimrequest.CarrierFaxNumber.toString() : '',
            }),
            CarrierPhoneNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Phone Number',
                name: 'CarrierPhoneNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(15) ],
                validators: { 'maxlength': 15 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('CarrierPhoneNumber') && this.imedclaimrequest.CarrierPhoneNumber !== null ? this.imedclaimrequest.CarrierPhoneNumber.toString() : '',
            }),
            ClaimantAddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimant Address',
                name: 'ClaimantAddressId',
                options: this.claimantAddresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimrequest && this.imedclaimrequest.ClaimantAddressId || null,
            }),
            ClaimantAttorneyAddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimant Attorney Address',
                name: 'ClaimantAttorneyAddressId',
                options: this.claimantAttorneyAddresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimrequest && this.imedclaimrequest.ClaimantAttorneyAddressId || null,
            }),
            ClaimantAttorneyFirm: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimant Attorney Firm',
                name: 'ClaimantAttorneyFirm',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantAttorneyFirm') && this.imedclaimrequest.ClaimantAttorneyFirm !== null ? this.imedclaimrequest.ClaimantAttorneyFirm.toString() : '',
            }),
            ClaimantAttorneyName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimant Attorney Name',
                name: 'ClaimantAttorneyName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantAttorneyName') && this.imedclaimrequest.ClaimantAttorneyName !== null ? this.imedclaimrequest.ClaimantAttorneyName.toString() : '',
            }),
            ClaimantDateOfBirth: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimant Date Of Birth',
                name: 'ClaimantDateOfBirth',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimrequest && this.imedclaimrequest.ClaimantDateOfBirth || null,
            }),
            ClaimantEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimant Email',
                name: 'ClaimantEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantEmail') && this.imedclaimrequest.ClaimantEmail !== null ? this.imedclaimrequest.ClaimantEmail.toString() : '',
            }),
            ClaimantFaxNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimant Fax Number',
                name: 'ClaimantFaxNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantFaxNumber') && this.imedclaimrequest.ClaimantFaxNumber !== null ? this.imedclaimrequest.ClaimantFaxNumber.toString() : '',
            }),
            ClaimantFirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimant First Name',
                name: 'ClaimantFirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantFirstName') && this.imedclaimrequest.ClaimantFirstName !== null ? this.imedclaimrequest.ClaimantFirstName.toString() : '',
            }),
            ClaimantLastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimant Last Name',
                name: 'ClaimantLastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantLastName') && this.imedclaimrequest.ClaimantLastName !== null ? this.imedclaimrequest.ClaimantLastName.toString() : '',
            }),
            ClaimantPhoneNo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimant Phone No',
                name: 'ClaimantPhoneNo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantPhoneNo') && this.imedclaimrequest.ClaimantPhoneNo !== null ? this.imedclaimrequest.ClaimantPhoneNo.toString() : '',
            }),
            ClaimNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claim Number',
                name: 'ClaimNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimNumber') && this.imedclaimrequest.ClaimNumber !== null ? this.imedclaimrequest.ClaimNumber.toString() : '',
            }),
            DateOfInjury: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Of Injury',
                name: 'DateOfInjury',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimrequest && this.imedclaimrequest.DateOfInjury || null,
            }),
            ImedClaimTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Type',
                name: 'ImedClaimTypeId',
                options: this.imedClaimTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimrequest && this.imedclaimrequest.ImedClaimTypeId || null,
            }),
            Injuries: new DynamicField({
                formGroup: this.formGroup,
                label: 'Injuries',
                name: 'Injuries',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('Injuries') && this.imedclaimrequest.Injuries !== null ? this.imedclaimrequest.Injuries.toString() : '',
            }),
            IsOtherClaimType: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Other Claim Type',
                name: 'IsOtherClaimType',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('IsOtherClaimType') && this.imedclaimrequest.IsOtherClaimType !== null ? this.imedclaimrequest.IsOtherClaimType : false,
            }),
            MmiNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mmi Notes',
                name: 'MmiNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('MmiNotes') && this.imedclaimrequest.MmiNotes !== null ? this.imedclaimrequest.MmiNotes.toString() : '',
            }),
            RequestorEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Requestor Email',
                name: 'RequestorEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('RequestorEmail') && this.imedclaimrequest.RequestorEmail !== null ? this.imedclaimrequest.RequestorEmail.toString() : '',
            }),
            RequestorFirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Requestor First Name',
                name: 'RequestorFirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('RequestorFirstName') && this.imedclaimrequest.RequestorFirstName !== null ? this.imedclaimrequest.RequestorFirstName.toString() : '',
            }),
            RequestorLastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Requestor Last Name',
                name: 'RequestorLastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('RequestorLastName') && this.imedclaimrequest.RequestorLastName !== null ? this.imedclaimrequest.RequestorLastName.toString() : '',
            }),
            RequestorPhoneNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Requestor Phone Number',
                name: 'RequestorPhoneNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('RequestorPhoneNumber') && this.imedclaimrequest.RequestorPhoneNumber !== null ? this.imedclaimrequest.RequestorPhoneNumber.toString() : '',
            }),
            Specialties: new DynamicField({
                formGroup: this.formGroup,
                label: 'Specialties',
                name: 'Specialties',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('Specialties') && this.imedclaimrequest.Specialties !== null ? this.imedclaimrequest.Specialties.toString() : '',
            }),
            Ssn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ssn',
                name: 'Ssn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(4) ],
                validators: { 'maxlength': 4 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('Ssn') && this.imedclaimrequest.Ssn !== null ? this.imedclaimrequest.Ssn.toString() : '',
            }),
            TreatingPhysician: new DynamicField({
                formGroup: this.formGroup,
                label: 'Treating Physician',
                name: 'TreatingPhysician',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('TreatingPhysician') && this.imedclaimrequest.TreatingPhysician !== null ? this.imedclaimrequest.TreatingPhysician.toString() : '',
            }),
            WcbCaseNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Wcb Case Number',
                name: 'WcbCaseNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('WcbCaseNumber') && this.imedclaimrequest.WcbCaseNumber !== null ? this.imedclaimrequest.WcbCaseNumber.toString() : '',
            }),
        };

        this.View = {
            AdditionalComments: new DynamicLabel({
			    label: 'Additional Comments',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('AdditionalComments') && this.imedclaimrequest.AdditionalComments !== null ? this.imedclaimrequest.AdditionalComments.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CarrierAddressId: new DynamicLabel({
			    label: 'Carrier Address',
			    value: getMetaItemValue(this.carrierAddresses as unknown as IMetaItem[], this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('CarrierAddressId') && this.imedclaimrequest.CarrierAddressId !== null ? this.imedclaimrequest.CarrierAddressId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CarrierContact: new DynamicLabel({
			    label: 'Carrier Contact',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('CarrierContact') && this.imedclaimrequest.CarrierContact !== null ? this.imedclaimrequest.CarrierContact.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CarrierEmail: new DynamicLabel({
			    label: 'Carrier Email',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('CarrierEmail') && this.imedclaimrequest.CarrierEmail !== null ? this.imedclaimrequest.CarrierEmail.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CarrierFaxNumber: new DynamicLabel({
			    label: 'Carrier Fax Number',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('CarrierFaxNumber') && this.imedclaimrequest.CarrierFaxNumber !== null ? this.imedclaimrequest.CarrierFaxNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CarrierPhoneNumber: new DynamicLabel({
			    label: 'Carrier Phone Number',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('CarrierPhoneNumber') && this.imedclaimrequest.CarrierPhoneNumber !== null ? this.imedclaimrequest.CarrierPhoneNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClaimantAddressId: new DynamicLabel({
			    label: 'Claimant Address',
			    value: getMetaItemValue(this.claimantAddresses as unknown as IMetaItem[], this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantAddressId') && this.imedclaimrequest.ClaimantAddressId !== null ? this.imedclaimrequest.ClaimantAddressId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClaimantAttorneyAddressId: new DynamicLabel({
			    label: 'Claimant Attorney Address',
			    value: getMetaItemValue(this.claimantAttorneyAddresses as unknown as IMetaItem[], this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantAttorneyAddressId') && this.imedclaimrequest.ClaimantAttorneyAddressId !== null ? this.imedclaimrequest.ClaimantAttorneyAddressId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClaimantAttorneyFirm: new DynamicLabel({
			    label: 'Claimant Attorney Firm',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantAttorneyFirm') && this.imedclaimrequest.ClaimantAttorneyFirm !== null ? this.imedclaimrequest.ClaimantAttorneyFirm.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClaimantAttorneyName: new DynamicLabel({
			    label: 'Claimant Attorney Name',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantAttorneyName') && this.imedclaimrequest.ClaimantAttorneyName !== null ? this.imedclaimrequest.ClaimantAttorneyName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClaimantDateOfBirth: new DynamicLabel({
			    label: 'Claimant Date Of Birth',
			    value: this.imedclaimrequest && this.imedclaimrequest.ClaimantDateOfBirth || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ClaimantEmail: new DynamicLabel({
			    label: 'Claimant Email',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantEmail') && this.imedclaimrequest.ClaimantEmail !== null ? this.imedclaimrequest.ClaimantEmail.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClaimantFaxNumber: new DynamicLabel({
			    label: 'Claimant Fax Number',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantFaxNumber') && this.imedclaimrequest.ClaimantFaxNumber !== null ? this.imedclaimrequest.ClaimantFaxNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClaimantFirstName: new DynamicLabel({
			    label: 'Claimant First Name',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantFirstName') && this.imedclaimrequest.ClaimantFirstName !== null ? this.imedclaimrequest.ClaimantFirstName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClaimantLastName: new DynamicLabel({
			    label: 'Claimant Last Name',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantLastName') && this.imedclaimrequest.ClaimantLastName !== null ? this.imedclaimrequest.ClaimantLastName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClaimantPhoneNo: new DynamicLabel({
			    label: 'Claimant Phone No',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimantPhoneNo') && this.imedclaimrequest.ClaimantPhoneNo !== null ? this.imedclaimrequest.ClaimantPhoneNo.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ClaimNumber: new DynamicLabel({
			    label: 'Claim Number',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ClaimNumber') && this.imedclaimrequest.ClaimNumber !== null ? this.imedclaimrequest.ClaimNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateOfInjury: new DynamicLabel({
			    label: 'Date Of Injury',
			    value: this.imedclaimrequest && this.imedclaimrequest.DateOfInjury || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            ImedClaimTypeId: new DynamicLabel({
			    label: 'Imed Claim Type',
			    value: getMetaItemValue(this.imedClaimTypes as unknown as IMetaItem[], this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('ImedClaimTypeId') && this.imedclaimrequest.ImedClaimTypeId !== null ? this.imedclaimrequest.ImedClaimTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Injuries: new DynamicLabel({
			    label: 'Injuries',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('Injuries') && this.imedclaimrequest.Injuries !== null ? this.imedclaimrequest.Injuries.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsOtherClaimType: new DynamicLabel({
			    label: 'Is Other Claim Type',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('IsOtherClaimType') && this.imedclaimrequest.IsOtherClaimType !== null ? this.imedclaimrequest.IsOtherClaimType : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            MmiNotes: new DynamicLabel({
			    label: 'Mmi Notes',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('MmiNotes') && this.imedclaimrequest.MmiNotes !== null ? this.imedclaimrequest.MmiNotes.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RequestorEmail: new DynamicLabel({
			    label: 'Requestor Email',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('RequestorEmail') && this.imedclaimrequest.RequestorEmail !== null ? this.imedclaimrequest.RequestorEmail.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RequestorFirstName: new DynamicLabel({
			    label: 'Requestor First Name',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('RequestorFirstName') && this.imedclaimrequest.RequestorFirstName !== null ? this.imedclaimrequest.RequestorFirstName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RequestorLastName: new DynamicLabel({
			    label: 'Requestor Last Name',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('RequestorLastName') && this.imedclaimrequest.RequestorLastName !== null ? this.imedclaimrequest.RequestorLastName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            RequestorPhoneNumber: new DynamicLabel({
			    label: 'Requestor Phone Number',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('RequestorPhoneNumber') && this.imedclaimrequest.RequestorPhoneNumber !== null ? this.imedclaimrequest.RequestorPhoneNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Specialties: new DynamicLabel({
			    label: 'Specialties',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('Specialties') && this.imedclaimrequest.Specialties !== null ? this.imedclaimrequest.Specialties.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Ssn: new DynamicLabel({
			    label: 'Ssn',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('Ssn') && this.imedclaimrequest.Ssn !== null ? this.imedclaimrequest.Ssn.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            TreatingPhysician: new DynamicLabel({
			    label: 'Treating Physician',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('TreatingPhysician') && this.imedclaimrequest.TreatingPhysician !== null ? this.imedclaimrequest.TreatingPhysician.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            WcbCaseNumber: new DynamicLabel({
			    label: 'Wcb Case Number',
			    value: this.imedclaimrequest && this.imedclaimrequest.hasOwnProperty('WcbCaseNumber') && this.imedclaimrequest.WcbCaseNumber !== null ? this.imedclaimrequest.WcbCaseNumber.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
