import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject, of, Subject } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { UntypedFormGroup } from '@angular/forms';
import { IAddress } from '@model/interfaces/address';
import { IIMedClaimContactsInfo } from '../model/interfaces/custom/imed-claim-contacts-info';
import { AddressBookSelectionEnums } from '../common/constants/address-book-selection.enums';
import { IImedClaimPartial } from '../model/interfaces/custom/imed-claim-partial';
import { ServiceIdEnums } from '../common/constants/service-id.enums';
import { ImedClaimServiceService } from './services/imedclaimservice.service';
import { ImeServiceService } from './services/service-specifics/ime-service/ime-service/imeservice.service';
import { BillDeskReviewServiceService } from './services/service-specifics/bill-desk-review-service/bill-desk-review-service.service';
import { RadiologyReviewServiceService } from './services/service-specifics/radiology-review/radiology-review-service.service';
import { RecordRetrievalServiceService } from './services/service-specifics/record-retrieval-service/record-retrieval-service.service';
import { RecordReviewServiceService } from './services/service-specifics/record-review-service/record-review-service.service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { TaskService } from '../tasks/task.service';
import { IHeaderInfoDTO } from '../model/interfaces/custom/header-info-dto';
import { ICreateDocumentDTO } from '../model/interfaces/custom/create-document.dto';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { IFilmInformationDTO } from '@model/interfaces/custom/film-information-dto';
import { ImedClaimTypes } from '@model/ImedClaimTypes';
import { ImedClaimSubServiceService } from '@app-shared/services/imed-claim-sub-service.service';
import { ServiceStatusIdEnums, SubServiceEnums } from '../../app/common/constants/Enums';
import { SubpoenaServiceService } from './services/service-specifics/subpoena-processing/subpoena-service.service';

export const emptyImedClaim: IImedClaim = {
    AdjusterIsDefault: false,
    AllStates: true,
    Archived: false,
    CarrierPartyIsDefault: false,
    CarriersCounselIsDefault: false,
    CausalRelationship: false,
    ClaimantsAttorneyIsDefault: false,
    ClaimNumber: '',
    ClaimTypeId: ImedClaimTypes.GeneralLiability, // GL by default - will be overwritten by page logic
    CreatedById: 0,
    CurrentCourseOfTreatment: true,
    CurrentSymptomsAndCompliants: true,
    DateCreated: new Date(),
    DefenseAttorneyIsDefault: false,
    DegreeOfDisability: false,
    EmployerIsDefault: false,
    FileNumber: '',
    FirstName: null,
    HistoryOfAllegedInjury: true,
    Id: 0,
    ImedClaimStatusId: 0,
    Impression: true,
    LastName: null,
    NeedForFurtherTreatment: false,
    Notes: null,
    OccupationalStatus: true,
    OtherIsDefault: false,
    ParaLegalIsDefault: false,
    PastMedicalHistory: true,
    PastSubsequentHistory: true,
    PhysicalCheckToFacilityRequired: false,
    PlantiffAttorneyIsDefault: false,
    WorkStatus: false,
};

@Injectable({
    providedIn: 'root',
})
export class ImedClaimService extends BaseService<IImedClaim> {
    set cachedImedClaimContacts(val: IIMedClaimContactsInfo) {
        this._cachedImedClaimContacts = val;
    }
    get cachedImedClaimContacts(): IIMedClaimContactsInfo {
        return this._cachedImedClaimContacts;
    }
    set prevCase(val: string) {
        this._prevCase = val;
    }
    get prevCase(): string {
        return this._prevCase;
    }

    // Event emitter that is fired from the service component on intital case create
    public submitFormEvent: EventEmitter<void> = new EventEmitter<void>();

    // Event emitter to notify attachments component that case is saved
    public saveDocsOnCreate: EventEmitter<number> = new EventEmitter<number>();
    // Event emitter to let basic info component know it can show confirmation message when doc upload process is complete
    public showConfirmation: EventEmitter<void> = new EventEmitter<void>();
    // Event emitter to reenable double click on submit button if there is a validation error when creating a new case
    public enableDoubleClick: EventEmitter<void> = new EventEmitter<void>();
    public clearDocumentUploadQueue: EventEmitter<any> = new EventEmitter<any>();
    public resetServiceForm: EventEmitter<any> = new EventEmitter<any>();

    private _isDuplicate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _prevCase = '';
    public cachedImedClaimForm: UntypedFormGroup = null;
    private _cachedImedClaimContacts: IIMedClaimContactsInfo;

    public _selectedServicesForm: UntypedFormGroup;
    private _documents: ICreateDocumentDTO[] = [];
    private _documentsChangedSubject: Subject<ICreateDocumentDTO[]>;

    // subscribed to by imed-claim-basic-info component, initiated by notifyDocumentsUpdate() which is called by imed-claim-attachments component
    public documentsChanged$: Observable<ICreateDocumentDTO[]>;
    private _documentsSavedSubject: Subject<IAttachmentDTO[]>;

    // subscribed to by imed-claim-attachments component, initiated by afterDocumentsUploaded() which is called by imed-claim-basic-info component
    public documentsSaved$: Observable<IAttachmentDTO[]>;

    public unsuccessfulUploads: ICreateDocumentDTO[];

    private _serviceId: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    getIsDuplicate(): Observable<boolean> {
        return this._isDuplicate.asObservable();
    }
    setIsDuplicate(val: boolean): void {
        this._isDuplicate.next(val);
    }
    notifyDocumentsUpdate(newDocs: ICreateDocumentDTO[]): void {
        this._documents = newDocs;
        this._documentsChangedSubject.next(this._documents);
    }

    setServiceId(value: number, imedClaimId: number): void {
        this._serviceId.next(value);
        window.location.assign(`#/cases/${imedClaimId}/services/${value}/followups`);
    }

    getServiceId(): Observable<number> {
        return this._serviceId.asObservable();
    }

    constructor(
        public http: HttpClient,
        private imedClaimServiceService: ImedClaimServiceService,
        private imeServiceService: ImeServiceService,
        private billDeskService: BillDeskReviewServiceService,
        private radiologyReviewService: RadiologyReviewServiceService,
        private recordRetrievalService: RecordRetrievalServiceService,
        private recordReviewService: RecordReviewServiceService,
        private taskService: TaskService,
        private imedClaimSubServiceService: ImedClaimSubServiceService,
        private subpoenaProcessingService: SubpoenaServiceService,
    ) {
        super('/client/imed-claims', http);
        this._documentsChangedSubject = new Subject();
        this.documentsChanged$ = this._documentsChangedSubject.asObservable();
        this._documentsSavedSubject = new Subject();
        this.documentsSaved$ = this._documentsSavedSubject.asObservable();
    }

    getEmptyImedClaim(): IImedClaim {
        return { ...emptyImedClaim };
    }

    checkForDuplicates(imedClaim: IImedClaim): Observable<IImedClaim[]> {
        return this.http.post<IImedClaim[]>(`/client/imed-claims/check-for-duplicates`, imedClaim);
    }

    saveClaimAddress(claimId: number, address: IAddress): any {
        return this.http.post<number>(`/client/imed-claims/${claimId}/addresses`, address);
    }

    updateClaimAddress(claimId: number, address: IAddress): any {
        return this.http.put<number>(`/client/imed-claims/${claimId}/addresses`, address);
    }

    unAssignContactFromClaims(imedClaimContacts: IIMedClaimContactsInfo, name: string): IIMedClaimContactsInfo {
        switch (name as AddressBookSelectionEnums) {
            case AddressBookSelectionEnums.PlantiffAttorney:
                imedClaimContacts.PlantiffAttorneyAddressBookId = null;
                imedClaimContacts.PlantiffAttorneyIndividualId = null;
                imedClaimContacts.PlantiffAttorneyName = null;
                imedClaimContacts.PlantiffAttorneyIsDefault = false;
                break;
            case AddressBookSelectionEnums.DefenseAttorney:
                imedClaimContacts.DefenseAttorneyAddressBookId = null;
                imedClaimContacts.DefenseAttorneyIndividualId = null;
                imedClaimContacts.DefenseAttorneyName = null;
                imedClaimContacts.DefenseAttorneyIsDefault = false;
                break;
            case AddressBookSelectionEnums.Other:
                imedClaimContacts.OtherAddressBookId = null;
                imedClaimContacts.OtherIndividualId = null;
                imedClaimContacts.OtherName = null;
                imedClaimContacts.OtherIsDefault = false;
                break;
            case AddressBookSelectionEnums.ParaLegal:
                imedClaimContacts.ParaLegalAddressBookId = null;
                imedClaimContacts.ParaLegalIndividualId = null;
                imedClaimContacts.ParaLegalName = null;
                imedClaimContacts.ParaLegalIsDefault = false;
                break;
            case AddressBookSelectionEnums.CarrierParty:
                imedClaimContacts.CarrierPartyAddressBookId = null;
                imedClaimContacts.CarrierPartyIndividualId = null;
                imedClaimContacts.CarrierPartyName = null;
                imedClaimContacts.CarrierPartyIsDefault = false;
                break;
            default:
                break;
        }
        return imedClaimContacts;
    }

    assignClaimToContacts(imedClaimContacts: IIMedClaimContactsInfo, imedClaim: IImedClaimPartial): IIMedClaimContactsInfo {
        imedClaimContacts.ParaLegalName = imedClaim.ParaLegalIndividual
            ? imedClaim.ParaLegalIndividual.FirstName + ' ' + imedClaim.ParaLegalIndividual.LastName
            : imedClaim.ParaLegalAddressBook
            ? imedClaim.ParaLegalAddressBook.FacilityName
            : '';

        imedClaimContacts.ParaLegalIsDefault = imedClaim.ParaLegalIsDefault;

        imedClaimContacts.CarrierPartyName = imedClaim.CarrierPartyIndividual
            ? imedClaim.CarrierPartyIndividual.FirstName + ' ' + imedClaim.CarrierPartyIndividual.LastName
            : imedClaim.CarrierPartyAddressBook
            ? imedClaim.CarrierPartyAddressBook.FacilityName
            : '';

        imedClaimContacts.CarrierPartyIsDefault = imedClaim.CarrierPartyIsDefault;

        imedClaimContacts.DefenseAttorneyName = imedClaim.DefenseAttorneyIndividual
            ? imedClaim.DefenseAttorneyIndividual.FirstName + ' ' + imedClaim.DefenseAttorneyIndividual.LastName
            : imedClaim.DefenseAttorneyAddressBook
            ? imedClaim.DefenseAttorneyAddressBook.FacilityName
            : '';

        imedClaimContacts.DefenseAttorneyIsDefault = imedClaim.DefenseAttorneyIsDefault;

        imedClaimContacts.OtherName = imedClaim.OtherIndividualId
            ? imedClaim.OtherIndividual.FirstName + ' ' + imedClaim.OtherIndividual.LastName
            : imedClaim.OtherAddressBook
            ? imedClaim.OtherAddressBook.FacilityName
            : '';

        imedClaimContacts.OtherIsDefault = imedClaim.OtherIsDefault;

        imedClaimContacts.PlantiffAttorneyName = imedClaim.PlantiffAttorneyIndividual
            ? imedClaim.PlantiffAttorneyIndividual.FirstName + ' ' + imedClaim.PlantiffAttorneyIndividual.LastName
            : imedClaim.PlantiffAttorneyAddressBook
            ? imedClaim.PlantiffAttorneyAddressBook.FacilityName
            : '';

        imedClaimContacts.PlantiffAttorneyIsDefault = imedClaim.PlantiffAttorneyIsDefault;

        return imedClaimContacts;
    }

    assignContactsToClaim(imedClaim: IImedClaimPartial, imedClaimContacts: IIMedClaimContactsInfo): IImedClaim {
        imedClaim.CarrierPartyAddressBookId = imedClaimContacts.CarrierPartyAddressBookId;
        imedClaim.CarrierPartyIndividualId = imedClaimContacts.CarrierPartyIndividualId;
        imedClaim.CarrierPartyIsDefault = imedClaimContacts.CarrierPartyIsDefault;
        imedClaim.DefenseAttorneyAddressBookId = imedClaimContacts.DefenseAttorneyAddressBookId;
        imedClaim.DefenseAttorneyIndividualId = imedClaimContacts.DefenseAttorneyIndividualId;
        imedClaim.DefenseAttorneyIsDefault = imedClaimContacts.DefenseAttorneyIsDefault;
        imedClaim.OtherAddressBookId = imedClaimContacts.OtherAddressBookId;
        imedClaim.OtherIndividualId = imedClaimContacts.OtherIndividualId;
        imedClaim.OtherIsDefault = imedClaimContacts.OtherIsDefault;
        imedClaim.ParaLegalAddressBookId = imedClaimContacts.ParaLegalAddressBookId;
        imedClaim.ParaLegalIndividualId = imedClaimContacts.ParaLegalIndividualId;
        imedClaim.ParaLegalIsDefault = imedClaimContacts.ParaLegalIsDefault;
        imedClaim.PlantiffAttorneyAddressBookId = imedClaimContacts.PlantiffAttorneyAddressBookId;
        imedClaim.PlantiffAttorneyIndividualId = imedClaimContacts.PlantiffAttorneyIndividualId;
        imedClaim.PlantiffAttorneyIsDefault = imedClaimContacts.PlantiffAttorneyIsDefault;

        return imedClaim;
    }

    setIsDefault(addressBookType: string, checked: boolean, imedClaimContacts: IIMedClaimContactsInfo): IIMedClaimContactsInfo {
        switch (addressBookType as AddressBookSelectionEnums) {
            case AddressBookSelectionEnums.PlantiffAttorney:
                imedClaimContacts.PlantiffAttorneyIsDefault = checked;
                break;
            case AddressBookSelectionEnums.DefenseAttorney:
                imedClaimContacts.DefenseAttorneyIsDefault = checked;
                break;
            case AddressBookSelectionEnums.Other:
                imedClaimContacts.OtherIsDefault = checked;
                break;
            case AddressBookSelectionEnums.ParaLegal:
                imedClaimContacts.ParaLegalIsDefault = checked;
                break;
            case AddressBookSelectionEnums.CarrierParty:
                imedClaimContacts.CarrierPartyIsDefault = checked;
                break;
            default:
                break;
        }
        return imedClaimContacts;
    }

    getClaimsByClientId(searchParams: SearchParams): Observable<any> {
        const urlParams = this.getHttpParams(searchParams);
        return this.http.get('/client/imed-claims/get-claims-by-client-id', { observe: 'response', params: urlParams });
    }

    afterDocumentsUploaded(uploadedDocs: IAttachmentDTO[]): void {
        this._documentsSavedSubject.next(uploadedDocs);
    }

    downloadSummarySheet(entityTypeName: string, imedClaimId: number): Observable<any> {
        return this.http.post(`/${entityTypeName}/download`, imedClaimId, { observe: 'response', responseType: 'blob' });
    }

    buildServicesArray(imedClaim: IImedClaim, userId: number): IImedClaimService[] {
        const form = this._selectedServicesForm;
        if (form.valid) {
            const services = [];
            for (const key in form.value.Services) {
                if (form.value.Services[key] === true) {
                    if (key === 'ime') {
                        form.value.Services.imesWithSpecialties
                            .filter((spec) => spec && spec.Id > 0)
                            .forEach((spec) => {
                                const service = this.imedClaimServiceService.getEmptyImedClaimService();
                                service.ServiceId = ServiceIdEnums.IME;
                                service.CreatedById = userId;
                                service.SpecialtyRequestedId = spec.Id;
                                service.ImedClaimId = imedClaim.Id;
                                service.ServiceStatusId = ServiceStatusIdEnums.IME_Pending_Scheduling;
                                service.ImeServices_ImedClaimServiceId = [];
                                const imeService = this.imeServiceService.getEmptyImeService();
                                imeService.CreatedById = userId;
                                service.ImeServices_ImedClaimServiceId.push(imeService);
                                services.push(service);
                            });
                    } else if (key === 'imeExpertConsultation') {
                        form.value.Services.imeExpertConsultationWithSpecialties
                            .filter((spec) => spec && spec.Id > 0)
                            .forEach((spec) => {
                                const service = this.imedClaimServiceService.getEmptyImedClaimService();
                                service.ServiceId = ServiceIdEnums.IME_Expert_Consultation;
                                service.CreatedById = userId;
                                service.SpecialtyRequestedId = spec.Id;
                                service.ImedClaimId = imedClaim.Id;
                                service.ServiceStatusId = ServiceStatusIdEnums.IME_Expert_Consultation_Pending_Scheduling;
                                service.ImeServices_ImedClaimServiceId = [];
                                const imeService = this.imeServiceService.getEmptyImeService();
                                imeService.CreatedById = userId;
                                service.ImeServices_ImedClaimServiceId.push(imeService);
                                services.push(service);
                            });
                    } else if (key === 'recordReview') {
                        form.value.Services.recordReviewWithSpecialties
                            .filter((spec) => spec && spec.Id > 0)
                            .forEach((spec) => {
                                const service = this.imedClaimServiceService.getEmptyImedClaimService();
                                service.ServiceId = ServiceIdEnums.Record_Review;
                                service.CreatedById = userId;
                                service.SpecialtyRequestedId = spec.Id;
                                service.ImedClaimId = imedClaim.Id;
                                service.ServiceStatusId = ServiceStatusIdEnums.Record_Review_Scheduled_Pending_Receipt_Of_Records;
                                service.RecordReviewServices = [];
                                const recordReviewService = this.recordReviewService.getEmptyRecordReviewServiceService();
                                recordReviewService.CreatedById = userId;
                                service.RecordReviewServices.push(recordReviewService);
                                services.push(service);
                            });
                    } else {
                        const service = this.imedClaimServiceService.getEmptyImedClaimService();
                        service.ImedClaimId = imedClaim.Id;
                        service.CreatedById = userId;
                        const billDeskService = this.billDeskService.getEmptyBillDeskReviewServiceService();
                        const radiologyReviewService = this.radiologyReviewService.getEmptyFilmReviewServiceService();
                        const recordRetrievalService = this.recordRetrievalService.getEmptyRecordRetrievalService();
                        const subpoenaProcessing = this.subpoenaProcessingService.getEmptySubpoenaService();
                        switch (key) {
                            case 'billDeskReview':
                                service.ServiceId = ServiceIdEnums.Billing_Desk_Review;
                                service.ServiceStatusId = ServiceStatusIdEnums.Billing_Desk_Review_Scheduled_Pending_Receipt_Of_Records;
                                service.BillDeskReviewServices = [];
                                billDeskService.CreatedById = userId;
                                service.BillDeskReviewServices.push(billDeskService);
                                break;
                            case 'radiologyReview':
                                service.ServiceId = ServiceIdEnums.Film_Review;
                                service.ServiceStatusId = ServiceStatusIdEnums.Radiology_Review_Pending_Receipt_Of_Films_From_Client;
                                service.FilmReviewServices = [];
                                radiologyReviewService.CreatedById = userId;
                                service.FilmReviewServices.push(radiologyReviewService);
                                break;
                            case 'recordRetrieval':
                                service.ServiceId = ServiceIdEnums.Record_Retrievals_HIPAA_Processing;
                                service.ServiceStatusId =
                                    ServiceStatusIdEnums.Record_Retrievals_HIPAA_Processing_Pending_Retrieval_Document_Submission_By_Mail_Or_Fax;
                                service.RecordRetrievalServices = [];
                                recordRetrievalService.CreatedById = userId;
                                service.RecordRetrievalServices.push(recordRetrievalService);
                                break;
                             case 'subpoenaProcessing':
                                service.ServiceId = ServiceIdEnums.Subpoena_Processing;
                                service.ServiceStatusId = ServiceStatusIdEnums.Subpoena_Pending_Retrieval_Document_Submission_By_Mail_Or_Fax;
                                service.SubpoenaServices = [];
                                service.SubpoenaServices.push(subpoenaProcessing);
                                break;
                            default:
                                break;
                        }
                        services.push(service);
                    }
                }
            }
            return services;
        }
        return [];
    }

    createAdditionalServicesTask(imedClaimId: number): Observable<number> {
        const additionalServices: { [s: string]: unknown; } = this._selectedServicesForm.value.AdditionalServices;
        if (Object.values(additionalServices).some((service) => service === true)) {
            const task = this.taskService.getEmptyTask();
            task.ImedClaimId = imedClaimId;
            task.TaskDescriptions = `When the Case was submitted, Client requested: ${additionalServices.translationRequested ? 'Translation,' : ''} \
            ${additionalServices.surveillanceRequested ? 'Surveillance,' : ''} ${
                additionalServices.backgroundInvestigationRequested ? 'Background Investigation' : ''
            } please add these to the Case as appropriate.`;
            return this.taskService.create(task);
        }
        return of(0);
    }

    getHeaderInfo(imedClaimId: number): Observable<IHeaderInfoDTO> {
        return this.http.get<IHeaderInfoDTO>(`/client/imed-claims/${imedClaimId}/get-header-info`, {});
    }

    getImedClaimServiceListForDropdown(imedClaimId: number): Observable<any> {
        return this.http.get(`/client/imed-claims/getImedClaimServiceListForDropdown/${imedClaimId}`, {});
    }

    getFilmInformationByImedClaimId(imedClaimId: number): Observable<IFilmInformationDTO[]> {
        return this.http.get<IFilmInformationDTO[]>(`/client/imed-claims/filmInformations/get-by-imed-claim-id/${imedClaimId}`);
    }

    createRecordRetrievalServiceForAmbraUpload(imedClaimId: number, userId: number, defenseFirmName: string): IImedClaimService {
        const service = this.imedClaimServiceService.getEmptyImedClaimService();
        service.ImedClaimId = imedClaimId;
        service.CreatedById = userId;
        service.ServiceId = ServiceIdEnums.Record_Retrievals_HIPAA_Processing;
        service.ServiceStatusId = ServiceStatusIdEnums.Record_Retrievals_HIPAA_Processing_Films_received_pending_distribution_to_service;
        service.RecordRetrievalServices = [];
        service.ImedClaimSubServices = [];

        const radiologyRetrievalSubService = this.imedClaimSubServiceService.getEmptyImedClaimService();
        radiologyRetrievalSubService.CreatedById = userId;
        radiologyRetrievalSubService.SubServiceId = SubServiceEnums.Radiology_Retrieval;
        service.ImedClaimSubServices.push(radiologyRetrievalSubService);

        const recordRetrievalService = this.recordRetrievalService.getEmptyRecordRetrievalService();
        recordRetrievalService.CreatedById = userId;
        recordRetrievalService.RadiologyFacility = defenseFirmName?.length > 100 ? defenseFirmName.substring(0, 100) : defenseFirmName;
        service.RecordRetrievalServices.push(recordRetrievalService);

        return service;
    }
}
