import { IErrorsModuleConfig, IToastableError, NotificationTypes } from '@mt-ng2/errors-module';

// the api key below is for BreckDemo and should be updated
export const ErrorConfigOverride: IErrorsModuleConfig = {
    customErrorHandlerFunction: ServiceUnavailableFunction,
};

export const ServiceUnavailableError: IToastableError = {
    errorMessage: 'Application is unavailable, please try again in a few minutes',
    notificationType: NotificationTypes.Info,
};

export function ServiceUnavailableFunction(error): IToastableError {
    if (error && error.status && error.status === 503) {
        return ServiceUnavailableError;
    }
    return null;
}
