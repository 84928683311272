import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { FileUploadModule } from 'ng2-file-upload';
import { DynamicFormModule, DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form';
import { MtNoteControlModule } from '@mt-ng2/note-control';
import { EntityComponentsNotesModule } from '@mt-ng2/entity-components-notes';
import { EntityComponentsAddressesModule } from '@mt-ng2/entity-components-addresses';
import { MtDocumentControlModule } from '@mt-ng2/document-control';
import { EntityComponentsDocumentsModule } from '@mt-ng2/entity-components-documents';
import { EntityComponentsPhonesModule } from '@mt-ng2/entity-components-phones';
import { MtSearchFilterSelectModule } from '@mt-ng2/search-filter-select-control';
import { MtSearchFilterDaterangeModule } from '@mt-ng2/search-filter-daterange-control';
import { MtSearchFilterCheckboxModule } from '@mt-ng2/search-filter-checkbox-control';
import { MtDisableDuringHttpCallsModule  } from '@mt-ng2/disable-during-http-calls';
import { EntityListModule, IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module';
import { SharedEntitiesModule } from '@mt-ng2/shared-entities-module';
import { MtManagedListControlModule } from '@mt-ng2/managed-list-control';
import { MtSearchBarControlModule } from '@mt-ng2/searchbar-control';
import { BackButtonModule } from '@mt-ng2/back-button-module';
import { MtDateModule } from '@mt-ng2/date-module';
import { MtFabSpeedDialControlModule } from '@mt-ng2/fab-speed-dial-control';
import { CommonService } from './services/common.service';
import { AuthEntityModule } from '../auth-entity/auth-entity.module';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';
import { KeyboardShortcutModule, KeyboardShortcutService } from '@mt-ng2/keyboard-shortcuts-module';

import { MtPhotoControlModule } from '@mt-ng2/photo-control';
import { PageTitlesModuleConfigToken } from '@mt-ng2/page-titles-config';
import { AttachmentControlComponent } from './attachment-control/attachment-control.component';
import { AttachmentTypeService } from './attachments/attachment-type.service';
import { BaseAttachmentsService } from './attachments/base-attachments.service';
import { UtcDate } from './utc-date-pipe/utc-date.pipe';
import { TypeAheadListControlComponent } from './type-ahead-list-control/type-ahead-list-control.component';
import { MultiselectComponent, MultiselectControlModule } from '@mt-ng2/multiselect-control';
import { dynamicFormModuleConfig } from './configs/dynamic-form-override-config';
import { UploadAnimationComponent } from './upload-animation/upload-animation.component';
import { LogoutLogService } from '../../app-shared/services/logout-log.service';
import { LoginLogService } from '../../app-shared/services/login-log.service';
import { ImedClaimServiceFilmInformationList } from './film-information/imed-claim-service-film-information-list.component';
import { ModalModule } from '@mt-ng2/modal-module';
import { FilmInformationService } from '@app-shared/services/film-information.service';
import { NavModule } from '@mt-ng2/nav-module';
import { AuthEntityService } from '@app-shared/services/auth-entity.service';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

@NgModule({
    declarations: [TypeAheadListControlComponent, AttachmentControlComponent, UtcDate, UploadAnimationComponent, ImedClaimServiceFilmInformationList],
    exports: [
        AuthEntityModule,
        AttachmentControlComponent,
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DynamicFormModule,
        NgxMaskModule,
        MtNoteControlModule,
        EntityComponentsNotesModule,
        EntityComponentsAddressesModule,
        MtManagedListControlModule,
        MtDocumentControlModule,
        EntityComponentsDocumentsModule,
        EntityComponentsPhonesModule,
        MtSearchFilterSelectModule,
        MtSearchFilterDaterangeModule,
        MtDisableDuringHttpCallsModule ,
        EntityListModule,
        ModalModule,
        MtSearchBarControlModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
        BackButtonModule,
        MtDateModule,
        MtFabSpeedDialControlModule,
        TypeAheadModule,
        KeyboardShortcutModule,
        MtPhotoControlModule,
        NavModule,
        UtcDate,
        TypeAheadListControlComponent,
        UploadAnimationComponent,
        MultiselectComponent,
        ImedClaimServiceFilmInformationList,
        ModalModule,
    ],
    imports: [
        AuthEntityModule,
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        BrowserAnimationsModule,
        DynamicFormModule,
        NgxMaskModule,
        FileUploadModule,
        MtNoteControlModule,
        EntityComponentsNotesModule,
        MtManagedListControlModule,
        EntityComponentsAddressesModule,
        ModalModule,
        MtDocumentControlModule,
        EntityComponentsDocumentsModule,
        EntityComponentsPhonesModule,
        MtSearchFilterSelectModule,
        MtSearchFilterDaterangeModule,
        MtDisableDuringHttpCallsModule ,
        EntityListModule,
        MtSearchBarControlModule,
        MultiselectControlModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
        BackButtonModule,
        MtDateModule,
        MtFabSpeedDialControlModule,
        TypeAheadModule,
        KeyboardShortcutModule,
        MtPhotoControlModule,
        NavModule,
        ModalModule,
    ],
})
export class SharedModule {
    static forRoot(): any {
        return {
            ngModule: SharedModule,
            providers: [
                AttachmentTypeService,
                BaseAttachmentsService,
                CommonService,
                LogoutLogService,
                LoginLogService,
                AuthEntityService,
                KeyboardShortcutService,
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: DynamicFormModuleConfigToken, useValue: dynamicFormModuleConfig },
                FilmInformationService,
            ],
        };
    }
}
