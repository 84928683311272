// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .border {
                border: solid 1px #858a9f;
                margin-top: 10px;
                border-radius: 0.5%;
            }
        `, "",{"version":3,"sources":["webpack://./src/client-portal/imed-claims/address/claim-address-basic-info.component.ts"],"names":[],"mappings":";YACY;gBACI,yBAAyB;gBACzB,gBAAgB;gBAChB,mBAAmB;YACvB","sourcesContent":["\n            .border {\n                border: solid 1px #858a9f;\n                margin-top: 10px;\n                border-radius: 0.5%;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
