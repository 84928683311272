import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaceholderLandingPageComponent } from './placeholder-landing-page/placeholder-landing-page.component';

@NgModule({
    declarations: [PlaceholderLandingPageComponent],
    imports: [CommonModule],
})
export class LandingPageModule {
    static forRoot(): any {
        return {
            ngModule: LandingPageModule,
            providers: [],
        };
    }
}
