import { Component, OnInit, Input } from '@angular/core';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'attachment-control',
    templateUrl: './attachment-control.component.html',
})
export class AttachmentControlComponent implements OnInit {
    private _showAllowedExtension = true;
    @Input('showAllowedExtension')
    get showAllowedExtension(): boolean {
        return this._showAllowedExtension;
    }
    set showAllowedExtension(value: boolean) {
        this._showAllowedExtension = value;
    }

    allowedMimeType: string[] = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    maxFileSize = 175 * 1024 * 1024;
    isHTML5 = true;
    uploader: FileUploader;
    headerTitle = 'Upload Document';
    private _showUploadOnly = false;
    @Input('singleFileOnly') singleFileOnly;
    errorMessage: string;

    constructor(private notificationsService: NotificationsService) {
        this.uploader = new FileUploader({
            url: '',
            allowedMimeType: this.allowedMimeType,
            isHTML5: this.isHTML5,
            maxFileSize: this.maxFileSize,
        });
    }

    ngOnInit(): void {
        this.uploader.onWhenAddingFileFailed = (item, filter) => this.onWhenAddingFileFailed(item, filter);
    }

    onWhenAddingFileFailed(item: FileLikeObject, filter: any): void {
        const allowedTypes = this.allowedMimeType.join();
        switch (filter.name) {
            case 'fileSize':
                this.errorMessage = `Maximum upload size exceeded (${item.size} of ${this.maxFileSize} allowed)`;
                break;
            case 'mimeType':
                this.errorMessage = `Type "${item.type} is not allowed. Allowed types: "${allowedTypes}"`;
                break;
            default:
                this.errorMessage = `Unknown error (filter is ${filter.name})`;
        }
        this.notificationsService.error(this.errorMessage);
    }
}
