import { IRecordRetrievalService } from '@model/interfaces/record-retrieval-service.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';

export const emptyRecordRetrievalService: IRecordRetrievalService = {
    Archived: false,
    CreatedById: 0,
    DateCreated: new Date(),
    Id: 0,
    ImedClaimServiceId: 0,
    ProcessingDate: null,
    ProcessingDateChangedById: null,
    ProcessingDateChangedDate: null,
    RadiologyFacility: '',
    Reference:
        'Entire medical record including patient histories, office notes (except psychotherapy notes), test results, RADIOLOGY STUDIES, FILMS, referrals, consults, billing records, insurance records, and records sent to you by other health care providers;',
    SensitiveConditionsInitialed: false,
};

@Injectable({
    providedIn: 'root',
})
export class RecordRetrievalServiceService extends BaseService<IRecordRetrievalService> {
    constructor(public http: HttpClient) {
        super('/recordretrievalservices', http);
    }

    getEmptyRecordRetrievalService(): IRecordRetrievalService {
        return { ...emptyRecordRetrievalService };
    }
}
