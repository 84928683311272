import { IFilmInformation } from '@model/interfaces/film-information.d';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFilmInformationDTO } from '@model/interfaces/custom/film-information-dto';
import { ModalService } from '@mt-ng2/modal-module';
import { isNullOrWhitespace } from '@mt-ng2/common-functions';

import CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-imed-claim-service-film-information-list',
    templateUrl: './imed-claim-service-film-information-list.component.html',
})
export class ImedClaimServiceFilmInformationList {
    @Input() items: IFilmInformationDTO[];
    @Input() canEdit: boolean;

    @Output('onAddItem') onAddItem: EventEmitter<void> = new EventEmitter<void>();
    @Output('onDeleteItem') onDeleteItem: EventEmitter<IFilmInformation> = new EventEmitter<IFilmInformation>();
    @Output('onEditItem') onEditItem: EventEmitter<IFilmInformation> = new EventEmitter<IFilmInformation>();

    constructor(private modalService: ModalService) {}

    delete(filmInformation: IFilmInformation, event: Event): void {
        event.stopPropagation();
        this.modalService
            .showModal({
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                reverseButtons: true,
                showCancelButton: true,
                text: 'Send request for a Viewpoint Admin to delete this film?',
                titleText: 'Request Delete',
            })
            .subscribe((result) => {
                if (result.isConfirmed) {
                    this.onDeleteItem.emit(filmInformation);
                }
            });
    }

    addItem(): void {
        this.onAddItem.emit();
    }

    editItem(filmInformation: IFilmInformation, event: Event): void {
        event.stopPropagation();
        this.onEditItem.emit(filmInformation);
    }

    openItem(filmInformation: IFilmInformation, event: Event): void {
        event.stopPropagation();
        const search = JSON.stringify({ 'filter.uuid.equals': filmInformation.StudyUuid });
        const key = CryptoJS.enc.Hex.parse(environment.ambraKey);
        const iv = CryptoJS.enc.Hex.parse(environment.ambraiv);
        const encryptedSearch = CryptoJS.AES.encrypt(search, key, { iv: iv });
        const urlEncodedSearch = encodeURIComponent(encryptedSearch.toString());
        const ambraViewItemUrl = environment.ambraViewUrl + urlEncodedSearch;

        this.modalService
            .showModal({
                allowEscapeKey: false,
                allowOutsideClick: false,

                confirmButtonText: 'Close',
                html: `
                    <iframe
                        src="${ambraViewItemUrl}"
                        height="900px"
                        width="100%">
                    </iframe>
                `,
                title: 'View Study',
                width: '80%',
                customClass: {
                    confirmButton: 'swal-ambra',
                },
            })
            .subscribe();
    }

    showAmbraLink(filmInformation: IFilmInformation): boolean {
        return !isNullOrWhitespace(filmInformation.StudyUuid as string);
    }
}
